<div class="maincontentarea">
    <section class="vouchers vouchers-detail">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h4><i class="voucher-ion-left fa fa-angle-left" (click)="landingPage(sessionId)"
                            ></i> &nbsp; {{vouchersDetail.brand_name}}</h4>
                </div>
                <div class="col-md-12 text-center vouchers-detail-img mt-3">
                    <image-manipulation [img]="vouchersDetail.voucher_image" [params]="{'width':200,'height':200}"
                        [notFound]="notFoundImg">
                    </image-manipulation>
                </div>
                <div class="col-md-12 mt-4">
                    <label>DEAL SUMMARY</label>
                    <p *ngFor="let de of deal">{{de}}</p>
                    
                    <label>BRAND</label>
                    <p>{{vouchersDetail.brand_name}}</p>

                    <label>TERMS & CONDITIONS</label>
                    <p *ngFor="let term of terms_cond">{{term}}</p>
                    
                    <label>VALIDITY</label>
                    <p>Valid till {{vouchersDetail.validity_date}}</p>
                </div>
            </div>
        </div>
    </section>
</div>