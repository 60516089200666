<button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item" id="openLogin"
    (click)="openLoginModal(content)">Open Login Modal</button>

<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>
<button type="button"  class="close vm-close" data-dismiss="modal" (click)="modalClose()">
    <img src="../../../assets/img/audio/close.png" />
</button>
<div id="container">
    <div class="virtual_mandir" [ngClass]="{'vm_iphone' : isIphone==true}">
        <div class="imgbg"></div>
        <div class="flower-block" [hidden]="isShowFlower">
            <ng-template ngFor let-config [ngForOf]="snowFlakes">
                <snow-flake [depth]="config.depth" [speed]="config.speed" [style.left.px]="config.left"
                    [style.top.%]="config.depth" [style.margin-bottom.px]="config.depth">
                    <div class="vertical-track">
                        <div class="horizontal-track">
                            <span class="flake"><img [style.width.px]="config.left+config.depth"
                                    src="../../../assets/img/vm/flower.png" /></span>
                        </div>
                    </div>
                </snow-flake>
            </ng-template>
        </div>
        <div class="mandir-block">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
            <!-- <img class="vm_slider_img" [src]="godImage" /> -->

            <div class="bell-left bell-width" (click)="bellleft()">
                <img src="../../../assets/img/vm/bell.png" />
            </div>

            <div class="bell-right bell-width" (click)="bellright()">
                <img src="../../../assets/img/vm/bell.png" />
            </div>

            <div class="left-manu mandir-nav">
                <ul>
                    <li><a (click)="!isArti ? flowerfall() : ''" href="javascript:void(0);"><img
                                [src]="isShowFlower ? '../../../assets/img/vm/flower_icon.png' : '../../../assets/img/vm/flower_icon_active1.png'" /></a></li>
                    <li><a (click)="gotoPnachang()"><img
                                src="../../../assets/img/vm/panchang_icon.png" /><label>पंचांग</label></a>
                    </li>
                </ul>
            </div>

            <div class="right-manu mandir-nav">
                <ul>
                    <li><a href="javascript:void(0);" (click)="!isArti ? playSankh() : ''"><img alt=""
                                [src]="isShank ? '../../../assets/img/vm/sankh_icon_active1.png' : '../../../assets/img/vm/sankh_icon.png'" /></a></li>
                    <li><a href="javascript:void(0);" (click)='!isArti ? AartiThali() : stopArti()'><img alt=""
                                [src]="isArti ? '../../../assets/img/vm/aarti_icon_active1.png':'../../../assets/img/vm/aarti_icon.png'" /><label>आरती</label></a></li>
                </ul>
            </div>

            <div class="arti-block">
                <div class="artimove">
                    <div class="artiinfini">
                        <a class="artithali" (click)='!isThaliLamp ? thali() : ""' href="javascript:void(0);" ><img [src]="isThali"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <audio id="shankhAudio" style="display: none;" on-ended="shankhEnded()">
        <source src="../../../assets/img/vm/shankh.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
    <audio id="bellAudio" style="display: none;">
        <source src="../../../assets/img/vm/bell.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
    <audio id="artiAudio" style="display: none;" on-ended="artiEnded()">
        <source src="../../../assets/img/vm/bg.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
    <audio id="flowerAudio" style="display: none;">
        <source src="../../../assets/img/vm/sprinkle.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>
</div>










<!-- <button type="button" 
    *ngFor="let image of images; let index = index" 
    (click)="selectedImageIndex = index">Select image {{ index + 1 }}</button>

<splide [options]="{ type: 'loop', perPage: 1, keyboard: false }">
    <splide-slide *ngFor="let image of images" [selectedSlideIndex]="selectedImageIndex">
        <img [src]="image.src" alt="" />
       
    </splide-slide>
</splide> -->




