import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery'
import { AssetsService } from "../../core/services/assets.service";
import { Errors, UserService } from '../../core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeConstants, CatlougeTypeConstants } from "src/app/core/constants";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { DOCUMENT, Location } from '@angular/common';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';

declare var $: any;

declare var videojs;

@Component({
  selector: 'app-owl-banner-slider',
  templateUrl: './owl-banner-slider.component.html',
  styleUrls: ['./owl-banner-slider.component.css'],
  providers: [UserService, PlayListService]
})
export class OwlBannerSliderComponent implements OnInit {

  @Input() owlOption: OwlOptions;
  @Input() data: any = {};
  @Input() page_view;
  @Output() deleteRecentWatch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeAddtoWatchListModal') closeAddtoWatchListModal;
  @ViewChild('addToWatchListSuccessFromBanner') addToWatchListSuccessFromBanner;
  public bannerResult: any = null;
  streamInfo: any;
  public userInfo: any;
  setWatchLitemItem: any;
  playList: any;
  playListId: string;
  watchListAddedItem: any;
  error_string: any;
  addedAssetIndex: any;
  playListAvailable: boolean;
  playlist: any;
  homePageAssets: any;
  deleteAsset_id: any;
  error_code: any;
  checkTabUrl: any;
  isCloudFrontType: boolean = false;
  notFoundImg: any;



  track_detail: any;
  playing_index: number = -1;
  is_dropdown: boolean;
  is_audio: boolean;
  audio_list: any = [];
  is_another_track: boolean;

  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private googleEvent: EventTrackingService,
    public location: Location
  ) {
    this.checkTabUrl = location.path();

  }


  ngOnInit() {
    this.notFoundImg = "landscape";
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.playListId = localStorage.getItem('playListId');
    }

    this.bannerResult = this.data;
    if (this.commonService.filterType == 1) {
      this.isCloudFrontType = true;
    } else {
      this.isCloudFrontType = false;
    }
    console.log(this.bannerResult);
    let isIos = /iPhone/i.test(window.navigator.userAgent)
    // this.getWatchList();
    if (this.userInfo) {
      this.getWatchList();
    }
    if (isIos) {
      this.owlOption.autoplay = false;
      delete this.owlOption.responsive['0'].autoplayTimeout;
      delete this.owlOption.responsive['600'].autoplayTimeout;
      delete this.owlOption.responsive['1000'].autoplayTimeout;
      delete this.owlOption.responsive['1300'].autoplayTimeout;
    }

    this.data.forEach(element => {
      if (element.type == 'audio') {
        this.audio_list.push(element);
      }

    });

    console.log('audio_list', this.audio_list);

    var oldPl: any = document.getElementById('pl_one');

    // check if audio player already playing
    if (oldPl) {

      let code = $('#AudioNextButton').attr('data-code');
      if (code) {
        this.audio_list.forEach((element, i) => {
          if (element.code == code) {
            //   alert(code)
            this.playing_index = i;
            this.track_detail = element;
            this.is_audio = true;
            // this.is_pause = $(".vjs-play-control").attr("title") == 'Play' ? true : false;
          }
        });
      }
      if (!this.is_audio) {
        this.is_another_track = true
      }
    }
  }

  playVideo(item, content, loginContent) {

    if (this.userInfo && item.type != 'audio') {
      this.streamInfo = item;
      this.streamInfo['stop'] = this.streamInfo.stop ? this.streamInfo : 0;
      this.streamInfo['genre'] = this.streamInfo?.asset_detail?.genre;

      this.commonService.goToDetail(this.streamInfo, this.page_view);
      // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen close', size: 'xl', backdrop: 'static' }).result.then((result) => {
      // }, (reason) => {
      // });
    } else if (this.userInfo && item.type == 'audio') {
      console.log('track details', item);
      this.playAudio(item, content);
    }
    else {
      this.commonService.setBeforeLoginState(item);
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  AddToWatchListInBanner(e, item, loginContent) {

    // e.preventDefault();
    e.stopPropagation();

    if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
      this.watchListAddedItem = item;



      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter('Watchlist', 'Watchlist', userUnique + '-' + item.code + '-' + item.name, 0, userUnique, userUnique + '-' + item.code + '-' + item.name);



      let addToplayListParams = {
        playlistid: this.playListId,
        catlogue: CatlougeTypeConstants.catlogue,
        plateform: "web",
        playingtype: "Video",
        assetId: item.asset_mongo_id ? item.asset_mongo_id : item._id,
        assetType: item.type,
        action: "saveAssetInPlaylist",
      }

      this.addedAssetIndex = this.data.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      this.data[this.addedAssetIndex].is_watchlist_added = true;
      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);

      if ((item.asset_mongo_id ? item.asset_mongo_id : item._id) != undefined) {
        this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data) => {
          this.error_string = data['error_string'];
          this.error_code = data['error_code'];
          this.addToWatchListSuccessFromBanner.nativeElement.click();

          if (data.error_code == '200') {
            this.data[this.addedAssetIndex].is_watchlist_added = true;
            // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
            // document.getElementById('itemAddSuccessMsg').innerHTML= `Successfully added to Watchlist.`;  
          }

          if (data.error_code == '209') {
            this.data[this.addedAssetIndex].is_watchlist_added = false;
            // this.itemAddedIntoWach.nativeElement.innerHTML = 'This item already exist in your Watchlist ';
            // this.recommendedData.result[this.addedAssetIndexFromRecommended]['is_watchlist_added'] = true;
            this.deleteWatchListItem(item);
            // this.document.getElementById('itemAddSuccessMsg').innerHTML = `Already added to Watchlist.`;
          }


          setTimeout(() => {
            this.document.getElementById('closeAddToWatchListInBanner').click();
          }, 1500);

        });
      }


    }
    else {
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }



  addWatchList(loginContent) {

    if (this.userInfo) {
    }
    else {
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });

    }
  }

  deleteWatch(item) {
    this.deleteRecentWatch.emit(item);
  }

  deleteWatchListItem(item) {
    console.log(item);

    this.deleteAsset_id = item.asset_mongo_id ? item.asset_mongo_id : item._id;

    let deletePlayListAssets = {
      playlistid: this.playListId,
      assetId: this.deleteAsset_id,
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      // playlist_asset_id:item._id,
      action: "deleteAssetFromPlaylist"
    }

    let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets);
    this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data) => {
      // console.log(data);
      // let index = this.playlist.findIndex(playItem => playItem._id === item._id);
    });

  }


  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.UserService.convertoJSONToUrlEncoded(temp);
    console.log(temp);
    this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      console.log(data);
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          console.log(this.playlist);
          this.check_into_watchlist()
          return this.playlist;
        }
      }
    });

  }

  check_into_watchlist() {
    console.log(this.playlist);
    this.data.forEach((tempAsset, tempIndex) => {

      if (this.playlist.findIndex(tempObj => tempObj._id == tempAsset.asset_mongo_id) != -1) {
        this.data[tempIndex].is_watchlist_added = true;
      } else {
        this.data[tempIndex].is_watchlist_added = false;
      }
    });

    console.log(this.data);
  }
  playAnotherTrack(data) {
    console.log('annouce into child component');
    this.AssetsService.playTrack(data);

  }
  playAudio(data, content) {

    let index;
    index = this.audio_list.findIndex(obj => obj._id == data._id);

    this.playing_index = index;
    var oldPl = document.getElementById('pl_one');
    if ((oldPl && this.track_detail && this.track_detail._id != data._id || oldPl && this.is_another_track)
    ) {
      data.audio_list = this.audio_list;
      data.is_track_changed = true;
      this.playAnotherTrack(data);
      return;
    }
    if ((this.is_audio && oldPl && this.track_detail && this.track_detail._id != data._id)
    ) {

      document.querySelector("ngb-modal-window").remove();
      // remove player
      if (oldPl) {
        console.log('remove old player');

        videojs(oldPl).dispose();
      }
    }
    console.log('data', data);
    console.log('this.track_detail', this.track_detail);


    if (!this.track_detail
      || (this.track_detail && this.track_detail._id != data._id)
    ) {

      setTimeout(() => {
        this.playing_index = index;
        // this.cdr.detectChanges();
      }, 1000);

      console.log('player start');

      this.track_detail = data;
      this.track_detail.audio_list = this.audio_list;
      this.track_detail.current_index = index;
      // this.track_detail.recently_watched = this.album_details.recently_watched;
      this.track_detail.is_playlist = false;
      this.track_detail.charge_code = data.code;
      // this.track_detail.is_preview = this.is_preview;
      // this.track_detail.is_preview = this.is_preview;
      console.log(this.track_detail);
      setTimeout(() => {

        try {
          this.is_audio = true;
          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: '_play_modal_jw audio_bar', backdrop: false, keyboard: false }).result.then((result) => {
          }, (reason) => { });
        } catch (e) {
          console.log(e);
        }
      }, 0);
    } else if (this.track_detail._id != data._id) {


    }


  }



  public onModalClosed(data: any): void {

    if (data == 'close') {
      this.track_detail = '';
      this.playing_index = -1;
      this.is_audio = false;
      // this.is_preview = false;

      console.log('Picked date: ', data);

      this.modalService.dismissAll();
      this.AssetsService.trackActivity(data)
      //  this.cdr.detectChanges();


      // $('#album_similar').modal('hide');
      // document.getElementById("maincontentarea").classList.remove("before_similar");
    }

  }

  public onTrackActivity(activity_data) {
    console.log('activity_data', activity_data);

    this.playing_index = activity_data.index;
    this.AssetsService.trackActivity(activity_data)
    // this.is_pause = activity_data.is_pause;
    // this.cdr.detectChanges()
    // this.cdr.detach();

  }



  openVritualMandirModal(content, loginContent) {

    if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
      this.modalService.dismissAll();
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: "static", windowClass: 'vm-width' }).result.then((result) => {
      }, (reason) => {
      });
    } else {
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }


  }

  }