<div class="maincontentarea mt20">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">               
                <div class="_about_content">
                    <h2>About us</h2>
                    <div class="_about_inner_c">                        
                        <img class="_about_img" src="assets/img/logo.png" alt="Samatva">                        
                        <p>Samatva is a devotional OTT platform offering a novel experience of staying connected with the Almighty and your inner self.</p>
                        <p>Watch top devotional live TV channels and videos such as bhajans, kirtans, kathas, pravachans, updesh, aarti, etc.</p>
                        <p>Samatva is India’s first portal dedicated to assist millions of devotees in their spiritual and devotional journey and connect them with saints and seers to follow their path of enlightenment & divine knowledge and get their blessings.</p>
                        <p>Start your day with handpicked verses or browse through a large library of sermons by top gurus. Samatva has a dedicated page for every guru to showcase their exclusive content and live stream their events.</p>
                        <p>Samatva is the flagship product of Vide Mind Pvt Ltd. For more details, visit <a target="_blank" href="http://videmind.com">Vide Mind site.</a></p>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>