import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'image-manipulation',
  templateUrl: './image-manipulation.component.html',
  styleUrls: ['./image-manipulation.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class ImageManipulationComponent implements OnInit {

  BASE_ASSETS_PATH: string = environment.BASE_ASSETS_PATH;// 'https://res.cloudinary.com/digivive/image/upload/';
  APP_CLOUD_NAME: string = environment.APP_CLOUD_NAME;
  EXT: string = environment.SRC_EXT;

  @Input()
  public params: any = {};

  @Input()
  img: string;

  @Input()
  notFound: string;

  assetsImage: string = '';
  @Input() cloudFrontData: any = {};

  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    //this.assetsImage = this.commonService.compressImgUrl(this.img,this.params);
    this.assetsImage = this.commonService.compressImgUrl2(this.img,this.params,this.notFound);
  }
  ngAfterViewInit(): void {
  }

}
