import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { UserService } from 'src/app/core';
import { UserService } from "src/app/core/services/user.service";
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from "../../core/services/assets.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from "src/app/core/services/common.service";

declare var $: any;

declare var videojs;
@Component({
  selector: 'app-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.css']
})
export class CampaignBannerComponent implements OnInit {

  catlouge = CatlougeTypeConstants.catlogue;
  @Input() data: any = {};
  @Input() all_items: any = {};
  landingPage: any;
  episodeDeatil: any;
  imgType: any;
  showImg: any;
  width: string;
  bannerWidth: boolean;
  public userInfo: any;

  isCloudFrontType: boolean = false;
  categoryImage: any;
  thumbnailBannerStretchedLandscape: any;
  thumbnail: any;
  notFoundImg:string = 'image-not-found';
  assetImgParam: any;

  track_detail:any;
  playing_index:number = -1;
  is_dropdown:boolean;
  is_audio:boolean;
  audio_list:any = [];
  is_another_track:boolean;

  constructor(private router: Router, 
              public UserService: UserService, 
              public AssetsService: AssetsService,
              private modalService: NgbModal,
              public commonService: CommonService) { }

  ngOnInit(): void {

    localStorage.setItem('isfromVirtualMandir', "no");
    if(this.commonService.filterType ==1){
      this.isCloudFrontType = true ;
      this.categoryImage = this.data.category_image_cloudfront_url;
      this.thumbnailBannerStretchedLandscape = this.data.thumbnail_banner_stretched_landscape_cloudfront_url;
      this.thumbnail  = this.data.thumbnail_cloudfront_url;
    }else{
      this.isCloudFrontType = false;
      this.categoryImage = this.data.category_image;
      this.thumbnailBannerStretchedLandscape = this.data.thumbnail_banner_stretched_landscape;
      this.thumbnail  = this.data.thumbnail;
    }

    this.data = this.data;
    console.log('dataaaaaa',this.all_items);
    this.all_items.forEach(el => {
      if(el.asset.type == 'audio') {
        this.audio_list.push(el.assets[0]);
      }
    });

   
    console.log('audio_listaudio_list',this.audio_list);
    
    
    this.landingPage = this.data.landing_page;
    // this.imgType =this.data&&this.data.thumbnail_type&&this.data.thumbnail_type.slug?this.data.thumbnail_type.slug:'';
    this.imgType =this.data.thumbnail_type.slug;
    console.log('imgType',this.imgType)
    switch(this.imgType){
      case 'stretched-landscape':
        this.assetImgParam  = {"crop":'fill','width':860,'height':376};
        this.showImg = this.categoryImage;
        this.bannerWidth = true;
        break;
      case 'banner':
        this.bannerWidth =false;
        this.assetImgParam  = {"crop":'fill'};
        this.showImg = this.thumbnailBannerStretchedLandscape;
        break;
       default:
        this.assetImgParam  = {"crop":'fill'};
        this.showImg = this.thumbnail;
        break;
    }
  }

  redirectUrl(content,audioContent) {

    this.userInfo = localStorage.getItem('userInfo');
    console.log(this.data);
    
    // return
    if(this.userInfo && this.data.asset.type != 'audio' && this.data.asset.type != 'playlist' && this.data.asset.type !='podcast'){
    switch (this.landingPage) {
      case '__url':
        this.landingUrl();
        break;
      case '__app_page':
        this.landingAppPage();
        break;
      case '__sub_category':
        this.landingSubCategory();
        break;
      case '__asset':
        this.landingAsset();
        break;
      case '__webviewurl':
        this.landingVotingPage();
        break;  
      default: this.router.navigateByUrl('/');
        break;

    }
  }else if(this.userInfo && this.data.asset.type == 'audio'){
    this.playAudio(this.data.assets[0],audioContent);
  }else if(this.userInfo && this.data.asset.type == 'playlist'){
    let slug = 'audio/' + this.data.asset.name + '/' + this.data.asset.code + '/' + this.data.asset.type; 
    this.router.navigate([slug])
  }else if(this.userInfo && this.data.asset.type == 'podcast'){
    let slug = 'podcast/' + this.data.asset.name + '/' + this.data.asset.code + '/' + this.data.asset.type; 
    this.router.navigate([slug])
  }else{
    this.commonService.setBeforeLoginState(this.data);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }
  }

  landingUrl() {
    let url = this.data.url;
    window.open(url, '_blank').focus();
  }
  landingAppPage() {
    let appPage = this.data.app_page;

    switch (appPage) {
      case 'about':
        this.router.navigateByUrl('/aboutus');
        break;
      case 'faq':
        this.router.navigateByUrl('/contactus');
        break;
      case 'help':
        this.router.navigateByUrl('/contactus');
        break;
      case 'my-profile':
        this.router.navigateByUrl('/myaccount');
        break;
      case 'theme-setting':
        this.router.navigateByUrl('/');
        break;
      case 'subscription':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'activation-code':
        this.router.navigateByUrl('/subscribe/choseplan');
        break;
      case 'watchlist':
        this.router.navigateByUrl('/playlist');
        break;

      default: this.router.navigateByUrl('/');
        break;

    }
  }
  landingSubCategory() {
    if (this.data.sub_category_id != '' ||  this.data.sub_category_id != undefined) {
     

      let input_id=this.data.category_id;
      let r_url='/viewall';
      if(this.data.sub_category_id){
        input_id=this.data.category_id;
        r_url='/subcategory';
      }
      let viewAlldParams = {
        categoryId: input_id,
        // categoryType: this.data.category_type
      }

      viewAlldParams['type'] = this.data.tab_slug;
      localStorage.setItem('banner','campaign_banner');
      this.router.navigate([r_url], { queryParams: viewAlldParams })

    } else {
      let categoryId = this.data.category_id;
      let viewalltabDataParams = {
        banner: "campaign_banner",
        "categoryId" :categoryId,
        "type" : 'home'
      }

      this.router.navigate(['/subcategory'], { queryParams: viewalltabDataParams })

      // this.router.navigate(['viewall'],{queryParams:viewalltabDataParams});
    }
  }
  landingAsset() {
    let assetData = this.data.asset;
    this.router.navigate(['detail/' + this.data.asset.type + '/' + this.data.asset.code + '/' + this.data.asset.type])
    // code: "TSH338" type: "tvshow"
    // code: TSH336
    // catlogue: 5f48bc1eb9124f41a16c5712
    // platform: web
    // type: tvshow
  }


  landingVotingPage() {
    if(this.data.feed_type.toLowerCase()=='rss'){
      let redirectType = this.data.feed_type;
      let redirectId = this.data.rssfeed_id;
      window.open('panchang/'+redirectType+'/'+ redirectId, '_blank');
    }else if(this.data.feed_type.toLowerCase()=='horo'){
      let redirectType = this.data.feed_type;
      let redirectId = this.data.rssfeed_id;
      window.open('horoscope/'+redirectType+'/'+ redirectId, '_blank');
    }else{
      if(this.data.webviewurl!=''){
      let url = this.data.webviewurl;
      const splitArr = url.split("cid=");
      let eventid =splitArr[1];
      let sessId = localStorage.getItem('sessionId');
      window.open('content/'+eventid+'/'+ sessId, '_blank');
      }else{
        window.open('notfound/');
      }
    }
    
  }

 











  // getAssetsData() {
  //   // if (this.tempQueryStr.type == 'episode' || this.tempQueryStr.type == 'tvshow') {
  //   //         this.getSeasonsData();
  //   //       } 
  //   let assetData = this.data.asset;
  //   console.log('getAssetsData');
  //   let assetDetail = {
  //     "code": assetData.code,
  //     "catlogue": this.catlouge,
  //     "platform": 'web',
  //     "type": assetData.type
  //   }
  //   let urlEncodedDetail = this.UserService.convertoJSONToUrlEncoded(assetDetail);
  //   setTimeout(()=>{

  //     this.AssetsService.getAssetDetail(urlEncodedDetail).subscribe(data => {
  //       //console.log(data.result);
  //       if (data && data.result) {

  //         if (data.error_code === 401) {
  //           window.location.replace('404')
  //         }

  //         if (Object.keys(data.result).length === 0) {
  //           document.getElementById("nexg_ngb-progressbar").style.display = 'none';
  //             document.getElementById('maincontentarea').classList.remove("_beforeLoad");
  //           // this.showError = true;
  //         }
  //         else{
  //           // if(data.result.recently_watched.stop == ""){
  //           //   data.result.recently_watched.stop = "0";
  //           // }
  //           this.episodeDeatil = data.result;

  //         }


  //         console.log('this.episodeDeatil',this.episodeDeatil);
  //         // if(this.seasonData.result || this.seasonData.result.length>0){
  //         //   this.episodeDeatil = this.seasonData.result;
  //         // }
  //         // this.episodeDeatil['type'] = this.episodeDeatil.type ? this.episodeDeatil.type : this.tempQueryStr.type
  //         if(this.episodeDeatil.recently_watched){
  //           this.episodeDeatil['stop'] = this.episodeDeatil.recently_watched.stop;

  //         }
  //         console.log(this.episodeDeatil['stop']);
  //         // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage : this.episodeDeatil.image;
  //        // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage:this.episodeDeatil.image;

  //         // this.episodeDeatil.director = ''
  //         // this.episodeDeatil.producer = ''
  //         // this.episodeDeatil.writer = ''
  //         // this.episodeDeatil.cast = ''
  //         if (this.episodeDeatil.name != undefined)
  //         //   this.pageTitleService.setTitle(this.episodeDeatil.name);
  //         // else
  //         //   this.pageTitleService.setTitle(this.episodeDeatil.channel_name);


  //         // this.googleEventTracking.routeTrack(this.commonService.detailRoute(this.episodeDeatil));

  //         // this.meta.updateTag({ property: 'og:url', content:document.URL});
  //         // this.meta.updateTag({ property: 'og:title', content:this.episodeDeatil.name });
  //         // this.meta.updateTag({ property: 'og:image', content: bgImage });
  //         // this.meta.updateTag({ property: 'og:description', content:  this.episodeDeatil.synopsis});

  //         // if (this.tempQueryStr.type == 'tvshow') {
  //         //   this.episodeDeatil.image = bgImage;
  //         // }
  //         setTimeout(() => {
  //           try {
  //             console.log(this.episodeDeatil);
  //             try {
  //               if(this.episodeDeatil.recently_watched.season && this.episodeDeatil.recently_watched.season.season_number && this.episodeDeatil.recently_watched.no) {
  //                 // this.changeSeason(this.episodeDeatil.recently_watched.season.season_number, (this.episodeDeatil.recently_watched.season.season_number));
  //               }
  //             } catch(e){
  //               console.log(e);
  //             }
  //             // bgImage = this.commonService.compressImgUrl(bgImage, { 'width': 1280, 'height': 720 });
  //             // let bgImage = this.tempQueryStr.type == 'episode' ? this.episodeDeatil?.tvshow_image : this.tempQueryStr.type == 'livetv' ? this.episodeDeatil.chimage:this.episodeDeatil.image;
  //             // bgImage = this.commonService.compressImgUrl(bgImage, {'width':1280,'height':720});
  //           //  console.log(bgImage);
  //             // document.getElementById('detail_banner').style.backgroundImage = "url(" + bgImage + ")";

  //             document.getElementById("nexg_ngb-progressbar").style.display = 'none';
  //             document.getElementById('maincontentarea').classList.remove("_beforeLoad");


  //           }
  //           catch (e) {
  //             console.log(e)
  //           }

  //         }, 200)

  //         // if (this.tempQueryStr.type == 'episode' || this.tempQueryStr.type == 'tvshow') {
  //         //   this.getSeasonsData();
  //         // }         
  //         // recommended Section 
  //         let recommededParams = {
  //           code: this.episodeDeatil.type == 'livetv' ? this.episodeDeatil.code : this.episodeDeatil.recently_watched.code,
  //           search_text: '',
  //           catlogue: this.catlouge
  //         }
  //         // this.getSeasonsData();
  //         // this.recomData(recommededParams);


  //       }

  //     });
  //   }, 2000)


  // }

  // recomData(recommededParams) {
  //   setTimeout(() => {
  //     this.spinner.hide();
  //   }, 50);
  //   this.loading = true;
  //   let urlEncodedRecomdedParams = this.UserService.convertoJSONToUrlEncoded(recommededParams);
  //   this.AssetsService.getRecommended(urlEncodedRecomdedParams).subscribe(data => {
  //     this.recommendedData = data;
  //       if (this.recommendedData.result) {
  //         this.getWatchList();

  //         this.recommendedData.result.forEach(ele => {
  //           if (ele.type == "episode") {
  //             if (!this.episodeArr.find(epiObj => epiObj.name == ele.ss)) {
  //               this.episodeArr.push({ name: ele.ss })
  //             }
  //             this.firstSelectedEpisode = this.episodeArr[0].name;
  //           } 


  //         });
  //       }
  //   });

  // }

  playAnotherTrack(data) {
    console.log('annouce into child component');
    this.AssetsService.playTrack(data);
   
  }
  playAudio(data, content,index = 0) {

    index =  this.audio_list.findIndex(obj => obj._id == data._id);
  
   
   
    this.playing_index = index;
   console.log(data);
   let content_availability =  data.content_availability.toLowerCase();
      var oldPl = document.getElementById('pl_one');
      if(oldPl  && this.track_detail && this.track_detail._id != data._id
         || oldPl &&  this.is_another_track
         || oldPl  && !this.track_detail
         ) 
      {
        this.is_another_track = false;
        data.audio_list = this.audio_list;
        data.is_track_changed = true;
        this.playAnotherTrack(data);
        return;
      }
      if((this.is_audio && oldPl  && this.track_detail && this.track_detail._id != data._id) 
       ){
        document.querySelector("ngb-modal-window").remove();
        // remove player
        if (oldPl) {
         console.log('remove old player');
         
          videojs(oldPl).dispose();
        }
      }
      console.log('data',data);
      console.log('this.track_detail',this.track_detail);
      
      if(  this.track_detail&& content_availability != 'paid' && this.track_detail._id != data._id)
      {
        this.playing_index = -1;
        
      } else if(!this.track_detail && content_availability == 'paid') {
         setTimeout(() => {
          this.track_detail = '';
         }, 1000);
      }
      if(!this.track_detail  
      || (this.track_detail && this.track_detail._id != data._id )
       ) {
        setTimeout(() => {
          this.playing_index = index;
          // this.cdr.detectChanges();
        }, 1000);
       
        console.log('player start');
        
        this.track_detail = data;
        this.track_detail.audio_list = this.audio_list;
        this.track_detail.current_index = index;
        // this.track_detail.recently_watched = this.album_details.recently_watched;
        this.track_detail.is_playlist = false;
        this.track_detail.charge_code = data.code;
        // this.track_detail.is_preview = this.is_preview;
        // this.track_detail.is_preview = this.is_preview;
        console.log( this.track_detail);
        setTimeout(() => {
         
          try {
            this.is_audio = true;
            this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass: '_play_modal_jw audio_bar', backdrop: false, keyboard: false }).result.then((result) => {
            }, (reason) => { });
          } catch (e) {
            console.log(e);
          }
        }, 0);
      }else if(this.track_detail._id != data._id) {
      
      }
  
  
    }
  
   
  
  public onModalClosed(data: any):void {

    if(data == 'close') {
      this.track_detail = '';
      this.playing_index = -1;
      this.is_audio = false;
      // this.is_preview = false;
     
      console.log('Picked date: ', data);
 
      this.modalService.dismissAll();
      this.AssetsService.trackActivity(data)
    //  this.cdr.detectChanges();
    
      
      // $('#album_similar').modal('hide');
      // document.getElementById("maincontentarea").classList.remove("before_similar");
    } 
   
  }

  public onTrackActivity(activity_data) {
    console.log('activity_data',activity_data);
    
    this.playing_index = activity_data.index;
    this.AssetsService.trackActivity(activity_data)
    // this.is_pause = activity_data.is_pause;
    // this.cdr.detectChanges()
    // this.cdr.detach();

  }

}
