import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-track-settings-modal',
  templateUrl: './track-settings-modal.component.html',
  styleUrls: ['./track-settings-modal.component.css']
})
export class TrackSettingsModalComponent implements OnInit {


  data:any;
  modalRef:NgbModalRef;
  constructor() { }

  ngOnInit(): void {
  }
  onClose() {
    console.log(44);
    
    this.modalRef.close();
  }
}
