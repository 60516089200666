
import { Component, OnInit, Input, Output, Inject, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import * as $ from 'jquery' 
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'; // add this 1 of 4
import { CatlougeTypeConstants } from "src/app/core/constants/index";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { DOCUMENT } from '@angular/common';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { LocalStorageService } from '../../services/local-stroage.service';
import { customOptionsForBigSlider, customOptionsForEightMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider } from 'src/app/shared/owl-slider-config';
import { AssetsService } from '../../services/assets.service';
import { UserService } from '../..';


@Component({
  selector: 'asset-row',
  templateUrl: './asset-row.component.html',
  styleUrls: ['./asset-row.component.css']
})
export class AssetRowComponent implements OnInit {

  @ViewChild('addToWatchListSuccess') addToWatchListSuccess;

  @Input() owlOption: OwlOptions;
  @Input() item: any = {};
  @Input() data: any = {};
  @Input() index: any = {};
  @Input() slides: any = {};

  customOptionsForBigSlider = customOptionsForBigSlider;
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  customOptionsForEightMiniSlider = customOptionsForEightMiniSlider;


  assets_hover: boolean;
  userInfo: any;
  page_view: string;
  routeName: string;
  playListId: string;
  setPlayListItem: any;
  addedAssetIndex: any;
  error_code: any;
  deletedItem: any;
  deleteAsset_id: any;

  thumbnailType: any;
  thumbnailClass: string;
  notFoundImg:string = 'image-not-found';
  assetImgParam: any;
  categoryId: any;
  viewAllType: any;
  limit: number = 10;



  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    public pageTitleService: PageTitleService,
    @Inject(DOCUMENT) private document: Document,
    public localStorageService:LocalStorageService,
    private googleEvent: EventTrackingService
  ) {

    this.customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
    this.customOptionsForSevenMiniSlider['slides'] = 7;
   }

  ngOnInit(): void {

    this.router.events.subscribe((res) => {
      console.log(this.router.url,"Current URL");
      this.routeName = this.router.url;
      console.log(this.routeName);
    });

    

    let now = moment();
    
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // this.playListId = localStorage.getItem('playListId');
   
    if (this.userInfo) {
      this.playListId = localStorage.getItem('playListId');
    }

    

    this.data =  this.data;

    this.item = this.item;
    this.slides = this.slides;
    this.index = this.index;
    this.data = this.data;
    this.thumbnailType = this.data.thumbnail_type.slug;

    // if(Object.keys(this.data.thumbnail_type).length === 0){
    //   this.thumbnailType = 'landscape';
    // }else{
    //   this.thumbnailType = this.data.thumbnail_type.slug;
    // }
   
    switch (this.thumbnailType) {
      case 'landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam  = {"crop":'fill','width':292,'height':164};
        this.thumbnailClass = 'landscape_assets';
        this.notFoundImg = 'landscape';
        break;
      case 'enlarged-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam  = {"crop":'fill','width':390,'height':220};
        this.thumbnailClass = 'enlarged_landscape_assets';
        this.notFoundImg = 'enlarged_landscape';
        break;
      case 'stretched-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam  =  {"crop":'scale','width':390,'height':170};
        this.thumbnailClass = 'stretched_landscape_assets';
        this.notFoundImg = 'stretched_landscape';
        
        break;
      case 'portrait':
        this.owlOption = customOptionsForEightMiniSlider;
        this.assetImgParam  = {"crop":'fill','ar_2':3,'width':191,'height':286}; 
        this.thumbnailClass = 'portrait_assets';
        this.notFoundImg = 'portrait';
        break;
      case 'square':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam  =  {"crop":'scale','width':161,'height':161};
        this.thumbnailClass = 'square_assets';
        this.notFoundImg = 'square';
        break;
      case 'enlarged-square':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam  =  {"crop":'scale','width':292,'height':292};
        this.thumbnailClass = 'enlarged_square_assets';
        this.notFoundImg = 'enlarged_square';
        break;
      case 'circular':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam  = {"radius":'max','width':191,'height':191};
        this.thumbnailClass = 'circular_assets';
        this.notFoundImg = 'circle';
        break;
        case 'enlarged-circle':
          this.owlOption = customOptionsForSixMiniSlider;
          this.assetImgParam  = {"radius":'max','width':280,'height':280};
          this.thumbnailClass = 'enlarged-circular_assets';
          this.notFoundImg = 'circle';
          break;

      default: this.thumbnailClass = 'landscape_assets';
               this.assetImgParam  = {'width':330,'height':180};
               this.owlOption = customOptionsForFourMiniSlider;
               this.notFoundImg = 'landscape';
        break;
    }
  }

    redirectUrl(data, content, jwPlayerDom, event) {
      // event.stopPropagation();
      let viewAlldParams = {
        categoryId: data._id,
        categoryType: data.category_type
      }

      viewAlldParams['type'] = this.data.tab_slug;
      viewAlldParams['sub_category_id'] = data._id;

      // this.router.navigate(['/subcategory'], { queryParams: viewAlldParams });

      let viewalltabDataParams = {
        "plateform": "web",
        "catlogue": CatlougeTypeConstants.catlogue,
        "slug": this.data.tab_slug, //this.getCurrentSlug
        // "category_id": this.categoryId,
        // category_type+'_id':data._id
        "sub_category_id":data._id,
        //sub_sub_category_id:611671427465905fc4001b87,
        "call": 'index',
        "page": 1,
        "limit": this.limit
      };
      // this.router.navigate(['/viewall'], { queryParams: viewAlldParams });
      this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })
      

      // let viewalltabDataParamsJSONToUrlEncoded = this.UserService.convertoJSONToUrlEncoded(viewalltabDataParams);

      // this.AssetsService.getViewAll(viewalltabDataParamsJSONToUrlEncoded).subscribe(data => {
      //   if (Array.isArray(data.result)) {
      //     if (data.result.length > 0) {
      //       if(data.result[0].sub_category_count>0){
      //         this.data = data;
      //         document.getElementById("nexg_ngb-progressbar").style.display = 'none';
      //         document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      //       }
      //       else{
      //         this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
      //       }
      //       document.getElementById("nexg_ngb-progressbar").style.display = 'none';
      //       document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      //       console.log('maincontentarea');
      //     }
      //   }
  
      // });


      // this.userInfo = localStorage.getItem('userInfo');
  
  
      // if (this.userInfo) {
      //   let itemForDetailPage = {
      //     code: data.code,
      //     type: data.type,
      //   }
      //   let pagename = this.strToLower(this.data.category_name);
      //    this.page_view = this.page_view+ '_'+pagename.split(/\s/).join('');
  
      //   if (data.asset_cat_type == 'recentlywatched') {
          
      //     // localStorage.setItem('stop', data.stop?data.stop:0);
      //     // // itemForDetailPage['stop'] = data.stop?data.stop:'';
      //     // this.streamInfo = data;
      //     // console.log(this.streamInfo);
      //     // console.log('recentlywatched');
      //     // // this.modalService.open(jwPlayerDom, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen', size: 'xl', backdrop: 'static' }).result.then((result) => {
      //     // // }, (reason) => {
      //     // // });
  
      //     // this.modalService.open(jwPlayerDom, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen', backdrop: 'static', keyboard : false }).result.then((result) => {
      //     // }, (reason) => {
      //     // });
      //     this.commonService.goToDetail(data, this.page_view);
  
      //   } else {
      //     // console.log('sahil '+this.page_view);
      //     this.commonService.goToDetail(data, this.page_view);
  
      //   }
      //   // this.router.navigate(['detail/'+data.name+'/'+itemForDetailPage.code+'/'+ itemForDetailPage.type])
      // } else {
      //   this.commonService.setBeforeLoginState(data);
      //   this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //   }, (reason) => {
      //   });
      // }
    }

    viewall(data) {
      // console.log(this.router.url);
      console.log(data);
      let viewAlldParams = {
        categoryId: data._id,
        categoryType:data.category_type,
        subCategoryCount:data.sub_category_count
      }
  
      viewAlldParams['type'] = data.tab_slug;
      this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
    }

 
  
    AddToPlayListItems(e, item, content) {
  
      console.log(item);
      e.stopPropagation();
      this.routeName = this.router.url;
      if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
        this.setPlayListItem = item;
          let userUnique = '';
        if (this.userInfo.result.register_by == "mobile") {
          userUnique = this.userInfo.result.mobile;
        } else {
          userUnique = this.userInfo.result.emailaddress;
        }
  
        this.googleEvent.eventEmitter('Watchlist' , 'Watchlist', userUnique+'-'+item.code+'-'+item.name, 0, userUnique, userUnique+'-'+item.code+'-'+item.name);
  
        let addToplayListParams = {
          playlistid: this.playListId ? this.playListId : '',
          catlogue: CatlougeTypeConstants.catlogue,
          plateform: "web",
          playingtype: "Video",
          assetId: item.asset_mongo_id ? item.asset_mongo_id : item._id,
          show_id: item.tvshowId ? item.tvshowId : '',
          assetType: item.type ? item.type : '',
          action: "saveAssetInPlaylist",
        }
  
  
        if(this.routeName == '/') {
          this.addedAssetIndex =  this.data.assets.findIndex(d =>d['asset_mongo_id'] == item.asset_mongo_id)
        } else if(this.routeName == '/tvshows') {
          this.addedAssetIndex =  this.data.assets.findIndex(d =>d['asset_mongo_id'] == item.asset_mongo_id)
        } else if(this.routeName == '/movies') {
          this.addedAssetIndex =  this.data.assets.findIndex(d =>d['_id'] == item._id)
        } else if(this.routeName == '/livetv') {
          this.addedAssetIndex =  this.data.assets.findIndex(d =>d['_id'] == item._id)
        }
  
        let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);
        if ((item.asset_mongo_id ? item.asset_mongo_id : item._id) != undefined) {
  
          this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data) => {
            this.error_code = data.error_code;
            if (data.error_code == '200') {
              console.log(this.addedAssetIndex);
              this.data.assets[this.addedAssetIndex].is_watchlist_added = true;
              // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
              this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully added to Watchlist.`;
  
            }
            if (data.error_code == '209') {
              this.data.assets[this.addedAssetIndex].is_watchlist_added = false;
              console.log(item);
              this.deleteWatchListItem(item);
            }
            this.addToWatchListSuccess.nativeElement.click();
            setTimeout(()=>{
              this.document.getElementById('closeAddToWatchList').click();
            }, 1500);
  
            //this.toastr.success('Success!', '', { timeOut: 2000 });
            // this.closeAddtoWatchListModal.nativeElement.click();
  
          });
        } else {
          // this.closeAddtoWatchListModal.nativeElement.click();
        }
  
  
      } else {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        }, (reason) => {
        });
      }
    }

    deleteWatchListItem(item){
    
      this.deletedItem = item;
      if(this.routeName == '/') {
        this.deleteAsset_id = item.asset_mongo_id
      } else if(this.routeName == '/tvshows') {
        this.deleteAsset_id = item.asset_mongo_id
      } else if(this.routeName == '/movies') {
        this.deleteAsset_id = item._id;
      } else if(this.routeName == '/livetv') {
        this.deleteAsset_id = item._id;
      }
  
  
      let deletePlayListAssets = {
          playlistid:this.playListId,
          assetId:this.deleteAsset_id,
          catlogue:CatlougeTypeConstants.catlogue,
          plateform:"web",
          // playlist_asset_id:item._id,
          action:"deleteAssetFromPlaylist"
      }
  
      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets); 
      this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data)=>{
        this.document.getElementById('itemAddSuccessMsg').innerHTML = `Successfully removed From Watchlist.`;
  
        // console.log(data);
        // let index = this.playlist.findIndex(playItem => playItem._id === item._id);
      });
   
    } 
  
  item_hover_enter() {
    // console.log("item hover enter");
    this.assets_hover = true;
  }

  item_hover_leave() {
    // console.log("item hover leave");
    this.assets_hover = false;
  }
  strToLower(str: string) {

    if(str) {
      return str.toLowerCase();
    }
  }
  
}
