import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {


  copyLink: boolean = false;
  shared_data:any;
  userInfo:any;

  share_url_link:any;
  modalRef:NgbModalRef;
  constructor(private googleEvent: EventTrackingService) { }

  ngOnInit(): void {

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }

  contentCopied(e) {
    console.log(e);
    
    this.copyLink = e.isSuccess;
  }


  shareTB() {

    var twitterUrl = `https://twitter.com/intent/tweet?text=${this.shared_data.name}&url=${encodeURIComponent(this.shared_data.share_url)}`;

    var twitterWindow = window.open(twitterUrl,
      'twitter-popup',
      'height=350,width=600'
    );

    if (twitterWindow.focus) {
      twitterWindow.focus();
      if (Object.keys(this.userInfo.result).length > 0) {

        let userUnique = '';
        if (this.userInfo.result.register_by == "mobile") {
          userUnique = this.userInfo.result.mobile;
        } else {
          userUnique = this.userInfo.result.emailaddress;
        }

        this.googleEvent.eventEmitter("Share" , "FB Share",  userUnique + '-' + this.shared_data.code + '-' + this.shared_data.name, 0, userUnique, userUnique + '-' + this.shared_data.code + '-' + this.shared_data.name);

      }
    }
    return false;
  }

  shareFB() {

   

    var fbShareLink = encodeURIComponent(this.shared_data.share_url);
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + fbShareLink,
      'facebook-popup',
      'height=350,width=600'
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
      if (Object.keys(this.userInfo.result).length > 0) {

        let userUnique = '';
        if (this.userInfo.result.register_by == "mobile") {
          userUnique = this.userInfo.result.mobile;
        } else {
          userUnique = this.userInfo.result.emailaddress;
        }

        this.googleEvent.eventEmitter("Share" , "FB Share",  userUnique + '-' + this.shared_data.code + '-' + this.shared_data.name, 0, userUnique, userUnique + '-' + this.shared_data.code + '-' + this.shared_data.name);

      }
    }
    return false;
  }


  openMailBody() {

    let emailSubject = this.shared_data.name;
    let emailBody = encodeURIComponent(this.shared_data.share_url);
    var mail = `mailto:?subject=${emailSubject}&body=${emailBody} \n`;
    window.open(mail);
  }
  onClose() {
    console.log(44);
    
    this.modalRef.close();
  }


}
