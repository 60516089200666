


            <div class="modal-header">
                <h4 class="modal-title">Add to Playlist </h4>
                <button type="button" class="close" data-dismiss="modal" (click)="onClose()">&times;</button>
                
            </div>
            <div class="modal-body album_more_info_list">
                <label class="mb-3">You don't have any playlists. Create New.</label>
                <div class="audio_line mb-3"></div>
                <form class="audio_playlist">
                    <label>Add To Existing Playlists</label>
                    <ul>
                        <li>
                            <a href="javascript:void(0);">
                                <img src="../../../assets/img/playlist.png" />
                                <label>Bhakti Songs</label>
                                <p> {{data}} Tracks</p>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <img src="../../../assets/img/playlist.png" />
                                <label>Bhakti Songs</label>
                                <p>8 Tracks</p>
                            </a>
                        </li>
                    </ul>
                    <div class="audio_line mb-3"></div>
                    <label>Create New Playlist</label>
                    <input type="text" class="form-control" placeholder="Enter name of playlist" />

                    <button type="submit" class="audio_btn mt-4">Save</button>
                </form>
            </div>