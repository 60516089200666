<div class="maincontentarea">
    <div class="container">
        <div class="terms">
            <h2>Privacy Policy</h2>
            <p>This privacy policy (Privacy Policy) explains the details of our collection, processing, and disclosure of information, including your personal information, as may be collected by on the website accessible at <a href="javascript:void(0);" routerLink="/">www.samatva.tv</a> and on the Samatva mobile application (Platform) by Digivive Services Private Limited and/ or its representatives authorized to solicit subscriptions to the Platform  You should read this Privacy Policy in its entirety and in conjunction with the Terms of Use, available at <a href="javascript:void(0);" routerLink="/tnc">www.samatva.tv/tnc</a></p>
          
            
            <h2>Scope of Policy and Terms</h2>
            <ol>
                <li>By accessing, downloading, or using the Platform regardless of the device or internet enabled resource used to access our Platform (Device), you expressly consent to be bound by the terms of this Privacy Policy. If you do not agree with the terms of this Privacy Policy, you are requested to immediately cease your access and use of the Platform and any part of the services as provided by Digivive on the Platform. Your use of the Platform indicates your acceptance of the terms set out in this Privacy Policy.</li>
                <li>This Privacy Policy is being published in compliance with, inter alia the provisions of the Information Technology Act, 2000, and the Information Technology (Reasonable Security Practices and Procedures, Sensitive Personal Data or Information) Rules, 2011, the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 and the Indian-Computer Emergency Response Team directions issued by the Ministry of Electronics and Information Technology.</li>
                <li>This Privacy Policy is concerned with your use of the Platform per se, regardless of the Device used to access our Platform.</li>
                <li>You hereby provide your free and informed consent to such use, collection, and disclosure of your personal information by Digivive as is set out in this Privacy Policy</li>
            </ol>

            <h2>Information We Collect</h2>
            <p>5. In the course of your interaction with the Platform, we inter alia collect the following information:</p>
            <ol type="a">
                <li>Information you submit to us: You give us your personal information by using our Platform, and by registering for use of the Platform. Such information will also include information you provide to us by corresponding with us. In the course of your interaction with the Platform, you may submit following information to us:
                    <ol type="i">
                        <li>First Name;</li>
                        <li>Last Name;</li>
                        <li>E-Mail address;</li>
                        <li>Phone Number;</li>
                        <li>Residential Address;</li>
                        <li>Billing Address; </li>
                        <li>Mode of Payment;</li>
                        <li>Information pertaining to preferences, account settings, reviews and comments, or any other information voluntarily provided by you in the course of your interaction with our Platform;</li>
                    </ol>
                </li>
                <li>Information we collect about you: In order to efficiently provide our services and in the interest of ensuring that your interactions with the Platform are smooth and efficient, we automatically collect the following information:
                    <ol type="i">
                        <li>Device IDs and other unique identifiers including but not limited to IP address, browser type, version, network service provider details, browser settings, operating system, mobile network information including carrier details, and device settings. </li>
                        <li>Information re interaction of your browser or mobile application with our servers including but not limited to crash reports, system activity, date, and time of your URL request. </li>
                        <li>Information retrieved from Device access provided by you including the location, microphone, and audio on the Device;</li> 
                        <li>Information collected by cookies, and other technologies, also including advertisement data, etc.; </li>
                        <li>Information re your activity on our platform including inter-alia terms you search for, videos you watch, and purchase activity;</li>

                    </ol>
                </li>

            </ol>
    
            <h2>Why We Collect Information</h2>
            <p>6.	Digivive collects your personal information to provide the services on the Platform, and for the purposes as outlined below: </p>
            <ul>
                <li>To provide you with services on the Platform;</li>
                <li>To validate a registered user of the Platform;</li>
                <li>To validate a person publishing a comment on the Platform;</li>
                <li>To send you information pertaining to the Platform through e-mail and SMS;</li>
                <li>To send you promotional materials and marketing surveys re the Platform; </li>
                <li>To provide personalized recommendations, customized search results, and personalized advertisements;</li>
                <li>To process grievances raised by you against the Platform; </li>
                <li>For research and analytical purposes; and</li>
                <li>To the extent necessary to ensure compliance with applicable laws.</li>
            </ul>
            <h2>Security of Information</h2>
            <p>7.	We understand that we must safeguard your personal information available with us. We therefore use and employ reasonable technical, organizational, logical, physical, security practices and procedures to protect your personal information against any unauthorized access, damage, modification, disclosure, impairment or use as prescribed by applicable law.</p>

            <h2>Disclosure of your Information </h2>
            <p>8.	Digivive may disclose your personal information for the purposes as outlined below:</p>
            <ul>
                <li>To third parties that provide services to Digivive to ensure that you have smooth experience on the Platform;</li>
                <li>For external data analysis, in an anonymized form;</li>
                <li>As mandated by law, such as to comply with a judicial order, executive orders, requirement by a law enforcement authority, or by other legal processes; </li>
                <li>When Digivive believes in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, and, or such disclosure is necessary to investigate fraud or crime;</li>
                <li>If Digivive is involved in a merger, acquisition, or sale of all or substantially amount of its assets or equity; and</li>
                <li>To any other third party with your prior and express consent.</li>

            </ul>
            <h2>Marketing and Analytics</h2>
            <p>9.	Digivive may send you messages and e-mails carrying promotional materials and marketing surveys. </p>
            <p>10.	Digivive and our third-party service providers, including our marketing partners and analytics providers, may use mobile tracking technologies and/or, website cookies to distinguish you from other users of the Platform. This helps Digivive to provide you with a good experience when you use the Platform and allows Digivive to improve the Platform. </p>
            <p>11.	Digivive may provide access of anonymized individual data to analytics companies in order to help us understand how the Platform is used and can be improved upon. We may link the information we store within the analytics software to any personal information you submit within the Platform. </p>
            <h2>Your Rights </h2>
            <p>12.	You have the following rights in relation to the personal information we hold about you:</p>
            <ul>
                <li>The right to access the information we hold about you, and also to obtain details of processing of your personal information by Digivive;</li>
                <li>The right to withdraw your consent to our continued processing of your personal information, at any point of time, subject only to any legitimate reason that Digivive may have for continued processing of your personal information; </li>
                <li>The right to correct, update, or amend the details of your personal information as available with us; and</li>
                <li>The right to erasure of any personal data that we process, in certain circumstances and subject to applicable laws. </li> 
            </ul>

            <h2>Retention Policy</h2>
            <p>13.	Retention periods for records are based on the type of record, the nature of the activity and product and service that the same is associated with, and linked to. The periods for retention of your personal information by Digivive vary, depending on specific legal, business, and statutory requirements.</p>            
            <h2>Children</h2>
            <p>14.	You must be 18 years of age or older to subscribe to the Platform. </p>
            <p>15.	In the event that the end user is below 18 years of age, their access to the Platform should always be monitored and supervised by a legal guardian who should be the operator of an account on the Platform and, or a licensee of the Platform. </p>
            <p>16.	Your continued use of the Platform and its services confirms that you are either over 18 years of age, or are accessing the Platform and the supervision of a legal guardian. </p>
            
            <h2>Changes </h2>
            <p>17.	We will inform you of the terms of this Privacy Policy once every year. Any changes we may make to our Privacy Policy in the future will be posted on the Platform and you will be required to read and accept all such revised terms in order to continue your use of the Platform.</p>
            
            <h2>Our Contact Information</h2>
            <p>18.	Please feel free to contact our Grievance Officer by email at: <a href="mailto:cgo@samatva.tv">cgo@samatva.tv</a> about any questions regarding the Privacy Policy, or security of your personal information, or if you have any concerns, requests, comments, or grievances regarding our use of your information. .</p>
            <p>This Policy was last updated on 12 August 2022 at 11:00 hrs.</p>
        </div>        
    </div>
</div>