
<div class="campaign-banner" (click)="redirectUrl(content,audioContent)">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <image-manipulation  [img]="showImg" [params]="assetImgParam"></image-manipulation>

                <!-- <image-manipulation [img]="showImg" [params]="assetImgParam" [notFound]="notFoundImg"></image-manipulation> -->
                <!-- <img class="grid_item" src="{{showImg}}"  [ngClass]="{'bannerWidth': bannerWidth }" > -->
            </div>
        </div>
    </div>
</div>
<!-- <div *ngIf="data.landing_page != '' || data.landing_page == '__url' ">

</div>
<div>
    {{data | json}}
</div> -->

<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>


<ng-template #audioContent let-modal>
    <app-audio-player [streamInfo]="track_detail" (onTrackActivity)="onTrackActivity($event)" (onModalClose)="onModalClosed($event)"></app-audio-player>
 </ng-template>



