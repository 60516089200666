import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vouchers-faq',
  templateUrl: './vouchers-faq.component.html',
  styleUrls: ['./vouchers-faq.component.css']
})
export class VouchersFaqComponent implements OnInit {
public sessId;
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.sessId = localStorage.getItem('sessionId');
  }


  landingPage(sessionId) {
    if (navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Android/i)) {
      this.router.navigate(['vouchers/' + sessionId]);
  }
    this.router.navigate(['vouchers/' + sessionId]);
  }

}
