import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-track-info-modal',
  templateUrl: './track-info-modal.component.html',
  styleUrls: ['./track-info-modal.component.css']
})
export class TrackInfoModalComponent implements OnInit {


  data:any;
  modalRef:NgbModalRef;
  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
    console.log(this.data);
    
  }

  ngOnDestroy() {
    this.modalRef.close();
  }

  onClose() {
    console.log(44);
    
    this.modalRef.close();
    // this.modalService.dismissAll();
  }

}
