
<audio loop   id="pl_one" class="video-js vjs-fluid" controls preload="auto"  width="640" height="360" playsinline >
    <p class="vjs-no-js">
       To view this video please enable JavaScript, and consider upgrading to a
       web browser that
       <a href="https://videojs.com/html5-video-support/" target="_blank">
         supports HTML5 video
       </a>
     </p> 
</audio>

<div class="modal auth" id="album_similar" data-backdrop= "false" *ngIf="audioType!='pc_episode'">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <h4 class="modal-title">album_similar</h4>
            </div> -->
            <div class="modal-body container audio_similer">
                <div class="row">
                    <div class="col-md-6" *ngIf="recommandation_album_details">
                        <div class="album_details_width" >
                            <!-- <img [src]="recommandation_album_details.thumbnail" /> -->
                            <image-manipulation  *ngIf="recommandation_album_details.image"
                   
                            [img]="isCloudFrontType == false ? recommandation_album_details.image : recommandation_album_details.image_square_cloudfront_url"
                            [params]="{'width':300,'height':300}" [notFound]="notFoundImg">
                         </image-manipulation>
                            <label>{{recommandation_album_details.name}}</label>
                            <p>{{recommandation_album_details.highlighted_label}}</p>
                            <div class="album_detail_option">
                                <ul>
                                    <li>
                                        <a href="javascript:void(0);" (click)="openShareModal()">
                                            <img src="../../../assets/img/share.png" alt="" title="" />
                                            <span>Share</span>
                                        </a>
                                    </li>
                                    <!-- <li>
                                        <a href="javascript:void(0);" (click)="addToPlaylist(recommandation_album_details)">
                                            <img src="../../../assets/img/add_to_playlist.png" alt="" title="" />
                                            <span>Add To Playlist</span>
                                        </a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="audio_similar_list">
                            <h4>SIMILAR</h4>
                            <ul>
                                <li *ngFor="let recommandation_audio of recommandation_audio_list;let i = index" (click)="playSimilarAudio(recommandation_audio,i)">
                                    <div class="album_track_list_img">
                                         <!-- <img class="album_free_icon" src="[../../../assets/img/free_tag_76x35.png]" alt="" title="" /> -->
                                        <img class="album_free_icon" *ngIf="recommandation_audio.content_availability.toLowerCase() == 'free' && recommandation_audio.hide_tag != '1'" src="../../../assets/img/free_tag_76x35.png" alt="" title="" />
                                        <img *ngIf="recommandation_audio.content_availability.toLowerCase() == 'paid' && recommandation_audio.hide_tag != '1'" class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <!-- <img  [src]="recommandation_audio.image_square_cloudfront_url ? recommandation_audio.image_square_cloudfront_url : recommandation_audio.image_cloudfront_url" alt="" title="" /> -->
                                        <image-manipulation 
                   
                                        [img]="isCloudFrontType == false ? recommandation_audio.image : recommandation_audio.image_square_cloudfront_url"
                                        [params]="{'width':300,'height':300}" [notFound]="notFoundImg">
                                     </image-manipulation>
                                    </div>
                                    <div class="similar_info_list">
                                        <label>{{recommandation_audio.name}}</label>
                                        <p>{{recommandation_audio.highlighted_label}}</p>
                                    </div>
                                    <!-- <span >{{(recommandation_audio.hours * 60) +  (recommandation_audio.min *1)}}:{{recommandation_audio.sec}}</span> -->
                                    <span class="time_block_fix">{{recommandation_audio.hours}}:{{(recommandation_audio.min)}}:{{recommandation_audio.sec}}</span>
                                </li>
                                
                                <!-- <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li>
                                <li>
                                    <div class="album_track_list_img">
                                        <img class="album_paid_icon" src="../../../assets/img/crown.png" alt="" title="" />
                                        <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvaG9tZXNjcmVlbi1zdWItY2F0ZWdvcnkvU0NURzM0Mi8xNjYzNjcwMTIwLnBuZyIsImVkaXRzIjp7Im5vcm1hbGl6ZSI6ZmFsc2UsImdyYXlzY2FsZSI6ZmFsc2UsIndlYnAiOnRydWUsInNoYXJwZW4iOmZhbHNlLCJyZXNpemUiOnsid2lkdGgiOjcwLCJoZWlnaHQiOjcwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                                    </div>
                                    <div class="similar_info_list">
                                        <label>Jaya Kishori Akshardham Temple Ahemadabad aya Kishori Akshardham Temple Ahemadabad</label>
                                        <p>Jaya Kishori</p>
                                    </div>
                                    <span>8:33</span>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <a data-toggle="modal" data-target="#showError" #showErrorMsg></a>
<div class="modal auth _premium_msg" id="showError">
  <div class="modal-dialog">
    <div class="modal-content">

      
      <div class="modal-header">
        <button type="button" #closeShowErrorMsg (click)="removeErrorBox()" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="success-add">
          <p>{{error}}</p>
        </div>

        <div class="action-btn">
          <div>
            <button type="button" class="btn btn-primary yes" (click)="removeErrorBox()">Ok</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div> -->




