import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core';
import { OpenLayoutComponent } from './layout/open-layout/open-layout.component';
import { RegisterComponent } from "./views/auth/register/register.component";
import { DetailEpisodeComponent } from './views/detail-episode/detail-episode.component';
import { HomeComponent } from './views/home/home.component';
import { ViewallComponent } from './views/home/viewall/viewall.component';
import { PagenotfoundComponent } from './views/pagenotfound/pagenotfound.component';
import { ServerErrorComponent } from './views/server-error/server-error.component';
import { ContactusComponent } from './views/contactus/contactus.component';
import { MediaComponent } from './views/media/media.component';
import { PolicyComponent } from './views/policy/policy.component';
import { TermsComponent } from './views/terms/terms.component';
import { AdvertiseComponent } from './views/advertise/advertise.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { DynamicTabComponent } from './views/dynamic-tab/dynamic-tab.component';
import { FaqComponent } from './views/faq/faq.component';
import { GurujiTabComponent } from './views/guruji-tab/guruji-tab.component';
import { RedeemComponent } from './views/redeem/redeem.component';
import { ExclusiveGuard } from './core/guard/exclusive.guard';
import { ContestComponent } from './views/contest/contest.component';
import { PanchangComponent } from './views/panchang/panchang.component';
import { PodcastComponent } from './views/podcast/podcast.component';
import { VouchersComponent } from './views/vouchers/vouchers.component';
import { VouchersDetailComponent } from './views/vouchers-detail/vouchers-detail.component';
import { VouchersFaqComponent } from './views/vouchers-faq/vouchers-faq.component';
import { HoroscopeComponent } from './views/horoscope/horoscope.component';
import { VirtualMandirComponent } from './views/virtual-mandir/virtual-mandir.component';


const hostname = 'Samatva ';
export const routes: Routes = [
  // { path: 'nexg', redirectTo: '', pathMatch: 'full' },

  // {
  //   path: '', component: HomeComponent,data: {title:`${hostname}| Home` }
   
  // },
  {
    path: '', component: DynamicTabComponent,data: {title:`${hostname}| Home`, description: '' }
   
  },

  { 
    path: 'detail/:name/:code/:type', 
    loadChildren: () => import('./views/detail-episode/detail-episode.module').then(m => m.DetailModule),
    data: {
      componentName:"DetailEpisodeComponent"
    }
  },
  // { 
  //   path: 'guruji', 
  //   loadChildren: () => import('./views/guruji-tab/guruji-tab.module').then(m => m.GurujiTabModule),
  //   data: {
  //     componentName:"GurujiTabComponent"
  //   }
  // },
  { 
    path: 'viewall', 
    loadChildren: () => import('./views/viewall/viewall.module').then(m => m.ViewallModule),
    data: {title: `${hostname}| View All`, description:''}
  },

  {
    path: 'livetv',
    loadChildren: () => import('./views/livetv/livetv.module').then(m => m.LivetvModule),
    data: {title: `${hostname}| Live Tv`, description:''}

  },
  {
    path: 'movies',
    loadChildren: () => import('./views/movies/movies.module').then(m => m.MoviesModule),
    data: {title: `${hostname}| Movies`, description:''}
},
  {
    path: 'tvshows',
    loadChildren: () => import('./views/tvshows/tvshow.module').then(m => m.TvshowModule),
    data: {title: `${hostname}| Series`, description:''}
  },
  {
    path: 'videos',
      loadChildren: () => import('./views/videos/video.module').then(m => m.VideosModule),
    data: {title: `${hostname}| Videos`, description:''}
  },
  {
    path: 'originals',
    loadChildren: () => import('./views/originals/original.module').then(m => m.OriginalsModule),
    data: {title: `${hostname}| Originals`, description:''}
  },
  {
    path: 'playlist',
    loadChildren: () => import('./views/playlist/playlist.module').then(m => m.PlaylistModule),
    data: {title: `${hostname}| Watchlist`, description:''}
    
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
    data: {title: `${hostname}| My Account`, description:''}
  },
  {
    path: 'search',
    loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule),
    data: {title: `${hostname}| Search`, description:''}
  },
  {
    path: 'subscribe/payment',
    loadChildren: () => import('./views/subscribe/payment/payment.module').then(m => m.PaymentModule),
    data: {title: `${hostname}| Payment`, description:''}
  },
  {
    path: 'subscribe/choseplan',
    loadChildren: () => import('./views/subscribe/chose-plan/chose-plan.module').then(m => m.ChosePlanModule),
    data: {title: `${hostname}| Plan Details`, description:''}
  },
  {
    path: 'subcategory',
    loadChildren: () => import('./views/sub-category-list/sub-category-list.module').then(m => m.SubCategoryListModule),
    // data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'subsubcategory',
    loadChildren: () => import('./views/sub-sub-category-list/sub-sub-category-list.module').then(m => m.SubSubCategoryListModule),
    // data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'audio/:name/:code/:type',
    loadChildren: () => import('./views/album-detail/album-detail.module').then(m => m.AlbumDetailModule),
    // data: {title: `${hostname}| Plan Details`}
  },
  {
    path: 'audio-playlists',
    loadChildren: () => import('./views/audio-playlists/audio-playlists.module').then(m => m.AudioPlaylistsModule),
    // data: {title: `${hostname}| Plan Details`}
  },
 
  {
    path: 'podcast/:name/:code/:type',
    component:PodcastComponent,data: {title: 'podcast', description:''}
  },
  {
    path: 'auth',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule),data: {title: 'Home', description:''}
      }]
  },
  {
    path: 'dashboard',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      }]
  },
  {
    path: 'admin',component: OpenLayoutComponent,
    children: [
         {
          path: 'home',
          loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      }]
  },
  {
    path: '404',
    component:PagenotfoundComponent,data: {title: '404', description:''}
  },
  {
    path: '500',
    component:ServerErrorComponent,data: {title: '500', description:''}
  },

  {
    path: 'contactus',
    component:ContactusComponent,data: {title: 'Contact us', description:''}
  },
  {
    path: 'media',
    component:MediaComponent,data: {title: 'media', description:''}
  },
  {
    path: 'aboutus',
    component:AboutusComponent,data: {title: 'About us', description:''}
  },
  {
    path: 'privacy',
    component:PolicyComponent,data: {title: 'Privacy Policy', description:''}
  },
  {
    path: 'tnc',
    component:TermsComponent,data: {title: 'Term & Condition', description:''}
  },
  {
    path: 'faq',
    component:FaqComponent,data: {title: 'FAQs', description:''}
  },
  {
    path: 'advertise',
    component:AdvertiseComponent,data: {title: 'Advertise', description:''}
  },
  {
    path: 'redeem',
    component: RedeemComponent,data: {title: `${hostname} | Redeem`}
  },
  {
    path: 'content/:id',
    component: ContestComponent,data: {title: `${hostname} | Contest`}
  },
  {
    path: 'content/:id/:sessionId',
    component: ContestComponent,data: {title: `${hostname} | Contest`}
  },
  {
    path: 'panchang/:rtype/:rid',
    component: PanchangComponent,data: {title: `${hostname} | Panchang`}
  },
  {
    path: 'panchang/:rtype/:rid/:sessionId',
    component: PanchangComponent,data: {title: `${hostname} | Panchang`}
  },
  {
    path: 'vouchers',
    component: VouchersComponent,data: {title: `${hostname} | Vouchers`}
  },
  {
    path: 'vouchers/:sessionId',
    component: VouchersComponent,data: {title: `${hostname} | Vouchers`}
  },
  {
    path: 'vouchers-detail/:voucherId',
    component: VouchersDetailComponent,data: {title: `${hostname} | Vouchers Detail`}
  },
  {
    path: 'vouchers-detail/:voucherId/:sessionId',
    component: VouchersDetailComponent,data: {title: `${hostname} | Vouchers Detail`}
  },
  {
    path: 'vouchers-faq',
    component: VouchersFaqComponent,data: {title: `${hostname} | Vouchers FAQ's`}
  },
  {
    path: 'horoscope/:rtype/:rid',
    component: HoroscopeComponent,data: {title: `${hostname} | Horoscope`}
  },
  {
    path: 'horoscope/:rtype/:rid/:sessionId',
    component: HoroscopeComponent,data: {title: `${hostname} | Horoscope`}
  },
  {
    path: 'virtual-mandir',
    component: VirtualMandirComponent,data: {title: `${hostname} | Virtual Mandir`}
  },
  {
    path: 'virtual-mandir/:sessionId',
    component: VirtualMandirComponent,data: {title: `${hostname} | Virtual Mandir`}
  },
  
  // put all route name above this path object
  { 
    path: ':name', 
    loadChildren: () => import('./views/guruji-tab/guruji-tab.module').then(m => m.GurujiTabModule),
    data: {
      componentName:"GurujiTabComponent"
    }
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  OpenLayoutComponent,
];
