import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-to-playlist-modal',
  templateUrl: './add-to-playlist-modal.component.html',
  styleUrls: ['./add-to-playlist-modal.component.css']
})
export class AddToPlaylistModalComponent implements OnInit {

  data:any;
  modalRef:NgbModalRef;
  constructor() { }

  ngOnInit(): void {
  }
  onClose() {
    console.log(44);
    
    this.modalRef.close();
  }

}
