<div class="container">
    <button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item" id="openLogin"
        (click)="openLoginModal(content)" #openLoginllModel>Open Login Modal</button>

    <ng-template #content let-modal>
        <app-login></app-login>
    </ng-template>
    <div class="maincontentarea">
        <section class="panchang">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                    <i *ngIf="isfromVirtualMandir" class="voucher-ion-left mblicon fa fa-angle-left" (click)="landingPage(sessionId)"></i>
                        <h4>Panchang</h4>
                    </div>
                    <div class="col-md-12">
                        <form [formGroup]="panchangForm">
                            <select (change)="getPanchang(panchangevent.value)" #panchangevent>
                                <option value="Agra-UP&India" selected="selected">Agra</option>
                                <option value="Ahemadabad-Gujarat&India">Ahemadabad</option>
                                <option value="Bangalore-India">Bangalore</option>
                                <option value="Bhopal-India">Bhopal</option>
                                <option value="Bhubaneswar-India">Bhubaneswar</option>
                                <option value="Chandigarh-India">Chandigarh</option>
                                <option value="Chennai-India">Chennai</option>
                                <option value="DehraDun&India">Dehradun</option>
                                <option value="Hyderabad-AP-India">Hyderabad</option>
                                <option value="Jaipur">Jaipur</option>
                                <option value="Kolkata-India">Kolkata</option>
                                <option value="Lucknow-India">Lucknow</option>
                                <option value="Mumbai-Maharastra-India">Mumbai</option>
                                <option value="NewDelhi-India">New Delhi</option>
                                <option value="Patna-Bihar-India">Patna</option>
                                <!--<option value="Ajmer&India">Ajmer</option>
                        <option value="Aligarh-UP&India">Aligarh UP</option>
                        <option value="Allahabad-UP&India">Allahabad</option>
                        <option value="Amadavad-Gujarat&India">Amadavad Gujarat</option>
                        <option value="Amaravati-Maharastra&India">Agra UP</option>
                        <option value="Amritsar-India">Amritsar</option>
                        <option value="Anand-Gujarat&India">Anand Gujarat</option> -->
                                <!-- <option value="Aurangabad-Maharastra&India">Aurangabad Maharastra</option>
                        <option value="Badrinath-Uttarakhand&India">Badrinath Uttarakhand</option>
                        <option value="Banaras-India">Banaras</option>
                        
                        <option value="Baroda-India">Baroda</option>
                        <option value="Belgaum-India">Belgaum</option>
                        <option value="Bharuch-India">Bharuch</option>
                        <option value="Bhatinda-Punjab&India">Bhatinda Punjab</option>
                        <option value="Bhavnagar-Gujarat&India">Bhavnagar Gujarat</option>
                        
                        
                        <option value="Bhuj-India">Bhuj</option>
                        <option value="Bikaner-India">Bikaner</option> -->
                                <!-- <option value="Bilaspur-Chhattisgarh&India">Bilaspur Chhattisgarh</option>
                        
                        <option value="Coimbatore-India">Coimbatore</option>
                        
                        <option value="Kolkata-India">Culcutta / Kolkata</option>
                        <option value="Dakor-Kheda-Gujarat&India">Dakor Kheda Gujarat</option>
                        <option value="Darjiling-WestBengal&India">Darjiling</option>
                        
                        <option value="Deoria-UP-India">Deoria UP</option>
                        <option value="Dharmapuri&India">Dharmapuri</option>
                        <option value="Dharwar-India">Dharwar</option>
                        <option value="Dwarka-India">Dwarka</option>
                        <option value="Ernakulam-India">Ernakulam</option>
                        <option value="Gangotri-Uttarakhand&India">Gangotri Uttarakhand</option>
                        <option value="Gauhati-India">Gauhati</option>
                        <option value="Gaya(HolyGayaJi)-Bihar&India">Gaya Bihar</option> -->
                                <!-- <option value="Ghaziabad-UP&India">Ghaziabad</option>
                        <option value="Godhra-Gujarat&India">Godhra Gujarat</option>
                        <option value="Guntur-AndraPradesh-India">Guntur AndraPradesh</option>
                        <option value="Gurgaon-Haryana&India">Gurgaon Haryana</option>
                        <option value="Haridwar-Uttarakhand&India">Haridwar Uttarakhand</option>
                        <option value="Himatnagar-Gujarat&India">Himatnagar Gujarat</option>
                        <option value="Hubli-India">Hubli</option>
                        
                        <option value="Idar-Gujarat&India">Idar Gujarat</option>
                        <option value="Imphal-Manipur&India">Imphal Manipur</option>
                        <option value="Indore-India">Indore</option>
                        
                        <option value="Jaisalmer-India">Jaisalmer</option>
                        <option value="Jalandhar-India">Jalandhar</option>
                        <option value="Jammu-Kashmir&India">Jammu Kashmir</option>
                        <option value="Jamnagar-India">Jamnagar</option>
                        <option value="Jamshedpur-India">Jamshedpur</option>
                        <option value="Jhansi-UP&India">Jhansi</option>
                        <option value="Jodhpur-India">Jodhpur</option>
                        <option value="Jullundhur-Punjab&India">Jullundhur</option>
                        <option value="Kakinada-AP&India">Kakinada</option>
                        <option value="Kalyan-India">Kalyan</option>
                        <option value="Kallakkurichchi-India">Kallakkurichchi Viluppuram</option>
                        <option value="Kanchipuram-India">Kanchipuram</option>
                        <option value="Kanpur-UP&India">Kanpur</option>
                        <option value="Kedarnath-Uttarakhand&India">Kedarnath</option>
                        <option value="Khedabrahma-Sabarkantha-Gujarat&India">Khedabrahma</option>
                        <option value="Kolhapur-Gujarat&India">Kolhapur</option>
                        <option value="Kedarnath-Uttarakhand&India">Kedarnath</option>
                        <option value="Kedarnath-Uttarakhand&India">Kedarnath</option>
                        <option value="Kedarnath-Uttarakhand&India">Kedarnath</option>
    
                        <option value="Noida-UP&India">Noida</option>
                        
                        <option value="Surat-India">Surat</option>
                        <option value="Patan-Gujarat&India">Gujarat</option> -->
                            </select>
                            <!-- <div *ngIf='tellFormControls[tell.slug].errors' class="_require_filed">
                        <span
                          *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                          {{tell.error_message}}
                        </span>
                      </div> -->
                        </form>
                    </div>
                    <div class="col-md-12">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation"><a id="today_tab" class="nav-link active"
                                    data-toggle="tab" href="#today" role="tab">TODAY</a></li>

                            <li class="nav-item" role="presentation"><a id="tomarrow_tab" class="nav-link"
                                    data-toggle="tab" href="#tomarrow" role="tab">TOMORROW</a></li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="today" role="tabpanel"
                                aria-labelledby="today_tab">
                                <span class="panchang_date"
                                    *ngIf="today!=undefined && today.Date!=undefined">{{today.Date}}</span>
                                <div class="panchang_view">
                                    <ul>
                                        <li>
                                            <img src="../../../assets/img/panchang/sunrise.png" alt="Sunrise"
                                                title="Sunrise" />
                                            <label>Sunrise</label>
                                            <span
                                                *ngIf="today!=undefined && today.Sunrise!=undefined && tSunrise!='Invalid date'"><!--05:24 <small>AM</small>-->
                                                {{tSunrise}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/panchang/sunset.png" alt="Sunset"
                                                title="Sunset" />
                                            <label>Sunset</label>
                                            <span
                                                *ngIf="today!=undefined && today.Sunset!=undefined && tSunset!='Invalid date'"><!--05:24 <small>AM</small>-->{{tSunset}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/panchang/monnrise.png" alt="Monnrise"
                                                title="Monnrise" />
                                            <label>Moonrise</label>
                                            <span
                                                *ngIf="today!=undefined && today.Moonrise!=undefined && tMoonrise!='Invalid date'">{{tMoonrise}}<!--05:24 <small>AM</small>--></span>
                                            <div class="line"></div>
                                        </li>
                                    </ul>

                                    <div class="panchang_raashi">
                                        <ul>
                                            <li>Sun in : <label
                                                    *ngIf="today!=undefined && today.Sunin!=undefined">{{today.Sunin}}</label>
                                            </li>
                                            <li>Moon in : <label
                                                    *ngIf="today!=undefined && tmoonIn!=undefined && tmoonIn!='Invalid date'">{{tmoonIn}}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="panchang_view panchang_view_4  mt-5">
                                    <ul>
                                        <li>
                                            <label>Tithi</label>
                                            <span *ngIf="today!=undefined && today.Tithi!=undefined && tTithi !='Invalid date'">{{tTithi}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Nakshatra</label>
                                            <span
                                                *ngIf="today!=undefined && today.Nakshatra!=undefined  && tnakshatra!='Invalid date'">{{tnakshatra}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Paksha</label>
                                            <span
                                                *ngIf="today!=undefined && today.Saurmaasa!=undefined">{{today.Saurmaasa}}
                                                Paksha</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Yoga </label>
                                            <span *ngIf="today!=undefined && today.Yoga!=undefined && tYoga!='Invalid date'">{{tYoga}}</span>
                                            <div class="line"></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-3">
                                        <div class="panchang_view panchang_view_1 ">
                                            <ul>
                                                <li>
                                                    <label>Subh Samaya </label>
                                                    <span
                                                        *ngIf="today!=undefined && today.AbhijitMuhurtha!=undefined && tAbhijitMuhurtha!='Invalid date'">{{tAbhijitMuhurtha}}<!--11:43<small>AM</small> - 12:15<small>AM</small>--></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="panchang_view m_m50">
                                            <div class="bg_gray">Ashubh Samaya</div>
                                            <ul class="view_1">
                                                <li>
                                                    <label>Rahu Kaal </label>
                                                    <span
                                                        *ngIf="today!=undefined && today.Rahukalam!=undefined && tRahukalam!='Invalid date'">{{tRahukalam}}
                                                        <!--11:43<small>AM</small> - 12:15<small>AM</small>---></span>
                                                    <div class="line"></div>
                                                </li>
                                                <li>
                                                    <label>Yamghant </label>
                                                    <span
                                                        *ngIf="today!=undefined && today.Yamagandam!=undefined && tYamagandam!='Invalid date'">{{tYamagandam}}
                                                        <!--11:43<small>AM</small> - 12:15<small>AM</small>--></span>
                                                    <div class="line"></div>
                                                </li>
                                                <li>
                                                    <label>Gulikai </label>
                                                    <span
                                                        *ngIf="today!=undefined && today.Gulikai!=undefined && tGulikai!='Invalid date'">{{tGulikai}}
                                                        <!--11:43<small>AM</small> - 12:15<small>AM</small>--></span>
                                                    <div class="line"></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tomarrow" role="tabpanel" aria-labelledby="tomarrow_tab">
                                <span class="panchang_date"
                                    *ngIf="tomorrow!=undefined && tomorrow.Date!=undefined">{{tomorrow.Date}}</span>
                                <div class="panchang_view">
                                    <ul>
                                        <li>
                                            <img src="../../../assets/img/panchang/sunrise.png" alt="Sunrise"
                                                title="Sunrise" />
                                            <label>Sunrise</label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Sunrise!=undefined && tmSunrise!='Invalid date'">{{tmSunrise}}<!--05:24 <small>AM</small>--></span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/panchang/sunset.png" alt="Sunset"
                                                title="Sunset" />
                                            <label>Sunset</label>
                                            <span *ngIf="tomorrow!=undefined && tomorrow.Sunset!=undefined && tmSunset!='Invalid date'">{{tmSunset}}
                                                <!--05:24 <small>AM</small>--></span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <img src="../../../assets/img/panchang/monnrise.png" alt="Monnrise"
                                                title="Monnrise" />
                                            <label>Moonrise</label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Moonrise!=undefined && tmMoonrise!='Invalid date'">{{tmMoonrise}}<!--05:24 <small>AM</small>--></span>
                                            <div class="line"></div>
                                        </li>
                                    </ul>

                                    <div class="panchang_raashi">
                                        <ul>
                                            <li>Sun in : <label
                                                    *ngIf="tomorrow!=undefined && tomorrow.Sunin!=undefined">{{tomorrow.Sunin}}</label>
                                            </li>
                                            <li>Moon in : <label
                                                    *ngIf="tomorrow!=undefined && tmmoonIn!=undefined && tmmoonIn!='Invalid date'">{{tmmoonIn}}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="panchang_view panchang_view_4  mt-5">
                                    <ul>
                                        <li>
                                            <label>Tithi</label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Tithi!=undefined && tmTithi!='Invalid date'">{{tmTithi}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Nakshatra</label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Nakshatra!=undefined && tmnakshatra!='Invalid date'">{{tmnakshatra}}</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Paksha</label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Saurmaasa!=undefined">{{tomorrow.Saurmaasa}}
                                                Paksha</span>
                                            <div class="line"></div>
                                        </li>
                                        <li>
                                            <label>Yoga </label>
                                            <span
                                                *ngIf="tomorrow!=undefined && tomorrow.Yoga!=undefined && tmYoga!='Invalid date'">{{tmYoga}}</span>
                                            <div class="line"></div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-md-3">
                                        <div class="panchang_view panchang_view_1 ">
                                            <ul>
                                                <li>
                                                    <label>Subh Samaya</label>
                                                    <span
                                                        *ngIf="tomorrow!=undefined && tomorrow.AbhijitMuhurtha!=undefined && tmAbhijitMuhurtha!='Invalid date'">{{tmAbhijitMuhurtha}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="panchang_view m_m50">
                                            <div class="bg_gray">Ashubh Samaya</div>
                                            <ul class="view_1">
                                                <li>
                                                    <label>Rahu Kaal </label>
                                                    <span
                                                        *ngIf="tomorrow!=undefined && tomorrow.Rahukalam!=undefined && tmRahukalam!='Invalid date'">{{tmRahukalam}}</span>
                                                    <div class="line"></div>
                                                </li>
                                                <li>
                                                    <label>Yamghant </label>
                                                    <span
                                                        *ngIf="tomorrow!=undefined && tomorrow.Yamagandam!=undefined && tmYamagandam!='Invalid date'">{{tmYamagandam}}</span>
                                                    <div class="line"></div>
                                                </li>
                                                <li>
                                                    <label>Gulikai </label>
                                                    <span
                                                        *ngIf="tomorrow!=undefined && tomorrow.Gulikai!=undefined && tmGulikai!='Invalid date'">{{tmGulikai}}</span>
                                                    <div class="line"></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>