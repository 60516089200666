
            <div class="modal-header">
                <h4 class="modal-title">More Info</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="onClose()">&times;</button>
            </div>
            <div class="modal-body album_more_info_list" >
                <div class="row">
                   
                    <div class="col-md-6">
                        <label>Duration</label>
                        <span>{{data.hours }} : {{data.min}}: {{data.sec ? data.sec : 0}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.synopsis!=''">
                        <label>About</label>
                        <span>{{data.synopsis}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.content_rating && data.content_rating.viewer_rating!=''">
                        <label>Maturity Rating</label>
                        <span>{{data.content_rating.viewer_rating}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.content_rating && data.content_rating.content_advisory!='' && data.content_rating.content_advisory!='undefined' || data.advisory!=''">
                        <label>Content Advisory</label>
                        <span> {{ data.content_rating && data.content_rating.content_advisory!='undefined'  ? data.content_rating.content_advisory : data.advisory}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.genre_text!='' && data.genre_text!='undefined' || data.genre_name!=''">
                        <label>Genres</label>
                        <span> {{data.genre_text!='' && data.genre_text!='undefined' ? data.genre_text : data.genre_text}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.viewer_rating && data.viewer_rating.rating_name!=''">
                        <label>Maturity Rating</label>
                        <span>{{data.viewer_rating.rating_name}}</span>
                    </div>
                    <!-- <div class="col-md-6" *ngIf="data.advisory!=''">
                        <label>Content Advisory</label>
                        <span> {{data.advisory}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.genre_name!=''">
                        <label>Genres</label>
                        <span> {{data.genre_name}}</span>
                    </div> -->
                    <div class="col-md-6" *ngIf="data.languages!=''">
                        <label>Language</label>
                        <span>{{data.languages}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.performer!='' && data.performer!= undefined">
                        <label>Performer</label>
                        <span>{{data.performer ? data.performer : '-'}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.music_group!=''">
                        <label>Music Group</label>
                        <span>{{data.music_group ? data.music_group : '-'}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.music_director_name!=''">
                        <label>Music Director</label>
                        <span>{{data.music_director_name ? data.music_director_name : '-'}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="data.producer!='' || data.music_label!='' ">
                        <label>Producer / Music Label</label>
                        <span >{{data.producer ? data.producer : ''}} {{data.producer && data.music_label  ? '/' : (!data.producer ? '' : '')}}{{data.music_label ? data.music_label : ''}}</span>
                    </div>
                </div>
            </div>
  