<div class="maincontentarea">
    <section class="vouchers vouchers-faq">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h4><i class="voucher-ion-left fa fa-angle-left" (click)="landingPage(sessId)"  style="cursor: pointer; font-size: 30px;"></i> &nbsp; My Vouchers FAQ's</h4>
                    
                    <ul class="mt-4">
                        <li>
                            <label>Q1) How can I get Vouchers?</label>
                            <p>You can get the vouchers by signing up on Samatva, tapping the ‘More’ button at the bottom right of home page and visiting the My Vouchers page.</p>
                        </li>
                        <li>
                            <label>Q2) What type of items can I use my vouchers on?</label>
                            <p>You can refer to the Terms & Conditions of the respective vouchers. Vouchers are subjected to be availed on different products or services. </p>
                        </li>
                        <li>
                            <label>Q3) The voucher I selected is about to expire. Can I extend the validity period?</label>
                            <p>The validity period of voucher cannot be extended, you should use the vouchers before the expiry date.</p>
                        </li><br/>
                        <li>
                            <label>Q4) Can I exchange Vouchers for cash?</label>
                            <p>No. Vouchers cannot be exchanged for cash</p>
                        </li><br/>
                        <li>
                            <label>Q5) Can a voucher be redeemed more than once?</label>
                            <p>Please check the terms and conditions of the respective voucher to see how many times the voucher can be redeemed.</p>
                        </li><br/>
                        <li>
                            <label>Q6) Can I exchange voucher for a different voucher?</label>
                            <p>No, you cannot exchange the voucher for a different voucher.</p>
                        </li><br/>
                        <li>
                            <label>Q7) Whom can I contact if I am having trouble in availing Vouchers?</label>
                            <p>If you face trouble in redeeming a voucher, you should contact the respective brand.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>