<div class="maincontentarea">
    <section class="podcast">
        <div class="container">
            <div class="row">
                <div class="col-md-3 podcast_img">
                    <image-manipulation
                        [img]="isCloudFrontType == false ? podcast_details.image : podcast_details.image_square_cloudfront_url"
                        [params]="{'width':300,'height':300}" [notFound]="notFoundImg">
                    </image-manipulation>
                </div>
                <div class="col-md-9">
                    <div class="podcast_detail">
                        <h1>{{podcast_details.name}}</h1>
                        <span>{{podcast_details.highlighted_label}}</span>
                        <p>{{readmoreCond?podcast_details.synopsis: podcast_details.synopsis | slice:0:235}}
                            <a href="javascript:void(0);"
                                *ngIf="podcast_details.synopsis && podcast_details.synopsis.length > 235"
                                (click)="readMore()">{{readMoreText}} <img
                                    src="../../../assets/img/read_more_arrow.png" /></a>
                        </p>
                        <div class="podcast_play">
                            <a *ngIf="(is_pause && !is_preview && isAudioExist) || (is_pause && is_preview && !isAudioExist)" href="javascript:void(0);"
                                (click)="playAudioRecomnd(podcast_details.recently_watched, audioContent,false,true)">
                                <img src="../../../assets/img/audio_play.png" alt="" title="" />
                                PLAY
                            </a>
                            <a *ngIf="!is_pause && !is_preview && isAudioExist" href="javascript:void(0);"
                                (click)="pause()">
                                <img src="../../../assets/img/audio_pause.png" alt="" title="" />
                                PAUSE
                            </a>
                        </div>
                        <div class="podcast_option">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" (click)="openShareModal(podcast_details.share_url)">
                                        <img src="../../../assets/img/share.png" alt="" title="" />
                                        <span>Share</span>
                                    </a>
                                </li>
                                <li>
                                    <a data-toggle="modal" data-target="#podcat_more_info" href="javascript:void(0);">
                                        <img src="../../../assets/img/more_info.png" alt="" title="" />
                                        <span>More info </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="assests-detail">
                <div class="row">
                    <div class="col-md-12">


                        <ul id="myTab" role="tablist" class="nav nav-tabs">
                            <li class="nav-item"><a id="episode-tab" data-toggle="tab" href="#episode_nav" role="tab"
                                    aria-controls="episode" aria-selected="true"
                                    class="nav-link active show">Episodes</a></li>
                            <li class="nav-item"><a id="similar-tab" data-toggle="tab" href="#similar_nav" role="tab"
                                    aria-controls="similar" aria-selected="false" class="nav-link">Similar</a></li>
                        </ul>
                        <div id="myTabContent" class="tab-content assests-content">

                            <div id="episode_nav" role="tabpanel" aria-labelledby="episode-tab"
                                class="tab-pane fade show active">
                                <div class="dropdown season-drpdwn"
                                    *ngIf="podcast_details && podcast_details.season && podcast_details.season.length  > 1">

                                    <button class="btn btn-secondary dropdown-toggle season-button" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        {{currentSeaon?currentSeaon:'Season 1'}} <i class="fa fa-angle-down"
                                            aria-hidden="true"></i>
                                    </button>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item"
                                            *ngFor="let season of seasonData.result.season; let i=index"
                                            (click)="changeSeason(season.season_id, i+1, season.season_number)">Season
                                            {{i+1}}
                                        </a>
                                    </div>

                                </div>
                                <ul class="podcast_list"
                                    *ngIf="allEpisode && allEpisode.result[0] && allEpisode.result[0].assets.length > 0">
                                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="5"
                                        [infiniteScrollThrottle]="25" (scrolled)="onScroll()" *ngIf="allEpisode">
                                        <li class="podcast_track_play"
                                            *ngFor="let item of allEpisode.result[0].assets; let i=index">
                                            <div class="podcast_align">
                                                <div class="podcast_epi_play">

                                                    <div *ngIf="item.hide_tag!=1">
                                                        <img *ngIf="item.content_availability.toLowerCase() == 'free'"
                                                            class="album_free_icon"
                                                            src="./assets/img/free_tag_76x35.png" alt="" title="" />
                                                        <img *ngIf="item.content_availability.toLowerCase() == 'paid'"
                                                            class="album_paid_icon" src="./assets/img/crown.png" alt=""
                                                            title="" />
                                                    </div>


                                                    <image-manipulation
                                                        [img]="isCloudFrontType == false ? item.image_square : item.image_square_cloudfront_url"
                                                        [params]="{'width':70,'height':70}" [notFound]="notFoundImg">
                                                    </image-manipulation>

                                                    <div class="podcast_overlay"
                                                        *ngIf="item.code == currentPlayCode && isAudioExist && !is_pause && !is_preview">
                                                        <div class="podcast_play_animation">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    </div>

                                                    <div class="podcast_overlay"
                                                        *ngIf="item.code == currentPlayCode && isAudioExist && !is_preview">
                                                        <div  [ngClass]="(item.code == currentPlayCode) && isAudioExist && !is_preview && is_pause ? 'podcast_play_animation padcast_play_animation_push' : 'podcast_play_animation'">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="(item.seek_bar_data.stop*100/item.seek_bar_data.duration) >= 98"
                                                        class="shade" style="border-radius: 6px;"></div>
                                                </div>
                                                <div [ngClass]="item.code == currentPlayCode && isAudioExist && !is_preview ? 'podcast_epi podcast_epi_active': 'podcast_epi'"
                                                    *ngIf="item.no">
                                                    <label>{{item.no}} . {{item.name}}</label>
                                                    <span>{{item.highlighted_label}}</span>
                                                </div>


                                                <div class="podcast_preview_play"
                                                    *ngIf="item.code==currentPlayCode && is_preview && !isAudioExist"
                                                    (click)="stopPreview()">
                                                    <ul class="preview_animate">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </div>


                                                <a class="podcast_preview_option dropdown-toggle"
                                                    href="javascript:void(0);" id="option_dropdown"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-ellipsis-v inner"></i>
                                                </a>

                                                <div class="dropdown-menu podcast_preview_option_position"
                                                    aria-labelledby="option_dropdown">
                                                    <ul>
                                                        <li *ngIf="item.code==currentPlayCode && is_preview && !isAudioExist">
                                                            <a href="javascript:void(0);">
                                                            <div class="podcast_preview_play poadcast_p_w">
                                                                <ul class="preview_animate">
                                                                    <li></li>
                                                                    <li></li>
                                                                    <li></li>
                                                                    <li></li>
                                                                    <li></li>
                                                                </ul>
                                                            </div>
                                                            Preview</a>
                                                        </li>
                                                        <li *ngIf="((item.is_display_preview == 'yes') && ((item.code!=currentPlayCode && is_preview && !isAudioExist) || (item.code!=currentPlayCode && !is_preview && isAudioExist) || (item.code==currentPlayCode && !is_preview && isAudioExist)))"><a
                                                                href="javascript:void(0);" class="preview_link"
                                                                (click)="playAudioRecomnd(item, audioContent, true,false)"><img
                                                                    src="../../../assets/img/preview_icon.png" alt=""
                                                                    title="" />Preview</a>
                                                                
                                                                </li>
                                                        <li><a href="javascript:void(0);"
                                                                (click)="openShareModal(item.share_url)"><img
                                                                    src="../../../assets/img/share.png" alt=""
                                                                    title="" />Share</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p class="synopsisHight" (click)="expendEpisodeSynopsis(i)"
                                                [ngClass]="{'episodeSynopsisHight': episodeIndex == i}">
                                                {{item.synopsis}}</p>
                                            <div class="podcast_progress">
                                                <!-- <p style="color: red">  {{"checkIsPuse :" + checkIsPuse + ", currentPlayCode: " + currentPlayCode + ", is_pause : " + is_pause + ", is_preview : " + is_preview + ", isAudioExist : "+ isAudioExist}}</p> -->

                                                <span class="podcast-icon-play">

                                                    <!-- <a href="javascript:void(0);"

                                                        (click)="playAudioRecomnd(item, audioContent)" *ngIf="
                                                        (checkIsPuse && (item.code != currentPlayCode && !is_pause && !is_preview && !isAudioExist)) 

                                                        || (!checkIsPuse && (item.code != currentPlayCode && is_pause && is_preview && !isAudioExist))

                                                        || (!checkIsPuse && (item.code == currentPlayCode && is_pause && is_preview && !isAudioExist))

                                                        || (!checkIsPuse && (item.code != currentPlayCode && !is_pause && !is_preview && isAudioExist))

                                                        || (!checkIsPuse && (item.code != currentPlayCode && is_pause && !is_preview && isAudioExist))
                                                        "><img src="../../../assets/img/podcast_play.png" alt=""
                                                            title="" /></a> -->

                                                    <!-- <a href="javascript:void(0);" (click)="play()"
                                                        *ngIf="!checkIsPuse && (item.code == currentPlayCode && is_pause && !is_preview && isAudioExist)"><img
                                                            src="../../../assets/img/podcast_play.png" alt=""
                                                            title="" /></a> -->


                                                    <!-- <a href="javascript:void(0);"
                                                        (click)="(item.code == currentPlayCode && isAudioExist && !is_pause && !is_preview && !checkIsPuse) ? pause() : 
                                                        (!checkIsPuse && (item.code == currentPlayCode && is_pause && !is_preview && isAudioExist)) ? play() : playAudioRecomnd(item, audioContent)" 
                                                        
                                                        *ngIf="
                                                        (checkIsPuse && (item.code != currentPlayCode && !is_pause && !is_preview && !isAudioExist)) 

                                                        || (!checkIsPuse && (item.code != currentPlayCode && is_pause && is_preview && !isAudioExist))

                                                        || (!checkIsPuse && (item.code == currentPlayCode && is_pause && is_preview && !isAudioExist))

                                                        || (!checkIsPuse && (item.code != currentPlayCode && !is_pause && !is_preview && isAudioExist))

                                                        || (!checkIsPuse && (item.code != currentPlayCode && is_pause && !is_preview && isAudioExist)) 
                                                        
                                                        || (!checkIsPuse && (item.code == currentPlayCode && is_pause && !is_preview && isAudioExist
                                                        
                                                        || item.code == currentPlayCode && isAudioExist && !is_pause && !is_preview && !checkIsPuse))
                                                        "><img [src]="(item.code == currentPlayCode && isAudioExist && !is_pause && !is_preview && !checkIsPuse) ? '../../../assets/img/podcast_pause.png' : 
                                                        '../../../assets/img/podcast_play.png" alt=""
                                                            title="" /></a> -->

                                                    <!-- <a *ngIf="item.code == currentPlayCode && isAudioExist && !is_pause && !is_preview && !checkIsPuse"
                                                        href="javascript:void(0);" (click)="pause()">
                                                        <img src="../../../assets/img/podcast_pause.png" alt=""
                                                            title="" />
                                                    </a> -->
                                                    <!-- <div class="shade" style="border-radius: 6px;"></div> -->



                                                    <a href="javascript:void(0);" (click)="playAudioRecomnd(item, audioContent,false,false)">
                                                        <img src="../../../assets/img/podcast_play.png"
                                                            title="" /></a>
                                                </span>
                                                <!-- <span style="color: red">{{(item.seek_bar_data.stop*100/item.seek_bar_data.duration) + "second" + (item.seek_bar_data.stop*100/item.seek_bar_data.duration+2)}}</span> -->
                                                <div class="podcast_progress_bar">
                                                    <span *ngIf="item.hours!='' && item.min!='' && item.sec!=''"
                                                        [ngStyle]="{'width': (item.seek_bar_data.stop*100/item.seek_bar_data.duration) >= 99 ? (item.seek_bar_data.stop*100/item.seek_bar_data.duration+2) +'%' : (item.seek_bar_data.stop*100/item.seek_bar_data.duration)  +'%'}"></span>
                                                    <span *ngIf="item.hours=='' && item.min=='' && item.sec==''"
                                                        [ngStyle]="{'width': '0%'}"></span>
                                                </div>


                                                <div class="time">


                                                    <p
                                                        *ngIf="(item.seek_bar_data.stop*100/item.seek_bar_data.duration) >= 99 || item.seek_bar_data.stop==0">
                                                        {{item.hours + ":" + item.min + ":" + item.sec}}
                                                    </p>
                                                    <p *ngIf="(item.seek_bar_data.stop*100/item.seek_bar_data.duration)!=0 && 
                           (item.seek_bar_data.stop*100/item.seek_bar_data.duration) < 99">
                                                        <!-- <span style="color:red">{{(item.seek_bar_data.duration)-(item.seek_bar_data.stop)}}</span> -->
                                                        {{getDuration((item.seek_bar_data.duration)-(item.seek_bar_data.stop))}}

                                                        Left
                                                    </p>

                                                </div>

                                            </div>
                                        </li>
                                    </div>
                                </ul>
                                <!-- <div *ngIf="allEpisode && allEpisode.result.length < 0" style="color: red;">Not Any Episode This Season</div> -->
                            </div>
                            <div id="similar_nav" role="tabpanel" aria-labelledby="similar-tab" class="tab-pane fade">
                                <div class="other_assests ">
                                    <div class="row m5">
                                        <div class="album_similar view_more" style="width: 100%;">
                                            <div class="_v_all_parent"
                                                *ngIf="similerDetails && similerDetails.length>0">
                                                <div class="asset_5 p15"
                                                    *ngFor="let item of similerDetails; let i=index">
                                                    <div class="_view_img_list_p" (click)="detailPageRedirect(item)">
                                                        <div class="_watching_img1">
                                                            <div class="p_g_i similar_asset_w">

                                                                <image-manipulation
                                                                    [img]="isCloudFrontType == false ? item.image_square : item.image_square_cloudfront_url"
                                                                    [params]="{'width':300,'height':300}"
                                                                    [notFound]="notFoundImg">
                                                                </image-manipulation>
                                                                <div *ngIf="item.hide_tag!=1">
                                                                    <a *ngIf="item.content_availability.toLowerCase() == 'free'"
                                                                        class="_free_tag"><img
                                                                            src="assets/img/free_tag_76x35.png"></a>
                                                                    <a *ngIf="item.content_availability.toLowerCase() == 'paid'"
                                                                        class="_free_tag _paid_tag"><img
                                                                            src="assets/img/crown.png"></a>
                                                                </div>

                                                            </div>
                                                            <div class="_visible_hover1">
                                                                <div class="_v_c_l">
                                                                    <a href="javascript:;">
                                                                        <div class="_c_img"><img
                                                                                src="assets/img/hover_Play.png"></div>
                                                                        <div class="_c_progress"><span>Play now</span>
                                                                        </div>
                                                                    </a>
                                                                    <div class="_right_c_t"><span
                                                                            class="rating_s">{{item.viewer_rating.rating_name}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="_inner_text_l">
                                                                    <h4>{{item.name}}</h4>
                                                                    <h4>{{item.highlighted_label}}</h4>
                                                                    <p>{{item.synopsis}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</div>



<div class="modal auth" id="podcat_more_info">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">More Info</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body podcast_info_list">
                <div class="row">
                    <!-- <div class="col-md-6">
<label>Tracks</label>
<span>{{podcast_details.name}}</span>
</div> -->
                    <!-- <div class="col-md-6">
<label>Total Duration</label>
<span>5 Min</span>
</div> -->
                    <div class="col-md-6" *ngIf="podcast_details.highlighted_label!=''">
                        <label>About</label>
                        <span>{{podcast_details.highlighted_label}}surajjjjjjj</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.viewer_rating.rating_name!=''">
                        <label>Maturity Rating</label>
                        <span>{{podcast_details.viewer_rating.rating_name}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.advisory!=''">
                        <label>Content Advisory</label>
                        <span>{{podcast_details.advisory}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.genre_text!=''">
                        <label>Genres</label>
                        <span>{{podcast_details.genre_text}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.languages!=''">
                        <label>Language</label>
                        <span>{{podcast_details.languages}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.music_group!=''">
                        <label>Music Group</label>
                        <span>{{podcast_details.music_group}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.music_director!=''">
                        <label>Music Director</label>
                        <span>{{podcast_details.music_director}}</span>
                    </div>
                    <div class="col-md-6" *ngIf="podcast_details.producer_name!=''">
                        <label>Producer / Music Label</label>
                        <span>{{podcast_details.producer ? podcast_details.producer : ''}}{{podcast_details.producer && podcast_details.music_label  ? '/' : (!podcast_details.producer ? '' : ' ')}}{{podcast_details.music_label ? podcast_details.music_label : ''}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #audioContent let-modal>
    <app-audio-player [streamInfo]="playEpisodeData" (checkTrackActivity)="checkTrackActivity($event)"
        (openErrorMsg)="openErrorMsg($event)" (onModalClose)="onModalClosed($event)"></app-audio-player>
</ng-template>

<a data-toggle="modal" data-target="#showError"  #showErrorMsgModal></a>
<div class="modal auth" id="showError" data-backdrop="static">
    <div class="modal-dialog">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" #closeShowErrorMsg (click)="removeErrorBox()" class="close"
                    data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/error_icon.png" />
                    <p>{{paidPackMsg}}</p>
                </div>

                <div class="action-btn">
                    <div>
                        <button type="button" class="btn btn-primary yes" (click)="removeErrorBox()">OK</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>