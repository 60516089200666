<button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item" id="openLogin"
    (click)="openLoginModal(content)" #openLoginllModel>Open Login Modal</button>

<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>
<p class="_custom_progress">
    <ngb-progressbar *ngIf="progrssBarValue > 0" class="_custom_progress horoscope_progress" type="info" id="nexg_ngb-progressbar"
       [value]="progrssBarValue">
    </ngb-progressbar>
    
 </p>
<div class="maincontentarea _beforeLoad" id="maincontentarea">
    <section class="horoscope">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h4>Daily Horoscope</h4>
                    <div class="horoscope-info">
                        <label>Please Select Your Zodiac Sign</label>
                        <a data-toggle="modal" data-backdrop="static" data-target="#HoroscopeModal"
                            href="javascript:void(0);" *ngIf="horoScopeData!=undefined">{{(horoscpeName.substring(5)).charAt(0).toUpperCase() + (horoscpeName.substring(5)).slice(1)}} <i class="fa fa-caret-down"></i></a>
                        <label *ngIf="horoScopeData!=undefined">{{horoScopeData.pubDate}}</label>
                    </div>

                    <div class="horoscope-detail">
                        <p *ngIf="horoScopeData!=undefined">{{horoScopeData.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<div id="HoroscopeModal" class="modal fade" role="dialog">
    <div class="modal-dialog horoscop-container">
        <div class="auth">
            <div class="modal-content">
                <div class="horoscopebg">
                    <div class="modal-header">
                        <h4 class="modal-title">Select Your Zodiac Sign</h4>
                        <button type="button" data-dismiss="modal" class="close d-btn-hide">x</button>
                    </div>
                    <div class="modal-body mt-3">
                        <div class="row horoscope-align">
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiAries')">
                                <div class="horoscope-sign" [class]="horoName=='aries' ? 'horoscope-active' : '' ">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/aries.png"
                                            alt="Aries" title="Aries" /></span>
                                    <span class="horoscope-label">Aries</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiTaurus')">
                                <div class="horoscope-sign" [class]="horoName=='taurus' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/taurus.png"
                                            alt="Taurus" title="Taurus" /></span>
                                    <span class="horoscope-label">Taurus</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiGemini')">
                                <div class="horoscope-sign" [class]="horoName=='gemini' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/gemini.png"
                                            alt="Gemini" title="Gemini" /></span>
                                    <span class="horoscope-label">Gemini</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiCancer')">
                                <div class="horoscope-sign" [class]="horoName=='cancer' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/cancer.png"
                                            alt="Cancer" title="Cancer" /></span>
                                    <span class="horoscope-label">Cancer</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiLeo')">
                                <div class="horoscope-sign" [class]="horoName=='leo' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/leo.png"
                                            alt="Leo" title="Leo" /></span>
                                    <span class="horoscope-label">Leo</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiVirgo')">
                                <div class="horoscope-sign" [class]="horoName=='virgo' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/virgo.png"
                                            alt="Virgo" title="Virgo" /></span>
                                    <span class="horoscope-label">Virgo</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiLibra')">
                                <div class="horoscope-sign" [class]="horoName=='libra' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/libra.png"
                                            alt="Libra" title="Libra" /></span>
                                    <span class="horoscope-label">Libra</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiScorpio')">
                                <div class="horoscope-sign" [class]="horoName=='scorpio' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img
                                            src="../../../assets/img/horoscopeicon/scorpio.png" alt="Scorpio"
                                            title="Scorpio" /></span>
                                    <span class="horoscope-label">Scorpio</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiSagittarius')">
                                <div class="horoscope-sign" [class]="horoName=='sagittarius' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img
                                            src="../../../assets/img/horoscopeicon/sagittarius.png" alt="Sagittarius"
                                            title="Sagittarius" /></span>
                                    <span class="horoscope-label">Sagittarius</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiCapricorn')">
                                <div class="horoscope-sign" [class]="horoName=='capricorn' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img
                                            src="../../../assets/img/horoscopeicon/capricorn.png" alt="Capricorn"
                                            title="Capricorn" /></span>
                                    <span class="horoscope-label">Capricorn</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiAquarius')">
                                <div class="horoscope-sign" [class]="horoName=='aquarius' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img
                                            src="../../../assets/img/horoscopeicon/aquarius.png" alt="Aquarius"
                                            title="Aquarius" /></span>
                                    <span class="horoscope-label">Aquarius</span>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-6 horoscope-pad-5" (click)="selectHoroscope('RashiPisces')">
                                <div class="horoscope-sign" [class]="horoName=='pisces' ? 'horoscope-active' : ''">
                                    <span class="horoscope-icon"><img src="../../../assets/img/horoscopeicon/pisces.png"
                                            alt="Pisces" title="Pisces" /></span>
                                    <span class="horoscope-label">Pisces</span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-right">
                                <a href="javascript:void(0);" data-dismiss="modal"
                                    class="all-btn mr-2 mbl-btn-none">CANCEL</a>
                                <a href="javascript:void(0);" class="all-btn mbl-block-btn" (click)="getHoroscope('Rashi'+horoName)">CONTINUE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>