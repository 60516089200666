import { LocationStrategy } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { UserService, DeviceService } from 'src/app/core';
import { VideoPlayerService } from 'src/app/core/services/videoPlayerService';
import { jwPlayerService } from 'src/app/views/jw-video-player/jwplayer.servies';
import { Analyticsstreaminglog, CatlougeTypeConstants } from "src/app/core/constants/index";
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from "@angular/common/http";


declare var videojs: any;
declare var $: any;
declare var google;

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal]
})

export class LiveComponent implements OnInit {

  @Input() streamInfo: any;
  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: string;
  title: string = '';
  videoPlayUrl: any = '';

  userUnique: string = 'NA';
  currentPlayingCode: string = '';
  isPlayed: number = 0;

  // for uuid
  data = "Example1";//Message to Encrypt
  iv = CryptoJS.enc.Base64.parse("");//giving empty initialization vector
  key = CryptoJS.SHA256("Message");//hashing the key using SHA256
  encrypted_UUID: any;
  deviceInfo = null;
  interval_ID: any;
  ipAddress: any;
  errmsg: any;

  // log object
  currentPlayingCharegCode: string = '';
  currentPlayingMongoId: string = '';
  currentPlayingContentAvailability: string = '';
  currentPlayingThumb: string = '';
  currentPlayingSec: string = '';
  currentPlayingMin: string = '';
  currentPlayingHour: string = '';
  currentPlayingDuration: string = '';
  currentPlayingGenreText: string = '';
  currentPlayingGenre: string = '';
  currentPlayingLanguages: string = '';
  currentPlayingSys: string = '';
  currentPlayingShowID: string = '';
  currentPlayingSeasonId: string = '';
  currentPlayingName: string = '';
  @Output() onModalClose = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationStrategy,
    public VideoPlayerService: VideoPlayerService,
    public UserService: UserService,
    public jwPlayerService: jwPlayerService,
    public spinner: SpinnerVisibilityService,
    public router: Router,
    private deviceApiService: DeviceService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.mobileBrowserBackbutton();

    });
    window.stop();
  }

  mobileBrowserBackbutton() {
    this.sendAnalyticsVideoEndLog();

    this.disableModal();
    if (this.playermodal)
    clearInterval(this.interval_ID);
      this.playermodal.dispose();

    this.playermodal = '';
    return false;
  }

  ngOnInit(): void {


    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.sessionId = localStorage.getItem('sessionId');

    if (Object.keys(this.userInfo.result).length > 0) {
      if (this.userInfo.result.register_by == 'mobile') {
        this.userUnique = this.userInfo.result.mobile;
      } else {
        this.userUnique = this.userInfo.result.emailaddress;
      }
    }
    // this.streamInfo = this.streamInfo;
    // try {
    //   this.currentPlayingCode = this.streamInfo.code;

    // } catch (err) {

    // }


    this.streamInfo = this.streamInfo;
    console.log(this.streamInfo);
    // this.currentPlayingCode = this.streamInfo.recently_watched.code;
    // if(this.streamInfo['seek_bar_data'] == undefined){
    //   this.streamInfo['seek_bar_data'] = {'code': ''};
    //this.streamInfo['seek_bar_data'].code = this.streamInfo.recently_watched.code;
    // }



    console.log(this.streamInfo);

    try {
      this.currentPlayingCode = this.streamInfo.code;//== undefined ? this.streamInfo.seek_bar_data.code : this.streamInfo.recently_watched.code;
      this.currentPlayingMongoId = this.streamInfo.asset_mongo_id;//== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingName = this.streamInfo.name;//== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingCharegCode = this.streamInfo.charge_code;//== undefined ? this.streamInfo.charge_code : this.streamInfo.recently_watched.charge_code;
      this.currentPlayingContentAvailability = this.streamInfo.content_availability;//== undefined ? this.streamInfo.content_availability : this.streamInfo.recently_watched.content_availability;
      this.currentPlayingThumb = this.streamInfo.thumbnail;// == undefined ? this.streamInfo.thumbnail : this.streamInfo.recently_watched.thumbnail;
      this.currentPlayingSec = this.streamInfo.sec;// == undefined ? this.streamInfo.recently_watched.sec == undefined ? this.streamInfo.sec : this.streamInfo.recently_watched.sec : this.streamInfo.recently_watched.asset.sec;

      this.currentPlayingMin = this.streamInfo.min;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.min == undefined ? this.streamInfo.min : this.streamInfo.recently_watched.min : this.streamInfo.recently_watched.asset.min;
      this.currentPlayingHour = this.streamInfo.hours;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingDuration = this.streamInfo.duration;// this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingGenreText = this.streamInfo.genre_text;//== undefined ? this.streamInfo.genre_text : this.streamInfo.recently_watched.genre_text;
      this.currentPlayingGenre = Object.keys(this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0] : '';// this.streamInfo.recently_watched.genre == undefined ? Object.keys( this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0]: '' : this.streamInfo.recently_watched.genre[0] ;
      this.currentPlayingLanguages = this.streamInfo.languages;//== undefined ? this.streamInfo.languages : this.streamInfo.recently_watched.languages;
      this.currentPlayingSys = this.streamInfo.synopsis;//== undefined ? this.streamInfo.synopsis : this.streamInfo.recently_watched.synopsis;
      this.currentPlayingShowID = this.streamInfo.show_id;// == undefined ? this.streamInfo.show_id : this.streamInfo.recently_watched.show_id;
      this.currentPlayingSeasonId = this.streamInfo.season_id;//== undefined ? this.streamInfo.season_id : this.streamInfo.recently_watched.season_id;

    } catch (e) {
      console.log(e)
    }


    let is_check_device = JSON.parse(localStorage.getItem('is_check_device'));
    console.log('is_check_device', is_check_device);

    this.getDeviceInfo();
    if (is_check_device) {
      if (this.streamInfo.plan != undefined && this.streamInfo.plan != '' && Object.keys(this.streamInfo.plan).length > 0) {
        this.getIPAddress();
        this.interval_ID = setInterval(() => {
          this.getIPAddress();
        }, this.streamInfo.plan.api_duration);
      }
    }

    this.playVideo();
    this.sendAnalyticsVideoEndLog();

  }


  // for uuid functions
  getDeviceInfo() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  encryptData(data, iv, key) {

    if (typeof data == "string") {
      data = data.slice();
      this.encrypted_UUID = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    else {
      this.encrypted_UUID = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    return this.encrypted_UUID.toString();
  }
  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      // this.plainText = this.sessionId+ '-'+ this.ipAddress;

      this.data = this.sessionId + '-' + this.ipAddress + '-' + this.deviceInfo.os_version;
      console.log('ipAddress', this.ipAddress);
      console.log('sessionId', this.sessionId);
      console.log('this.data', this.data);

      this.encrypted_UUID = this.encryptData(this.data, this.iv, this.key);
      console.log(this.encrypted_UUID);//genrated encryption String:  swBX2r1Av2tKpdN7CYisMg==

      var decrypteddata = this.decryptData(this.encrypted_UUID, this.iv, this.key);
      console.log(decrypteddata);//genrated decryption string:  Example1
      this.callDeviceApi();
    });
  }




  callDeviceApi() {
    let alloweddevice = this.streamInfo.plan.allowed_device;
    let allowedOs = this.streamInfo.plan.allowed_os;
    let obj: any = {
      catalogue: CatlougeTypeConstants.catlogue,
      session_id: this.sessionId,
      code: this.currentPlayingCode,
      device_id: this.encrypted_UUID,
      device_type: 'computer',
      date: Date.now(),
      plan: {
        allowed_device: alloweddevice,
        allowed_os: allowedOs
      }
    }

    let urlEncodedBodyData = this.UserService.convertoJSONToUrlEncoded(obj)
    urlEncodedBodyData = JSON.stringify(obj);
    console.log(urlEncodedBodyData);
    console.log(obj);

    let allowsArr = this.streamInfo.plan.allowed_os;
    if (allowsArr != "" && allowsArr != undefined && allowsArr.length > 0) {
      if (allowsArr.includes('computer')) {
        this.deviceApiService.checkAllowedDevices('device', urlEncodedBodyData).subscribe(
          (res) => {
            console.log('device api called');
            this.errmsg = res.msg
          },
          (err) => {
            console.log(err.status);
            if (err.status == 400) {
              clearInterval(this.interval_ID);
              this.disableModal();
              if (this.playermodal) this.playermodal.dispose();
              this.onModalClose.emit(err.error.msg);
            }

          })

      } else {
        clearInterval(this.interval_ID);
        this.disableModal();
        if (this.playermodal) this.playermodal.dispose();
        this.onModalClose.emit("This device is not allowed for streaming in your existing plan. Kindly upgrade your plan in case you wish to watch on this device.");
      }
    }

  }

  playVideo() {
    let videoStreamObj = {
      "nmode": "WIFI",
      "pushId": '',
      "ct": "1",
      "build": 10000,
      "charge_code": this.currentPlayingCode,
      "appv": "1.0.00",
      "push_date": "",
      "air": "airip",
      "plateform" : "web"
    };

    let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);

    this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe(playObj => {

      if (playObj.result == undefined || playObj.result == '') {

        if (playObj.error_string) {
          this.router.navigate(['subscribe/choseplan'], { queryParams: { 'code': this.currentPlayingCode } });
        }
        this.disableModal();
        return false;
      }

      if (
        playObj.cloud_front &&
        Object.keys(playObj.cloud_front).length > 0 &&
        playObj.cloud_front.cloud_url != "" &&
        playObj.cloud_front.cloudfront_key_pair_id != "" &&
        playObj.cloud_front.cloudfront_policy != "" &&
        playObj.cloud_front.cloudfront_signature != ""
      ) {
        this.videoPlayUrl = playObj.cloud_front.cloud_url;
        this.setCookie(
          "CloudFront-Key-Pair-Id",
          playObj.cloud_front.cloudfront_key_pair_id.trim(),
          1
        );
        this.setCookie(
          "CloudFront-Policy",
          playObj.cloud_front.cloudfront_policy.trim(),
          1
        );
        this.setCookie(
          "CloudFront-Signature",
          playObj.cloud_front.cloudfront_signature.trim(),
          1
        );
      } else {
        this.videoPlayUrl = playObj.result;
      }

      //'https://digilive.nexg.tv/live/salvationtv.smil/playlist.m3u8?sf=net';//
      //console.log(this.videoPlayUrl);
      if (playObj.assetData.name != undefined)
        this.title = playObj.assetData.name;
      else
        this.title = playObj.assetData.channel_name;

      let advertisement_url = playObj.assetData.advertisement_url;

      // let new_source = {
      //   sources: [
      //     { src: this.videoPlayUrl, type: 'application/x-mpegURL' }
      //   ],
      //   "controls": true,
      //   autoplay: true
      // };
      let new_source = {
        sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
        controls: true,
        autoplay: true,
        withCredentials: true,
        crossDomain: true,
      };

      this.playermodal = videojs('player_one', new_source);
      videojs.options.hls.overrideNative = true;
      videojs.options.html5.nativeAudioTracks = false;
      videojs.options.html5.nativeTextTracks = false;

      let imaOptions = {
        adTagUrl: advertisement_url,
        disableAdControls: true,
        id: 'videoAds',
      };

      this.playermodal.ima(imaOptions);
      google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);

      var startEvent = 'click';
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        startEvent = 'touchend';
      }
      let _this = this;
      this.playermodal.one(startEvent, function () {
        _this.playermodal.ima.initializeAdDisplayContainer();
      });

      this.playermodal.on('adend', function () {
        console.log('playermodal adend');
        // 3. Re-enable volume, restart video from beginning
        _this.playermodal.play();

      });
      this.playermodal.on('play', (event) => {
        if (this.isPlayed > 0)
          this.playVideo();
        this.sendAnalyticsVideoEndLog();

        this.isPlayed = 1;
      });
      this.playermodal.on('pause', (event) => {
        this.sendAnalyticsVideoEndLog();
      });

      this.playermodal.poster(playObj.assetData.image);

      this.playermodal.nuevo({
        videoInfo: true,
        infoTitle: this.title,
        infoIcon: "",
        liveTimeout: 10,
        qualityMenu: true,
        pipButton: false,
        // rateMenu:false,
        settingsButton: true,
        // zoomMenu: false,
        buttonRewind: false,
        buttonForward: false,
        poster: playObj.assetData.image,
        errordisplay: false,
        shareMenu: false,
        contextMenu: false
      });


      $('.player_one-dimensions.vjs-fluid').css('height', `${window.innerHeight}px`);

      $(window).bind('orientationchange', function (event) {
        let height = window.innerHeight;
        $('.player_one-dimensions.vjs-fluid').css('height', `${height}px`);
      });

      $(window).resize(function () {
        let height = window.innerHeight;
        $('.player_one-dimensions.vjs-fluid').css('height', `${height}px`);
      });

      this.playermodal.on('nuevoReady', (event) => {
        event.preventDefault();


        $('.vjs-text').append('<i class="fa fa-arrow-left vjs-ttl_btn" id="vjs-ttl_btn"></i>');

        let buttonfwd = document.getElementById("vjs-ttl_btn");

        buttonfwd.ontouchstart = (event) => {

          this.sendAnalyticsVideoEndLog();
          event.stopPropagation();

          event.preventDefault();

          this.disableModal();
          if (this.playermodal)
          clearInterval(this.interval_ID);
            this.playermodal.dispose();

          this.playermodal = '';

          return false;
        };

        buttonfwd.onclick = (event) => {
          this.sendAnalyticsVideoEndLog();
          event.stopPropagation();

          event.preventDefault();

          this.disableModal();
          if (this.playermodal)
          clearInterval(this.interval_ID);
            this.playermodal.dispose();

          //$('body').removeClass('vjs-full-window');
          this.playermodal = '';

          return false;
        }


        let self = this;
        this.playermodal.on('timeupdate', function () {

        });


      });
    });
  }

  sendAnalyticsVideoEndLog() {

    let playLogReqdata = Analyticsstreaminglog.logData;
    playLogReqdata.platform = "web";
    playLogReqdata.code = this.currentPlayingCode;
    playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
    playLogReqdata.play_url = this.videoPlayUrl;
    playLogReqdata.asset_code = this.currentPlayingCode;
    playLogReqdata.category = this.currentPlayingCode;

    // playLogReqdata['show_id'] = this.streamInfo.recently_watched.show_id;
    // playLogReqdata['season_id'] = this.streamInfo.recently_watched.season_id;
    playLogReqdata.stop = Math.round(this.playermodal.currentTime());

    playLogReqdata['duration'] = Math.round(this.playermodal.duration());
    let Playerduration = Math.round(this.playermodal.duration());

    console.log(Playerduration);
    playLogReqdata.charge_code = this.currentPlayingCharegCode;
    playLogReqdata['last_video_play_id'] = this.currentPlayingMongoId;//this.streamInfo.asset_mongo_id;
    playLogReqdata['release_date'] = this.streamInfo.release_date ? this.streamInfo.release_date : '';
    playLogReqdata['name'] = this.currentPlayingName;//this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
    playLogReqdata['cloud_image'] = this.currentPlayingThumb;//this.streamInfo.thumbnail;
    playLogReqdata['content_availability'] = this.currentPlayingContentAvailability;//this.streamInfo.content_availability;
    playLogReqdata['asset_mongo_id'] = this.currentPlayingMongoId;

    playLogReqdata['sec'] = this.currentPlayingSec;
    playLogReqdata['min'] = this.currentPlayingMin;
    playLogReqdata['hours'] = this.currentPlayingHour;
    playLogReqdata['genre_text'] = this.currentPlayingGenreText;
    playLogReqdata['languages'] = this.currentPlayingLanguages;
    playLogReqdata['genre'] = this.currentPlayingGenre;
    playLogReqdata['synopsis'] = this.currentPlayingSys;

    if (playLogReqdata['duration'] == undefined || playLogReqdata['duration'] == 'undefined') {
      playLogReqdata['duration'] = this.currentPlayingDuration;
    }

    if (playLogReqdata.stop > playLogReqdata['duration']) {
      playLogReqdata.stop = playLogReqdata['duration'] - 1;
    }

    console.log('duration ' + playLogReqdata['duration']);




    console.log('playLogReqdata.stop ' + playLogReqdata.stop);
    console.log('playLogReqdata.duration ' + playLogReqdata['duration']);


    playLogReqdata.start = 0;
    playLogReqdata.mobile = this.userInfo.result.mobile;

    playLogReqdata['type'] = this.streamInfo.type;
    playLogReqdata['uid'] = this.sessionId;

    if (Math.round(this.playermodal.currentTime()) == 0) {
      return false;
    }


    setTimeout(() => { this.spinner.hide(); }, 10);
    console.log(playLogReqdata);
    let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);


    let totalDuration = Math.round(this.playermodal.duration());//this.playermodal.duration();
    let playedDuration = Math.round(this.playermodal.currentTime());//this.playermodal.currentTime();
    console.log(urlEncodedPlayData);
    if (((playedDuration * 100) / totalDuration) < 98) {
      this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res => {

      }));
    }

    let cs_uri_query = this.videoPlayUrl.split('?')[1];
    let cs_uri_stem = this.videoPlayUrl.split('?')[0];
    let videoLogData = {
      "x-app": this.streamInfo.type == 'livetv' ? 'livetv' : 'vod',
      "x-event": 'stop',
      "sc-bytes": Math.round(this.playermodal.currentTime()),
      "c-user-agent": "platform/web/PlayerVersion-2.7.3",
      "x-duration": this.playermodal.currentTime(),
      "x-sname": playLogReqdata['name'],
      "cs-uri-query": cs_uri_query ? encodeURIComponent(cs_uri_query.toString()) : "",
      "cs-uri-stem": cs_uri_stem
    }


    setTimeout(() => { this.spinner.hide(); }, 10);
    let urlEncodedEncodeParams = this.UserService.convertoJSONToUrlEncoded(videoLogData);
    this.jwPlayerService.analyticsLog(urlEncodedEncodeParams).subscribe((res => { }));



  }
  disableModal() {
    document.querySelector("ngb-modal-window").classList.remove("show");
    document.querySelector("ngb-modal-backdrop").classList.remove("show");
    document.getElementById('nexgTV-app').click();
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
    document.querySelectorAll(".d-block").forEach(el => el.remove())
    document.body.style.overflow = "scroll";
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
