
<footer class="inner_f_i" *ngIf="!isVouchersPage && !isHoroscopePage && !isMandirPage">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="inner_footer_social">
                    <ul>
                        <li>
                            <a href="javascript:void(0);">
                                <!-- <i class="fa fa-facebook-square" aria-hidden="true"></i> -->
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <!-- <i class="fa fa-twitter-square" aria-hidden="true"></i> -->
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <!-- <i class="fa fa-youtube-play" aria-hidden="true"></i> -->
                            </a>
                        </li>
                    </ul>

                    <div class="inner_app">                 
                        <div class="app_list">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.videmind.samatva" class="w_play_store"></a>
                            <a target="_blank" href="https://apps.apple.com/in/app/samatva/id1625255259" class="w_app_store"></a>
                        </div>
                     </div>
                </div>
  
                <div class="inner_footer_page">
                    <ul>
                        <!-- <li>
                            <a href="javascript:void(0);"  routerLink="/advertise">
                                Advertise
                            </a>
                        </li> -->
                        <li>
                            <a href="javascript:void(0);" routerLink="/aboutus">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/tnc">
                                Terms &  Use
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" routerLink="/privacy">
                                Privacy Policy
                            </a>
                        </li>
                        <!-- <li>
                            <a href="javascript:void(0);" routerLink="/faq">
                             FAQs
                            </a>
                        </li> -->
                        
                        <!-- <li>
                            <a href="javascript:void(0);" routerLink="/media">
                                Media
                            </a>
                        </li> -->
                        <li>
                            <a href="javascript:void(0);" routerLink="/contactus">
                                Contact Us
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <!-- <span class="demo_c">This is demo website for testing purposes.</span> -->
  
                <!-- <p class="copy">&copy;  2022, Samatva is an OTT platform run by Digivive Services Pvt Ltd.</p> -->
                <p class="copy">&copy;  2022</p>
            </div>
        </div>
    </div>        
  </footer>