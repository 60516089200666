import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map } from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';


@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject(false);
  public currentUser:any;

  private currentMenuSubject = new BehaviorSubject(false);
  public activeMenu = this.currentMenuSubject.asObservable();
  currentSlugName: any;

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private http: HttpClient,
  ) { 
   this.currentUser = this.currentUserSubject.asObservable();
  }
  
  populate() {
    if (this.jwtService.getToken()) {
      this.apiService.get('/user')
        .subscribe(
          data => this.setAuth(data.user),
          err => this.purgeAuth()
        );
    } else {
      this.purgeAuth();
    }
  }

  setAuth(user: any) {
    this.jwtService.saveToken(user.data);
    this.currentUserSubject.next(user);
    // localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('id', user.user_id);
    localStorage.setItem('type', user.role);
    localStorage.setItem('user_name', user.user_name);
    localStorage.setItem('uname', user.uname);
    localStorage.setItem('uemail', user.uemail);
    localStorage.setItem('menu', JSON.stringify(user.menu));

  }

  purgeAuth() {
    this.jwtService.destroyToken();
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('id');
    localStorage.removeItem('productId');
    localStorage.removeItem('type');
    localStorage.removeItem('user_name');
    localStorage.removeItem('uname');
    localStorage.removeItem('data');
    localStorage.removeItem('addMessageText');
    localStorage.removeItem('uemail');
    localStorage.removeItem('parentControl');
  }

  attemptAuth(credentials): Observable<User> {
    return this.apiService.post('signupnexg',  credentials).pipe(map(data => {
      if (data['code'] != 404) this.setAuth(data);
      return data;
    }
    ));
  }

  getHistory(credentials): Observable<User> {
    return this.apiService.post('auth/getHistory', credentials).pipe(map(data => {
      return data;
    }));
  }

  getMenuListForNexGtv(credentials):Observable<User> {
    return this.apiService.post('auth/getMenuListForNexgtv', credentials).pipe(map(data => {
      return data;
    }
    ));
  }

  changePassword(credentials): Observable<User> {
    return this.apiService.put('signupnexg/updatepass', credentials).pipe(map(data => {
      return data;
    }
    ));
  }


  forgotPassword(credentials){
    return this.apiService.post('signupnexg/resetpass', credentials).pipe(map(data => {
      return data;
    }
    ));

  }
  login(cond) {
    return this.apiService.post('signupnexg/login', cond).pipe(map(data => {
      return data;
    }));
  }

  verifyOtp(data){
    
    return this.apiService.post('signupnexg/verifyotp', data).pipe(map(data => {
      return data;
    }));
  }

  resetPassword(credentials) {
    return this.apiService.post('signupnexg/updatepass', credentials).pipe(map(data => {
      return data;
    }));
  }


  update(user): Observable<User> {
    return this.apiService
      .put('/update/user', { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data.user);
        return data.user;
      }));
  }

  emailExist(cond) {
    return this.apiService.post('auth/emailExist', cond).pipe(map(data => {
      return data;
    }));
  }


  convertoJSONToUrlEncoded(params){
      var queryString = Object.keys(params).map(function(key) {
          return key + '=' + params[key]
      }).join('&');
      queryString.replace('"', '');
      return queryString;
  }

  loginStatus(loginStatus:boolean){
    this.currentUserSubject.next(loginStatus)
  }

  updateSelectedMenu(menuName){
    this.currentMenuSubject.next(menuName)
  }

  requestOtp(credentials){
    return this.apiService.post('signupnexg/sendpotp', credentials).pipe(map(data => {
      return data;
    }));

  }

  setCurrentSlugName(name) {
    localStorage.setItem('slug',name);
  }

  get getCurrentSlugName() {
    return this.currentSlugName = localStorage.getItem('slug');
  }

  
  dynamicTab(tabparam) {

    console.log(tabparam);
    return this.apiService.get(`tabdata?${tabparam}`).pipe(map(data => {
      return data;
    }));
  }

  // tellUs Api Process

  getTellUsFields(data){
    return this.apiService.post('additionalprofilefields/updateprofile',data).pipe(map(data => {
      return data;
    }));
  }
}
