// var filterDataType = localStorage.getItem('filterTypeData');
// var isCloudfrontEnabled  = JSON.parse(filterDataType).is_cloudfront_enabled;
// var bucket  = JSON.parse(filterDataType).bucket;

export const environment = {
  production: true,

  api_url: 'https://apps.digivive.com/arpan/',
  play_url:'https://apps.digivive.com',
  api_base_url:'https://apps.digivive.com/production/',
  //googleTrackingCode: 'G-1QR6CKY32N', old 
  googleTrackingCode: 'G-D8FBSCLJ5L', 
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
};