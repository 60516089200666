import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { ApiService, Errors, UserService } from '../../../core';
import { CommonService } from "src/app/core/services/common.service";
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { UserTypeConstants, CatlougeTypeConstants, resendOtpTimer } from "src/app/core/constants";
import { PlayListService } from '../../playlist/playlist/playlist.service';
import { NgbModal, ModalDismissReasons, } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [PlayListService]
})
export class RegisterComponent implements OnInit {

  errors: Errors = { errors: {} };
  registerForm: FormGroup;
  @ViewChild('closebutton') closebutton;
  timezone: any = '';
  otpScreen: boolean = false;
  isregistred: boolean = false;
  hide: boolean = true;
  loginPageStatus: boolean = false;
  signUpPageStatus: boolean = true;

  //Tell Us
  tellUsScreen: boolean = false;
  regsterScreen: boolean = true;

  // Form Submitted variable status 
  isSignUpSubmited: boolean = false;
  isForgotSubmitted: boolean = false;
  isResetSubmitted: boolean = false;
  isOtpSubmitted: boolean = false;
  userExist: any;
  userExistLimit: string = '';


  // Hide Show Password
  passHide: boolean = true;
  cPassHide: boolean = true;
  verifyForm: FormGroup;
  verifySuccessMsg: string;
  otpHide: boolean = false;

  timezoneForGA: any;
  locale: any;
  timeZoneName: any;
  otpInt: any = 1;
  maxOtpAttempt = 5;
  passLengthError: string;
  error_code: any;

  mobileNumber: string = '';
  emailId: string = '';
  stringArray: any;
  fristString: any;
  secondString: any;
  show: boolean = false;
  showErrorString: any;
  registerErrorCode: any;
  verifyOTPErrorString: any;
  verifyOTPErrorCode: any;

  tellUsForm: FormGroup;
  tellUsAllFieldsArray: any = [];
  errorCode: any;
  tellUsSuccessMsg: boolean=false;
  errorCode1: any;
  isTellUsSubmitted: boolean = false;
  public d = parseInt(new Date().getFullYear()+"");
  @ViewChild('openTellUsModel') openTellUsModel: ElementRef;
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    public commonService: CommonService, private googleEvent: EventTrackingService,
    public playListService: PlayListService,
    private apiService: ApiService,
    private modalService: NgbModal
  ) {

    this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(this.timezone);
    this.registerForm = this.fb.group({
      'pass': ['', [Validators.required, Validators.minLength(8)]],
      'cpass': ['', [Validators.required]],
      'catlogue': [CatlougeTypeConstants.catlogue],
      'device_id': ['web'],
      'platform': ['web'],
      'ipaddress': ['127.0.0.1'],
      'device_token': ['web'],
      'os_version': ['windows 10'],
      'build_version': ['1.0.0'],
      'fb_id': [''],
    }, {
      validator: this.commonService.passwordMatch('pass', 'cpass')
    });

    this.verifyForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.minLength(6)]],
      'catlogue': [CatlougeTypeConstants.catlogue, [Validators.required]],
    });

    this.tellUsForm = this.fb.group({});
    

    this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    // this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    // this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));


    // if (this.timezone == 'Asia/Calcutta') {
    //   this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    //   this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
    // } else {
    //   this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    //   this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
    // }

    this.timezoneForGA = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.locale = Intl.DateTimeFormat().resolvedOptions().locale;
    this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZoneName;
    // this.googleEvent.eventEmitter(this.locale + ' - ' + this.timezoneForGA, 'Sign Up', 'Sign Up button click');
    var user = this.mobileNumber ? this.mobileNumber : this.emailId;
    //this.googleEvent.eventEmitter('sign_up', 'sign_up', 'Sign Up button click.', 0, user, 'Sign Up button click.');
  }



  get registerFormControls() { return this.registerForm.controls; }
  get otpFormControls() { return this.verifyForm.controls; }
  get tellFormControls() { return this.tellUsForm.controls; }

  setMobileEmailFormCOntroll(forname) {
    // if(this.timezone == 'Asia/Calcutta'){
    //   forname.addControl('mobile',new FormControl('', [Validators.required, Validators.minLength(10)]));
    // } else {
    //   forname.addControl('emailaddress',new FormControl('', [Validators.required, Validators.email]));
    // }
  }
  ngOnInit() {
    console.log(this.timezone);
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone =='Asia/Kolkata'?'Asia/Calcutta':'Asia/Calcutta';    
    // this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (localStorage.getItem('isAuthenticated')) {
      this.router.navigateByUrl('/');
    }
  }


  chnageRegisterVia(event: any) {
    let text: any;
    text = event.target.innerText;
    if (text == 'Register via Mobile Number') {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      console.log(this.timezone);
      this.timezone = '';
      this.registerForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));
      this.verifyForm.addControl('emailaddress', new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]));

      this.registerForm.removeControl('mobile');
      this.verifyForm.removeControl('mobile');

      // if (this.timezone == 'Asia/Calcutta') {


      // } else {

      //   this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Kolkata' ? 'Asia/Calcutta' : Intl.DateTimeFormat().resolvedOptions().timeZone;
      //   this.registerForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));
      //   this.verifyForm.addControl('mobile', new FormControl('', [Validators.required, Validators.minLength(10)]));

      //   this.registerForm.removeControl('emailaddress');
      //   this.verifyForm.removeControl('emailaddress');
      // }


    }

  }

  goToHome() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById('nexgTV-app').click();
      document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
      document.querySelectorAll(".d-block").forEach(el => el.remove())
      document.body.style.overflow = "scroll";

    } catch (e) {

    }
    this.router.navigateByUrl('/contactus');
  }


  register() {
    this.userExistLimit = '';
    this.errors = { errors: {} };
    this.isSignUpSubmited = true;
    if (this.registerForm.invalid) {
      return;
    }
    try {

    } catch (e) {

    }
    let credentials = this.registerForm.value;
    console.log(credentials);
    credentials.pass = this.registerForm.value.pass.trim();
    credentials.cpass = this.registerForm.value.cpass.trim();

    this.mobileNumber = credentials.mobile;
    this.emailId = credentials.emailaddress;

    console.log(`mobile--${credentials.mobile}`);
    console.log(`email--${credentials.emailaddress}`);
    // console.log(credentials.cpass.length);
    if (credentials.pass.length < 8 || credentials.cpass.length < 8) {
      this.userExist = 'Password must be at least 8 characters';
      return;
    } else {
      this.userExist = ''
    }

    delete credentials['cpass'];
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.attemptAuth(urlEncodedBodyData).subscribe(data => {
      this.showErrorString = data['error_string'];
      this.registerErrorCode = data['error_code'];
      if (this.registerErrorCode == '214') {
        return;
      }
      console.log(data);
      this.userExist = ''
      localStorage.setItem('sessionId', data['result']['sessionId'])
      if (data['isregistred'] == true) {
        this.isregistred = true;
      }

      var user = this.mobileNumber ? this.mobileNumber : this.emailId;
      if (data['error_code'] == "201" || data['error_code'] == "200" || data['error_code'] == "428") {
        this.otpScreen = true;
        this.userExist = data['error_string'];

        if (this.timezone == 'Asia/Calcutta') {
          this.verifyForm.patchValue({
            mobile: credentials['mobile']
          })
        } else {
          this.verifyForm.patchValue({
            emailaddress: credentials['emailaddress']
          })
        }
        this.resendOtpTimer()
        this.googleEvent.eventEmitter('sign_up', 'sign_up', user + ' registration pending.', 0, user, user + ' registration pending.');

        // this.verifySuccessMsg=' ';
      } else if (data['error_code'] == '429') {

        if (data['error_string'].includes('Contact')) {
          this.stringArray = data['error_string'].split("Contact");
          this.fristString = this.stringArray[0];
          this.secondString = this.stringArray[1];
          this.show = true;
          console.log('this.fristString', this.fristString + 'this.secondString', this.secondString);
        } else {
          console.log(data['error_string']);
          this.userExist = '';//data['error_string'];
          this.userExistLimit = data['error_string'];
        }



      }
      else if (data['error_code'] == '428') {
        this.otpScreen = true;
        this.userExist = data['error_string'];

      }
      if (data['error_code'] == "214") {
        this.userExist = data['error_string']
        this.googleEvent.eventEmitter('sign_up', 'sign_up', this.userExist, 0, user, this.userExist);
      }
      if (data['error_code'] == "202") {
        // this.userExist = data['error_string']
        this.userExist = 'Incorrect OTP. Please try again.'
      }

      // this.closebutton.nativeElement.click();
      // this.router.navigateByUrl('/home');
    }, err => {
      this.errors = err;
      var user = this.mobileNumber ? this.mobileNumber : this.emailId;
      this.googleEvent.eventEmitter('sign_up', 'sign_up', user + ' registration failed.', 0, user, user);

    });
  }


  verifyOtp() {
    this.showErrorString = '';

    this.errors = { errors: {} };
    console.log(this.verifyForm);
    this.isOtpSubmitted = true;
    if (this.verifyForm.invalid) {
      // return;
    }
    const credentials = this.verifyForm.value;
    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.verifyOtp(urlEncodedBodyData).subscribe(data => {
      this.verifyOTPErrorString = data['error_string'];
      this.verifyOTPErrorCode = data['error_code'];
      if (Object.keys(data.result).length == 0) {
        return;
      }

      console.log(data);
      if (Object.keys(data.result).length > 0 || data['error_code'] == "202") {
        this.userExist = data['error_string']
      }

      if (data['error_code'] == "429") {

        if (data['error_string'].includes('Contact')) {
          this.stringArray = data['error_string'].split("Contact");
          this.fristString = this.stringArray[0];
          this.secondString = this.stringArray[1];
          this.show = true;
          console.log('this.fristString', this.fristString + 'this.secondString', this.secondString);
        } else {
          console.log(data['error_string']);
          this.userExist = '';//data['error_string'];
          this.userExistLimit = data['error_string'];
        }

        // this.error_code = data['error_code'];
        // this.userExist = data['error_string']
      }

      if (data['error_code'] == "200") {
        this.userExist = '';
        this.verifySuccessMsg = 'Verified'
        var user = this.mobileNumber ? this.mobileNumber : this.emailId;
        this.googleEvent.eventEmitter('sign_up', 'sign_up', user + ' is successfully registered.', 0, user, user);

        setTimeout(() => {
          console.log(data);
          // localStorage.setItem('userInfo', data)
          // this.closebutton.nativeElement.click();
          //this.router.navigateByUrl('/');

          let credentials = this.registerForm.value;
          let username = '';
          if (credentials.mobile == null || credentials.mobile == undefined) {
            username = credentials.emailaddress
          } else {
            username = credentials.mobile;
          }
          this.signin(username, this.registerForm.value.pass.trim());
        }, 2000);
      }

    }, err => {
      this.errors = err;
    });

  }

  postTellUs() {
    this.errors = { errors: {} };
    this.isTellUsSubmitted = true;
    let day =  this.tellUsForm.controls.day.value;
    let month =  this.tellUsForm.controls.month.value;
    let year =  this.tellUsForm.controls.year.value;
    var datum = new Date(Date.UTC(year,month-1,day));
    var timeStap = datum.getTime();  //d/1000;
    this.tellUsForm.patchValue({dob:timeStap});
    this.tellUsForm.addControl('is_additional_profile_field_skipped', new FormControl(''));

    console.log(this.tellUsForm);

    if (this.tellUsForm.invalid) {
      return;
    }

    console.log(this.tellUsForm);
    const credentials = this.tellUsForm.value;
    console.log(credentials);
    delete  credentials.year;
    delete  credentials.day;
    delete  credentials.month;

    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
     this.userService.getTellUsFields(urlEncodedBodyData).subscribe(data => {
       if (data['error_code'] == "200") {
         this.tellUsSuccessMsg = true;
         setTimeout(() => {
           this.getPlaylist();  
           this.closebutton.nativeElement.click();
        }, 2000);
      }

    }, err => {
      this.errors = err;
    });

  }


  hidePassword() {
    this.hide = !this.hide;
  }

  login() {
    this.loginPageStatus = true;
    this.signUpPageStatus = false;
  }


  signin(usename, password) {
    let credentials =
    {
      'pass': password,
      'catlogue': [CatlougeTypeConstants.catlogue],
      'device_id': ['web'],
      'platform': ['web'],
      'ipaddress': ['127.0.0.1'],
      'device_token': ['web'],
      'os_version': ['windows 10'],
      'build_version': ['1.0.0'],
      'fb_id': ['']
    };
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if ((usename) && reg.test(usename) == false) {
      credentials['mobile'] = usename;
    } else {
      credentials['emailaddress'] = usename;
    }


    let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(credentials);
    this.userService.login(urlEncodedBodyData).subscribe(data => {
      if (data.error_code == "200") {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userInfo', JSON.stringify(data));
        localStorage.setItem('sessionId', data.result.sessionId);
        localStorage.setItem('debug', 'false');
        localStorage.setItem('parentControl', data.result.parental_control);

        // show TeelUs Popup Files Api Hit Start
        let da = "";
        this.userService.getTellUsFields(da).subscribe(data1 => {
          this.errorCode1 = data1['error_code'];
          if (this.errorCode1 == "200") {
            if (data1['result']['is_profile_complete'] == 0 && data1['result']['is_additional_profile_field_skipped'] == 0 &&  data1['result']['additional_field_data'].length>0) {
              // this.tellUsScreen= true;
              // this.regsterScreen = false;
              // this.otpScreen = false;
        
              // this.tellUsAllFieldsArray = data1['result']['additional_field_data'];
              
              // this.tellUsAllFieldsArray.forEach((item:any)=>{
              //   if(item.is_required==1){
              //     this.tellUsForm.addControl(item.slug, new FormControl('',[Validators.required]))
              //     if(item.type=='input_calender'){
              //       this.tellUsForm.addControl(item.slug, new FormControl(''))
              //       this.tellUsForm.addControl('day', new FormControl('Day', [Validators.required, Validators.pattern("^[0-9]*$")]));
              //       this.tellUsForm.addControl('month', new FormControl('Month', [Validators.required, Validators.pattern("^[0-9]*$")]));
              //       this.tellUsForm.addControl('year', new FormControl('Year', [Validators.required, Validators.pattern("^[0-9]*$")]));
              //     }
              //   }else{
              //     this.tellUsForm.addControl(item.slug, new FormControl(''))
              //   }

              // });

              this.closebutton.nativeElement.click();
              this.openTellUsModel.nativeElement.click();
               
            } else {
              this.getPlaylist();  
            }
          }

        }, err => {
          this.errors = err;
        });
        // show TeelUs Popup Files Api Hit End 

        // if(this.tellUsAllFieldsArray.length>0){
        //   this.tellUsScreen= true;
        //   this.regsterScreen = false;
        //   this.otpScreen = false;
        // }else{
        //   localStorage.setItem('isAuthenticated', 'true');
        //   localStorage.setItem('userInfo', JSON.stringify(data));
        //   localStorage.setItem('sessionId', data.result.sessionId);
        //   localStorage.setItem('debug', 'false');

        //   localStorage.setItem('parentControl', data.result.parental_control);
        //   this.getPlaylist();  
        // }


        // this.router.navigateByUrl(this.currentRoute);
      } else {
      }

    }, err => {
      this.errors = err;
    });
  }
  
  openTellUs(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false, windowClass:'tellu' }).result.then((result) => {
    }, (reason) => {
    });

  }


  getPlaylist() {
    let tempGetPlay = {
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getplaylist",
    }

    let urlEncodedPlayListParams = this.userService.convertoJSONToUrlEncoded(tempGetPlay);

    this.playListService.getPlaylist(urlEncodedPlayListParams).subscribe(data => {
      // this.playList = data;

      if (data && data.result) {
        localStorage.setItem('playListId', data?.result[0]?.playlistid);
        this.userService.loginStatus(true);
        this.closebutton.nativeElement.click();
        //this.closeModal();

        if (Object.keys(this.commonService.getAssetsData()).length > 0)
          this.commonService.goToDetail(this.commonService.getAssetsData(), '', 1);
        else
          window.location.reload();


      }
    });
  }


  closeModal() {
    document.querySelector("ngb-modal-window").classList.remove("show");
    document.querySelector("ngb-modal-backdrop").classList.remove("show");
    document.getElementById('nexgTV-app').click();
    document.querySelectorAll(".modal-backdrop").forEach(el => el.remove())
    document.querySelectorAll(".d-block").forEach(el => el.remove())
    document.body.style.overflow = "scroll";

  }
  clearResponseError() {
    this.userExist = '';
  }

  requestForOtp() {
    this.showErrorString = '';
    this.verifyOTPErrorString = '';
    // this.resendOTPstatus = true;
    let otpObj = {
      catlogue: CatlougeTypeConstants.catlogue,
      send_for: 'signup'
    }
    this.userService.requestOtp(otpObj).subscribe(data => {
      this.error_code = data['error_code'];
      this.showErrorString = data['error_string'];
      if (data.error_code == "201") {
        this.userExist = data['error_string'];
        this.resendOtpTimer();
      }

      if (data.error_code == "429") {
        // this.maxOtpAttempt = 0;
        this.userExist = data['error_string'];
      }
      else if (data['error_code'] == '428') {
        this.userExist = data['error_string'];
      }


    });
  }

  resendOtpTimer() {
    this.otpInt = resendOtpTimer.otpTimer;
    // this.resendOTPstatus=true;
    this.maxOtpAttempt--;
    let timerInterval = setInterval(() => {
      if (this.otpInt == 0) {
        clearInterval(timerInterval);
        // this.otpInt = 60;  
        console.log(this.otpInt);
      }
      this.otpInt--;
      console.log(this.maxOtpAttempt);
    }, 1000);

  }

  reloadPage() {
    window.location.reload();
  }


  termsPageFn() {
    window.open("terms", "_blank");
  }
}
