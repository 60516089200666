import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AssetsService } from 'src/app/core/services/assets.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { AccountService } from '../account/account.service';
declare var $: any;
@Component({
  selector: 'app-horoscope',
  templateUrl: './horoscope.component.html',
  styleUrls: ['./horoscope.component.css']
})
export class HoroscopeComponent implements OnInit {

  public isSession: boolean;
  public sessionId: any;
  public type: any;
  public rId: any;
  public id: any;

  public some_error: any;
  public registeredBy: any;
  public registredEmail: any;
  public registredMobileNumber: any;
  public name: any;
  public horoscpeName: any;
  public userHoroScopeData: any;
  public horoScopeData: any;
  public horoName: any;


  progrssBarValue: number = 0;
  progressInc: number = 0;


  constructor(public userService: UserService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public assetsService: AssetsService,
    private googleEvent: EventTrackingService,
    private apiService: AccountService,) { }

  ngOnInit(): void {

    
    this.activatedRoute.params.subscribe(params => {

      this.sessionId = params['sessionId'];
      this.type = params['rtype'];
      this.rId = params['rid'];
      if (params['sessionId']) {
        this.isSession = true;
      } else {
        this.isSession = false;
      }
    });

    if (localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession == false) {
      $('#openLogin').trigger('click');
      //this.openLoginModel.nativeElement.click();
    } else {
      if (localStorage.getItem('sessionId') == "" || localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession) {
        localStorage.setItem('sessionId', this.sessionId);
      }
      this.profileDetails();
    }

    this.liniarProgressBar();


  }



  profileDetails() {
    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {
        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by == undefined ? '' : data.result.register_by;
          this.registredEmail = data.result.emailaddress == undefined ? '' : data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile == undefined ? '' : data.result.mobile;
          this.name = data.result.first_name == undefined ? '' : data.result.first_name;
          this.id = data.result._id.$oid;

          let UserData = {
            user: this.registredEmail == '' ? this.registredMobileNumber : this.registredEmail,
            id: this.rId
          }
          let urlEncodedParams = this.userService.convertoJSONToUrlEncoded(UserData);
          this.assetsService.checkHoroscopeData(urlEncodedParams).subscribe(data => {
            this.userHoroScopeData = data;
            if (this.userHoroScopeData.result.previous_zodiacsign != 0) {
              let zodiacsign = 'Rashi' + this.userHoroScopeData.result.previous_zodiacsign;
              this.horoscpeName = zodiacsign;
              this.getHoroscope(zodiacsign);
            } else {
              this.getHoroscope('RashiAries');
            }
          });
          this.googleEvent.eventEmitter('Vouchers Event', 'Vouchers', 'Click on Vouchers page', 0, this.registredEmail == '' ? this.registredMobileNumber : this.registredEmail, 'Vouchers page');

        }

      },
        error => {
          this.some_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }


  getHoroscope(e) {
    this.horoscpeName = e;
    this.horoName = e.toLowerCase().substring(5);
    let temp = {
      type: 'horoscope',
      id: this.rId,
      zodiacsign: this.horoscpeName,
      user: this.registredEmail == '' ? this.registredMobileNumber : this.registredEmail,
    }
    let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.assetsService.getHoroscopeData(urlEncodedContestantParams).subscribe(data => {
      this.horoScopeData = data.result;
      document.getElementById("nexg_ngb-progressbar").style.display = 'none';
      document.getElementById('maincontentarea').classList.remove("_beforeLoad");
    
    });


    $('.d-btn-hide').trigger('click');
  }

  selectHoroscope(name) {
    this.horoName = name.toLowerCase().substring(5);
  }

  openLoginModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
    }, (reason) => {
    });

  }

  liniarProgressBar() {
    // progress bar code
    this.progrssBarValue = 0;
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
      console.log(this.progrssBarValue);
    }, 500)

  }



}
