import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetsService } from "../../core/services/assets.service";
import { UserService } from "src/app/core/services/user.service";
import { AccountService } from '../account/account.service';
declare var $: any;
@Component({
  selector: 'app-vouchers-detail',
  templateUrl: './vouchers-detail.component.html',
  styleUrls: ['./vouchers-detail.component.css']
})
export class VouchersDetailComponent implements OnInit {
  public sessionId: any;
  public isSession: boolean;
  public vouchersDetail: any;
  public notFoundImg: any;
  public some_error: any;
  public registeredBy: any;
  public registredEmail: any;
  public registredMobileNumber: any;
  public name:any;
  public id:any;
  public voucherId: any;
  public terms_cond;
  public deal:any;

  constructor(public activatedRoute: ActivatedRoute, 
    private modalService: NgbModal, 
    private userService: UserService, 
    private assetsService: AssetsService,
    private apiService: AccountService,
    private googleEvent: EventTrackingService,
    private router: Router) { }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.sessionId = params['sessionId'];
      this.voucherId = params['voucherId'];
      if (params['sessionId']) {
        this.isSession = true;
      } else {
        this.isSession = false;
      }
    });


    if (localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession == false) {
      $('#openLogin').trigger('click');
      //this.openLoginModel.nativeElement.click();
    } else {
      if (localStorage.getItem('sessionId') == "" || localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession) {
        localStorage.setItem('sessionId', this.sessionId);
      }
      this.profileDetails();
      this.gitVoucherDetail();
    }

    
  }


  profileDetails() {
    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {
        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by ==undefined ? '' : data.result.register_by;
          this.registredEmail = data.result.emailaddress ==undefined ? '' : data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile ==undefined ? '' : data.result.mobile;
          this.name = data.result.first_name ==undefined ? '' : data.result.first_name;
          this.id = data.result._id.$oid;
       
          let UserData = {
            catlogue: 'Samatva',
            user_id: this.id,
            page_name: 'Vouchers',
            mobile: this.registredMobileNumber,
            email: this.registredEmail,
            user_name: this.name,
            registeredby: this.registeredBy
          }
          let urlEncodedParams = this.userService.convertoJSONToUrlEncoded(UserData);
          this.assetsService.postVoucherUserDetail(urlEncodedParams).subscribe(data=>{
            
          });

          this.googleEvent.eventEmitter('Voucher Event', 'Vouchers Details', 'Click on Voucher Detail page ', 0, this.registredEmail==''? this.registredMobileNumber : this.registredEmail, 'Voucher Detail page');

        }

      },
        error => {
          this.some_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }

  gitVoucherDetail(){
    let temp = {
      action: 'details',
      id: this.voucherId
    }

    let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.assetsService.getVouchers(urlEncodedContestantParams).subscribe(data => {
      this.vouchersDetail = data.result[0];
      // if((this.vouchersDetail.terms_condition).includes('\n')){
        this.terms_cond = this.vouchersDetail.terms_condition.split('\n');
        this.deal = this.vouchersDetail.deal_summary.split('\n');
      //   console.log('yes');
      // }else{
      //   console.log('no');
      // }
    });
  }

  landingPage(sessionId) {
    if (navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Android/i)) {
      this.router.navigate(['vouchers/' + sessionId]);
  }
    this.router.navigate(['vouchers/' + sessionId]);
  }


 
}
