import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation, Output, TemplateRef, Injector, OnDestroy, ChangeDetectorRef, HostListener } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationStrategy } from "@angular/common";
import { VideoPlayerService } from "src/app/core/services/videoPlayerService";
import { UserService, DeviceService } from "src/app/core";
import { EventTrackingService } from "src/app/services/google/event-tracking.service";
import { jwPlayerService } from "src/app/views/jw-video-player/jwplayer.servies";
import { Analyticsstreaminglog, CatlougeTypeConstants } from "src/app/core/constants/index";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { DfpVideoDirective } from "ngx-dfp/video";
import { ActivatedRoute, Router } from "@angular/router";
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from "@angular/common/http";
import { ThemeService } from "ng2-charts";
import { AssetsService } from "src/app/core/services/assets.service";
import { Subscription } from 'rxjs';
import { TrackInfoModalComponent } from "../track-info-modal/track-info-modal.component";
import { TrackSettingsModalComponent } from "../track-settings-modal/track-settings-modal.component";
import { AddToPlaylistModalComponent } from "../add-to-playlist-modal/add-to-playlist-modal.component";
import { ShareModalComponent } from "../share-modal/share-modal.component";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { CommonService } from "src/app/core/services/common.service";

declare var videojs;
declare var google;
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class AudioPlayerComponent implements OnDestroy {


  @ViewChild(DfpVideoDirective) dfpVideo: DfpVideoDirective;

  adInput = new EventEmitter<any>();
  @Output() onModalClose = new EventEmitter<any>();
  @Output() onTrackActivity = new EventEmitter<any>();
  @Output() checkTrackActivity = new EventEmitter<any>();
  @Output() openErrorMsg = new EventEmitter<any>();


  @Input() streamInfo: any;
  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: string;
  title: string = "";
  title1: string = "";
  audioPlayUrl: any = "";

  userUnique: string = "NA";
  timerOfPlayer: any;
  timerForPlayer: number;
  audioType: string = "audio";
  nextVideoCode: string = "";
  preVideoCode: string = "";
  nextVideoName: string = "";
  preVideoName: string = "";
  nextEpisodeNumber: string = "";
  preEpisodeNumber: string = "";
  nextSeasonNumber: string = "";
  preSeasonNumber: string = "";
  prePlayingThumb: string = "";

  preVideoDuration: string = "";
  is_trailer: number = 0;
  public is_live_evnet: number = 0;

  currentPlayingCode: string = "";
  currentPlayingCharegCode: string = "";
  currentPlayingMongoId: string = "";
  currentPlayingContentAvailability: string = "";
  currentPlayingThumb: string = "";
  currentPlayingSec: string = "";
  currentPlayingMin: string = "";
  currentPlayingHour: string = "";
  currentPlayingDuration: string = "";
  currentPlayingGenreText: string = "";
  currentPlayingGenre: string = "";
  currentPlayingLanguages: string = "";
  currentPlayingSys: string = "";
  currentPlayingShowID: string = "";
  currentPlayingSeasonId: string = "";
  currentPlayingName: string = "";

  nextPlayingMongoId = "";
  nextPlayingCharegCode: string = "";
  nextPlayingContentAvailability: string = "";
  nextPlayingThumb: string = "";
  nextPlayingSec: string = "";
  nextPlayingMin: string = "";
  nextPlayingHour: string = "";
  nextPlayingGenreText: string = "";
  nextPlayingGenre: string = "";
  nextPlayingLanguages: string = "";
  nextPlayingSys: string = "";
  nextPlayingStop: string = "";
  nextPlayingDuration: string = "";
  nextVideoDuration: string = "";
  nextPlayingStartTime: any = 0;
  prePlayingStartTime: any = 0;

  nextPlayingShowID: string = "";
  nextPlayingSeasonId: string = "";
  public action1: any;


  playType: string = "";
  assetsCode: any;
  rmContApiCalledStatus = false;
  lastEpisode: any;

  error: string = "";
  startTime: any = 0;
  nextVideObj: any = { start: 0, stop: 0, duration: 0 };

  seasonarray: any = [];
  isHudredPersent: boolean = true;
  nextAudio: any;

  // @ViewChild("showErrorMsg") showErrorMsg;
  @ViewChild("closeShowErrorMsg") closeShowErrorMsg;
  playUrlResObj: any;
  videoLabelObj: any;
  watch_credit_status: any = 1;
  currentPlayingTime: any;
  shouldCloseAfterPersent: boolean = true;
  AudioClose: any;

  // for uuid
  data = "Example1";//Message to Encrypt
  iv = CryptoJS.enc.Base64.parse("");//giving empty initialization vector
  key = CryptoJS.SHA256("Message");//hashing the key using SHA256
  encrypted_UUID: any;
  deviceInfo = null;
  interval_ID: any;
  ipAddress: any;
  errmsg: any;
  is_preview: boolean;
  recommandation_audio_list: any;

  next_track_api_data: any;
  recommandation_album_details: any = {};
  subscription: Subscription;
  is_repeat_mode: boolean;
  is_repeat_one_mode: boolean;
  is_shuffle_mode: boolean;

  playing_track_details: any;
  similar_track_data: any;
  next_track: any;

  isCloudFrontType: boolean = true;
  is_paid_alert: boolean;

  is_last_track: boolean = false;

  nextAudioPaidData: any;
  nextEpisodeIsBlank: boolean = true;



  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
      $('#AudioFullButton i').removeClass('fa-angle-down');
      $('#AudioFullButton i').addClass('fa-angle-up');
      $("#maincontentarea").removeClass("before_similar");
      document.getElementById("maincontentarea").classList.remove("before_similar");
      $('#album_similar').modal('hide');
  }

  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationStrategy,
    public VideoPlayerService: VideoPlayerService,
    private googleEvent: EventTrackingService,
    public UserService: UserService,
    public jwPlayerService: jwPlayerService,
    public spinner: SpinnerVisibilityService,
    private toastr: ToastrService,
    public router: Router,
    private deviceApiService: DeviceService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService,
    public assetsService: AssetsService,
    public modalService: NgbModal,
    public commonService: CommonService,
    public cdr: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute
  ) {
    history.pushState(null, null, window.location.href);
    // this.location.onPopState(() => {
    //   this.mobileBrowserBackbutton();
    // });
    // window.stop();

    console.log(this.streamInfo);
    this.subscription = assetsService.playTrack$.subscribe(
      track => {
        console.log(track);
        // this.is_preview = track.is_preview;
       
        if (track.is_track_changed && !track.is_preview) {
          let content_availability =  track.content_availability.toLowerCase();
          
          this.next_track = track;
          this.streamInfo.current_index = -1;
          this.recommandation_album_details.image = content_availability == 'paid' && track.is_pack_active == '0' ? this.recommandation_album_details.image  : '';
          this.streamInfo.audio_list = track.audio_list;
          this.streamInfo.playlist_id = track.playlist_id;
          this.streamInfo.is_playlist = track.is_playlist;
          this.getNexTrackData(track.code, true);
          this.is_preview = false;


          this.streamInfo.is_similar_track = false;
          console.log(this.streamInfo.current_index);
        } else if (track.is_track_changed && track.is_preview) {
          console.log(track);

          let assetDetail = {
            "code": track.code,
            "catlogue": CatlougeTypeConstants.catlogue,
            "platform": 'web',
            "type": 'audio'
          }
          let urlEncodedStreamInfo2 = this.UserService.convertoJSONToUrlEncoded(assetDetail);
          // clearInterval(this.interval_ID);
          this.is_preview = track.is_preview;
          this.playAudioPreview(urlEncodedStreamInfo2, track.is_preview);

        } else if (track.is_close_player) {
          clearInterval(this.interval_ID);
          setTimeout(() => { this.playermodal.dispose(), document.querySelector("ngb-modal-window").classList.remove("show") }, 50);
          document.getElementById("maincontentarea").classList.remove("before_similar");
          $('#album_similar').modal('hide');
          this.onModalClose.emit('close');
        } else if (track.type = "pc_episode") {
          this.nextVideoCode = track.code;
          this.is_preview = track.is_preview;
          if (track.is_preview) {
            let assetDetail = {
              "code": track.code,
              "catlogue": CatlougeTypeConstants.catlogue,
              "platform": 'web',
              "type": track.type
            }
            let urlEncodedStreamInfo2 = this.UserService.convertoJSONToUrlEncoded(assetDetail);

            this.playAudioPreview(urlEncodedStreamInfo2, track.is_preview);
          } else {
            this.nextButtonAction(this.nextVideoCode);
          }
        }

      });
  }

  adEvent(event) {
    if (event.type === "complete") {
      // hide ad container
      this.dfpVideo.adContainer.style.zIndex = "-1";
      this.playermodal.play();
    }
    if (event.type === "start") {
      // show ad container
      // this.dfpVideo.adContainer.style.zIndex = '1';
    }
  }
  callMe(value: string) {
    console.log('Called : ' + value);
  }
  mobileBrowserBackbutton() {
    // this.stopAudio();

    this.disableModal();
    if (this.playermodal) this.playermodal.dispose();
    clearInterval(this.interval_ID);

    this.playermodal = "";
    return false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {


    if (this.commonService.filterType == 1) {
      this.isCloudFrontType = true;
    } else {
      this.isCloudFrontType = false;
    }
    this.watch_credit_status = 1;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.sessionId = localStorage.getItem("sessionId");

    if (Object.keys(this.userInfo.result).length > 0) {
      if (this.userInfo.result.register_by == "mobile") {
        this.userUnique = this.userInfo.result.mobile;
      } else {
        this.userUnique = this.userInfo.result.emailaddress;
      }
    }

    this.streamInfo = this.streamInfo;
    this.audioType = this.streamInfo.type;

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.asset != undefined && this.audioType == "pc_episode") {
      this.streamInfo.recently_watched.charge_code = this.streamInfo.recently_watched.asset.charge_code;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.sec == undefined && this.audioType == "pc_episode") {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.sec = this.streamInfo.recently_watched.asset.sec;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.min == undefined && this.audioType == "pc_episode") {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.min = this.streamInfo.recently_watched.asset.min;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.hours == undefined && this.audioType == "pc_episode") {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.hours = this.streamInfo.recently_watched.asset.hours;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.genre_text == undefined && this.audioType == "pc_episode") {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.genre_text = this.streamInfo.recently_watched.asset.genre_text != undefined ? this.streamInfo.recently_watched.asset.genre_text : this.streamInfo.genre_text;
      else
        this.streamInfo.recently_watched.genre_text = this.streamInfo.genre_text == undefined ? "" : this.streamInfo.genre_text;
    } else if (this.streamInfo.recently_watched) {
      this.streamInfo.recently_watched.genre_text = this.streamInfo.genre_text == undefined ? "" : this.streamInfo.genre_text;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.synopsis == undefined) {
      this.streamInfo.recently_watched.synopsis = this.streamInfo.synopsis;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.season_id == undefined) {
      this.streamInfo.recently_watched.season_id = this.streamInfo.season_id;
    }

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.show_id == undefined) {
      this.streamInfo.recently_watched.show_id = this.streamInfo.show_id;
    }

    console.log('this.streamInfo', this.streamInfo);
    if (this.streamInfo.recently_watched) {
      try {
        this.currentPlayingCode = this.streamInfo.recently_watched.code == undefined ? (this.streamInfo.seek_bar_data ? this.streamInfo.seek_bar_data.code : '') : this.streamInfo.recently_watched.code;
        this.currentPlayingMongoId = this.streamInfo.recently_watched.asset_mongo_id; //== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
        this.currentPlayingName = this.streamInfo.name; //== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
        this.currentPlayingCharegCode = this.streamInfo.recently_watched.charge_code; //== undefined ? this.streamInfo.charge_code : this.streamInfo.recently_watched.charge_code;
        this.currentPlayingContentAvailability = this.streamInfo.recently_watched.content_availability; //== undefined ? this.streamInfo.content_availability : this.streamInfo.recently_watched.content_availability;
        this.currentPlayingThumb = this.streamInfo.recently_watched.thumbnail; // == undefined ? this.streamInfo.thumbnail : this.streamInfo.recently_watched.thumbnail;
        this.currentPlayingSec = this.streamInfo.recently_watched.sec; // == undefined ? this.streamInfo.recently_watched.sec == undefined ? this.streamInfo.sec : this.streamInfo.recently_watched.sec : this.streamInfo.recently_watched.asset.sec;
        this.currentPlayingMin = this.streamInfo.recently_watched.min; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.min == undefined ? this.streamInfo.min : this.streamInfo.recently_watched.min : this.streamInfo.recently_watched.asset.min;
        this.currentPlayingHour = this.streamInfo.recently_watched.hours; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
        this.currentPlayingDuration = this.streamInfo.recently_watched.duration; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
        this.currentPlayingGenreText = this.streamInfo.recently_watched.genre_text; //== undefined ? this.streamInfo.genre_text : this.streamInfo.recently_watched.genre_text;
        this.currentPlayingGenre = // Object.keys(this.streamInfo.recently_watched.genre).length == 1 //   ? this.streamInfo.recently_watched.genre[0] //   : ""; // this.streamInfo.recently_watched.genre == undefined ? Object.keys( this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0]: '' : this.streamInfo.recently_watched.genre[0];
          this.currentPlayingLanguages = this.streamInfo.recently_watched.languages; //== undefined ? this.streamInfo.languages : this.streamInfo.recently_watched.languages;
        this.currentPlayingSys = this.streamInfo.recently_watched.synopsis; //== undefined ? this.streamInfo.synopsis : this.streamInfo.recently_watched.synopsis;
        this.currentPlayingShowID = this.streamInfo.recently_watched.show_id; // == undefined ? this.streamInfo.show_id : this.streamInfo.recently_watched.show_id;
        this.currentPlayingSeasonId = this.streamInfo.recently_watched.season_id; //== undefined ? this.streamInfo.season_id : this.streamInfo.recently_watched.season_id;
      } catch (e) {
        console.log(e);
      }
    }

    if (this.streamInfo.seasons) {
      this.preNextVideo();
    }

    let videoStreamObj: any = {
      nmode: "WIFI",
      pushId: "",
      ct: "1",
      build: 10000,
      charge_code: this.streamInfo.code ? this.streamInfo.code : this.currentPlayingCode,
      appv: "1.0.00",
      push_date: "",
      air: "airip",
    };

    let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
    console.log('urlEncodedStreamInfo', urlEncodedStreamInfo);
    this.is_preview = this.streamInfo.is_preview;

    let assetDetail = {
      "code": this.streamInfo.code,
      "catlogue": CatlougeTypeConstants.catlogue,
      "platform": 'web',
      "type": 'audio'
    }
    if (this.streamInfo.type == 'pc_episode') {
      assetDetail.type = this.streamInfo.type;
    }

    let urlEncodedStreamInfo2 = this.UserService.convertoJSONToUrlEncoded(assetDetail);
    clearInterval(this.interval_ID);
    if (this.is_preview) {
      this.playAudioPreview(urlEncodedStreamInfo2, this.is_preview);
    }
    this.playAudioPreview(urlEncodedStreamInfo2, this.is_preview);
    if (!this.is_preview) {
      this.playAudioWithAPI(urlEncodedStreamInfo);
    }

    if (this.streamInfo.type != 'pc_episode') {
      this.audioRecommandationTrack(this.streamInfo);
    }
    // if(this.streamInfo.is_playlist) {
    //   // this.audioRecommandation();
    //   this.audioRecommandationTrack(this.streamInfo);
    // }
    // this.recommandation_album_details.image_square_cloudfront_url = this.streamInfo.image_square_cloudfront_url;
    // this.recommandation_album_details.image = this.streamInfo.image ? this.streamInfo.image : this.streamInfo.image_cloudfront_url;
    this.recommandation_album_details.name = this.streamInfo.name;
    this.recommandation_album_details.highlighted_label = this.streamInfo.highlighted_label;
    this.recommandation_album_details.share_url = this.streamInfo.share_url;
    this.recommandation_album_details.code = this.streamInfo.code;
    console.log('recommandation_album_details', this.recommandation_album_details);




    // this.playAudio(urlEncodedStreamInfo);

    let is_check_device = JSON.parse(localStorage.getItem('is_check_device'));
    console.log('is_check_device', is_check_device);

    this.getDeviceInfo();
    if (is_check_device) {
      if (this.streamInfo.plan != undefined && this.streamInfo.plan != '' && Object.keys(this.streamInfo.plan).length > 0) {
        this.getIPAddress();
        this.interval_ID = setInterval(() => {
          this.getIPAddress();
        }, this.streamInfo.plan.api_duration);
      }
    }

  }

  // for uuid functions
  getDeviceInfo() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  encryptData(data, iv, key) {

    if (typeof data == "string") {
      data = data.slice();
      this.encrypted_UUID = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    else {
      this.encrypted_UUID = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    return this.encrypted_UUID.toString();
  }
  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      // this.plainText = this.sessionId+ '-'+ this.ipAddress;

      this.data = this.sessionId + '-' + this.ipAddress + '-' + this.deviceInfo.os_version;
      console.log('ipAddress', this.ipAddress);
      console.log('sessionId', this.sessionId);
      console.log('this.data', this.data);

      this.encrypted_UUID = this.encryptData(this.data, this.iv, this.key);
      console.log(this.encrypted_UUID);//genrated encryption String:  swBX2r1Av2tKpdN7CYisMg==

      var decrypteddata = this.decryptData(this.encrypted_UUID, this.iv, this.key);
      console.log(decrypteddata);//genrated decryption string:  Example1
      this.callDeviceApi();
    });
  }




  callDeviceApi() {
    let alloweddevice = this.streamInfo.plan.allowed_device;
    let allowedOs = this.streamInfo.plan.allowed_os;
    let obj: any = {
      catalogue: CatlougeTypeConstants.catlogue,
      session_id: this.sessionId,
      code: this.currentPlayingCode,
      device_id: this.encrypted_UUID,
      device_type: 'computer',
      date: Date.now(),
      plan: {
        allowed_device: alloweddevice,
        allowed_os: allowedOs
      }
    }

    let urlEncodedBodyData = this.UserService.convertoJSONToUrlEncoded(obj)
    urlEncodedBodyData = JSON.stringify(obj);
    console.log(urlEncodedBodyData);
    console.log(obj);

    let allowsArr = this.streamInfo.plan.allowed_os;
    if (allowsArr != "" && allowsArr != undefined && allowsArr.length > 0) {
      if (allowsArr.includes('computer')) {
        this.deviceApiService.checkAllowedDevices('device', urlEncodedBodyData).subscribe(
          (res) => {
            console.log('device api called');
            this.errmsg = res.msg
          },
          (err) => {
            console.log(err.status);
            if (err.status == 400) {
              clearInterval(this.interval_ID);
              // this.removeFromContinueWatch(this)
              this.disableModal();
              if (this.playermodal) this.playermodal.dispose();
              this.shouldCloseAfterPersent = false;
              this.onModalClose.emit(err.error.msg);
            }

          })

      } else {

        clearInterval(this.interval_ID);
        // this.removeFromContinueWatch(this)
        this.disableModal();
        if (this.playermodal) this.playermodal.dispose();
        this.shouldCloseAfterPersent = false;
        this.onModalClose.emit("This device is not allowed for streaming in your existing plan. Kindly upgrade your plan in case you wish to watch on this device.");
      }
    }

  }

  playAudioWithAPI(urlEncodedStreamInfo) {
    $('body').removeClass("modal-open");
    this.VideoPlayerService.playUrl(urlEncodedStreamInfo, this.is_trailer, this.is_live_evnet).subscribe(
      (playObj) => {
        let noerror = 1;
        this.watch_credit_status = 1;
         //this.playing_track_details = playObj;
        console.log('this.playing_track_detailsthis.playing_track_detailsthis.playing_track_details',this.playing_track_details);
        
        if (playObj.result == undefined || playObj.result == "" || Object.keys(playObj.result).length === 0) {
          // this.onModalClose.emit('close');
          // alert(playObj.error_string)
          if (playObj.error_string) {

            const modalRef = this.modalService.open(AlertModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
            let component_instance: any = (<AlertModalComponent>modalRef.componentInstance);
            component_instance.deviceMsg = playObj.error_string;
            component_instance.code = this.streamInfo.type=='audio' ? this.streamInfo.code : this.currentPlayingCode;
            component_instance.modalRef = modalRef;

            //  this.router.navigate(["subscribe/choseplan"], {
            //   queryParams: { code: this.currentPlayingCode },
            // });
          }

          this.disableModal();
          return false;
        }

        this.launchAudioPlayer(playObj);
      }
    );
  }

  launchAudioPlayer(playObj) {
    this.playUrlResObj = playObj;
    this.playing_track_details = playObj;
    this.recommandation_album_details.image = '';
    setTimeout(() => {
      this.recommandation_album_details.image_square_cloudfront_url = this.playUrlResObj.assetData.image_square_cloudfront_url;
      this.recommandation_album_details.image = this.playUrlResObj.assetData.image ? this.playUrlResObj.image : this.playUrlResObj.assetData.image_cloudfront_url;
    }, 1000);
    if (playObj.assetData.type == 'pc_episode') {
      if (playObj.next_episode && playObj.next_episode.code != undefined) {
        this.nextVideoCode = playObj.next_episode.code;
        this.nextAudioPaidData = playObj.next_episode;
      }

      if (playObj.next_episode && playObj.prev_episode.code != undefined) {
        this.preVideoCode = playObj.prev_episode.code;
      }

      // var activity_d: any = {
      //   currentCode: playObj.assetData.code,
      //   is_pause: true,
      //   is_podcst: true
      // }

      // this.checkTrackActivity.emit(activity_d);
    }



    if (this.is_preview) {
      if (playObj.assetData.cloud_front && Object.keys(playObj.assetData.cloud_front).length > 0) {
        this.audioPlayUrl = playObj.assetData.cloud_front.audio_url;

        this.setCookie("CloudFront-Key-Pair-Id", playObj.assetData.cloud_front.cloudfront_key_pair_id.trim(), 1);
        this.setCookie("CloudFront-Policy", playObj.assetData.cloud_front.cloudfront_policy.trim(), 1);
        this.setCookie("CloudFront-Signature", playObj.assetData.cloud_front.cloudfront_signature.trim(), 1);
      }
    } else {
      if (playObj.cloud_front && Object.keys(playObj.cloud_front).length > 0) {
        this.audioPlayUrl = playObj.cloud_front.audio_url;

        this.setCookie("CloudFront-Key-Pair-Id", playObj.cloud_front.cloudfront_key_pair_id.trim(), 1);
        this.setCookie("CloudFront-Policy", playObj.cloud_front.cloudfront_policy.trim(), 1);
        this.setCookie("CloudFront-Signature", playObj.cloud_front.cloudfront_signature.trim(), 1);
      } else {
        this.audioPlayUrl = playObj.result;
      }
    }

    ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

    if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {

      this.title = '<div class="audio_title" id="audiotitle">' +
        '<div>' + playObj.assetData.show_details.show_title + '</div>' +
        '<span class="audio_season_episode">' + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
        '<span>' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

      this.title1 = playObj.assetData.show_details.show_title + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' +
        + playObj.assetData.show_details.episode_title;

    } else {
      playObj.assetData.highlighted_label = playObj.assetData.highlighted_label ? playObj.assetData.highlighted_label : ''
      this.title = '<div class="audio_title" id="audiotitle"><div><span>' + playObj.assetData.name + '</span> <p class="artist">' + playObj.assetData.highlighted_label + '</p></div></div>';
      this.title1 = (playObj.assetData.name || '');
    }

    if (playObj.assetData.advertisement_url != undefined && playObj.assetData.advertisement_url != "") {
      var advertisement_url = playObj.assetData.advertisement_url;
    } else {
      advertisement_url = "";
    }

    //this.audioPlayUrl = './assets/img/audio-test/test1h.mp3';
   // this.audioPlayUrl = './assets/img/audio-test/test5.mp3';
    let new_source = {
      sources: [{ src: this.audioPlayUrl, type: "audio/mp3" }],
      controls: true,
      autoplay: true,
      withCredentials: true,
      crossDomain: true,
    };
    console.log('new_source', new_source);

    this.playermodal = videojs("pl_one", new_source);


    videojs.options.hls.overrideNative = true;
    videojs.options.html5.nativeAudioTracks = false;
    videojs.options.html5.nativeTextTracks = false;

    let imaOptions = {
      adTagUrl: advertisement_url, //'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=',
      disableAdControls: true,
      id: "videoAds",
    };

    this.playermodal.ima(imaOptions);
    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
    var startEvent = "click";

    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i)) {
      startEvent = "touchend";
    }

    let _this = this;
    this.playermodal.one(startEvent, function () {
      _this.playermodal.ima.initializeAdDisplayContainer();
    });

    this.playermodal.nuevo({
      audioInfo: true,
      // infoTitle: this.title,
      infoIcon: "",
      qualityMenu: true,
      pipButton: false,
      settingButton: true,
      //buttonRewind: true,
      //buttonForward: true,
      resume: true,
      // poster: playObj.assetData.image,
      errordisplay: false,
      shareMenu: false,
      contextMenu: false,
      // slideImage: "https://p8tst.s3.ap-south-1.amazonaws.com/thumb/rra/150/RRA_sprite.jpg",
      // slideType: 'vertical',
      // //slideWidth: 160,  //optional
      // //slideHeight: 90  //optional
      // logo: playObj.assetData.image,
    });

    //<li  id="track_settings"><a  href="javascript:void(0);"><img src="../../../assets/img/audio_setting.png" alt="" title="" />Settings</a></li>

    if (playObj.assetData.type == 'pc_episode') {
      var nextTitle = "Next-Episode";
      var preTitle = "Previous-Episode";
    } else {
      var nextTitle = "Next-Song";
      var preTitle = "Previous-Song";
    }

    let nextButton = '<div data-code=' + this.streamInfo.code + ' data-playlistId=' + this.streamInfo.playlist_id + ' class="audio_track_btn" id="AudioNextButton" title=' + nextTitle + '><i class="fa fa-step-forward"></i></div>';
    let repeatButton = '<div data-code=' + this.currentPlayingCode + ' data-playlistId=' + this.streamInfo.podcast_id + ' data-isPreview=' + this.is_preview + ' class="audio_track_btn2" id="AudioRepeatButton" title="Repeat"><i class="fa fa-retweet"></i></div>';
    let shuffleButton = '<div  class="audio_track_btn2" id="AudioShuffleButton" title="Shuffle"><i class="fa fa-random"></i></div>';
    let backButton = '<div class="audio_track_btn" id="AudioBackButton" title=' + preTitle + '><i class="fa fa-step-backward "></i></div>';
    let moreOptionsButton = '<a  class="audio_track_btn dropdown-toggle" id="MoreOptionsButton"  data-toggle="dropdown" title="More options"><i class="fa fa-ellipsis-v "></i></a>     <div class="dropdown-menu dropdown_option_position" aria-labelledby="option_dropdown"> <ul> <li id="track_more_info"><a  href="javascript:void(0);" ><img src="../../../assets/img/more_info.png" alt="" title="" />More Info</a></li> <li  id="track_settings"><a  href="javascript:void(0);"><img src="../../../assets/img/audio_setting.png" alt="" title="" />Settings</a></li> </ul> </div>';
    let fullScreenButton = '<div  class="audio_track_btn full_screen_btn" data-toggle="modal" data-target="#album_similar" id="AudioFullButton" title="Queue" ><i class="fa fa-angle-up"></i></div>';
    this.AudioClose = '<div class="" ><span class="audio_close" id="AudioClose" data-dismiss="modal" title="Close"> <img src="./assets/img/audio/close.png"></span></div>';

    $(".vjs-volume-panel").after(this.AudioClose);
    $(".vjs-play-control").after(nextButton);
    $(".vjs-play-control").before(backButton);


    $("#AudioBackButton").before(repeatButton);
    $("#AudioRepeatButton").before(moreOptionsButton);

    $("#AudioNextButton").after(shuffleButton);
    $(".vjs-volume-panel").after(fullScreenButton);

    if (playObj.assetData.type != 'audio' && playObj.assetData.type != 'pc_episode') {
      $("#AudioRepeatButton").css("visibility", "hidden");
      $("#MoreOptionsButton").css("visibility", "hidden");
      $("#AudioFullButton").css("visibility", "hidden");
      $("#AudioShuffleButton").css("visibility", "hidden");
      $(".full_screen_btn").css("visibility", "hidden");
      $('.vjs-control-bar').removeClass('podcastAudio');
    }

    if (playObj.assetData.type == 'pc_episode') {
      $("#AudioFullButton").css("visibility", "hidden");
      $("#AudioShuffleButton").css("visibility", "hidden");
      $('.vjs-control-bar').addClass('podcastAudio');
      $(".vjs-cog-button .vjs-menu-button .vjs-button").css("visibility", "visible");

      if (this.nextVideoCode == '') {
        $('#AudioNextButton').css("pointer-events", "none");
      }
      else {
        $("#AudioNextButton").css("pointer-events", "auto");
      }

      if (this.preVideoCode == '') {
        // $("#AudioBackButton").css("visibility", "hidden");
        $('#AudioBackButton').css("pointer-events", "none");
      }
      else {
        $("#AudioBackButton").css("pointer-events", "auto");
        //$("#AudioBackButton").css("visibility", "visible");
      }

      // $(".podcastAudio #AudioNextButton").addClass("surajTesst");
      // setTimeout(() => {
      //       $('.video-js .vjs-control-bar.podcastAudio .vjs-cog-menu-button').css({
      //         marginLeft:'-23%',
      //     });
      // }, 100);





      let preTime = Math.round(this.streamInfo.recently_watched.stop * 100 / this.streamInfo.recently_watched.duration) > 98 ? 0 : Math.round(this.streamInfo.recently_watched.stop);
      if (advertisement_url == "") {
        this.playermodal.one("loadedmetadata", function () {
          console.log("setting current time to 60 seconds " + preTime);
          _this.playermodal.currentTime(preTime);
        });
      }
    }


    // if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {
    //    $(".vjs-play-control").after(nextButton);
    //    $(".vjs-play-control").before(backButton);
    // }
    // $(".vjs-play-control").append(this.title);
    let AudioNextButton = document.getElementById("AudioNextButton");
    let AudioBackButton = document.getElementById("AudioBackButton");
    let MoreOptionsButton = document.getElementById("MoreOptionsButton");
    let track_more_info = document.getElementById("track_more_info");
    let track_settings = document.getElementById("track_settings");
    let AudioFullButton = document.getElementById("AudioFullButton");
    let AudioRepeatButton = document.getElementById("AudioRepeatButton");
    let AudioShuffleButton = document.getElementById("AudioShuffleButton");
    $("div.audio_title").remove();
    $("div.vjs-logo").remove();
    $("#MoreOptionsButton").before(this.title);

    playObj.assetData.image_square_cloudfront_url = playObj.assetData.image_square_cloudfront_url ? playObj.assetData.image_square_cloudfront_url : playObj.assetData.image_cloudfront_url
    let logo = '<div class="vjs-logo"><img src=' + playObj.assetData.image_square_cloudfront_url + '></div>';
    $("#audiotitle").before(logo);

    this.playermodal.onended = function () {

    }


    AudioNextButton.onclick = (event) => {

      if (playObj.assetData.type == 'pc_episode' && this.streamInfo.type != 'audio') {
        this.nextButtonAction(this.nextVideoCode);
      } else {
        this.streamInfo.current_index = this.streamInfo.current_index ? this.streamInfo.current_index : 0;
        console.log(this.streamInfo);
        // check if last track
        if (this.streamInfo.current_index + 1 != this.streamInfo.audio_list.length) {
          this.streamInfo.current_index = this.streamInfo.current_index + 1;
          //  last track
        } else if (this.streamInfo.current_index + 1 == this.streamInfo.audio_list.length) {
          this.streamInfo.current_index = 0;
        }
        let next_track_data = this.streamInfo.audio_list[this.streamInfo.current_index];
        console.log('next track', next_track_data);
        let is_track_pause = false;
        if (next_track_data.content_availability.toLowerCase() == 'paid' && next_track_data.is_pack_active != '1') {
          this.streamInfo.current_index = this.streamInfo.current_index - 1;
          is_track_pause = true;
        }
        console.log(next_track_data);
        var activity_data: any = {
          index: this.streamInfo.is_similar_track ? -1 : this.streamInfo.current_index,
          is_pause: is_track_pause,
          track_detail: next_track_data
        }
        let sub = this.activatedRoute.data.subscribe(v => {
          console.log(v);
          if(v.title == 'podcast') {
            this.checkTrackActivity.emit(activity_data)
          }
        });

        this.onTrackActivity.emit(activity_data);
       
        this.recommandation_album_details.image = next_track_data.content_availability.toLowerCase() == 'paid'
          && next_track_data.is_pack_active != '1' ? this.recommandation_album_details.image : '';
        this.getNexTrackData(next_track_data.code, true);
      }



    };



    AudioBackButton.onclick = (event) => {

      if (playObj.assetData.type == 'pc_episode' && this.streamInfo.type != 'audio') {
        this.backButtonAction(this.preVideoCode);
      } else {
        this.streamInfo.current_index = this.streamInfo.current_index ? this.streamInfo.current_index : 0;
        // check if first track
        if (this.streamInfo.current_index - 1 >= 0) {
          this.streamInfo.current_index = this.streamInfo.current_index - 1;
        } else if (this.streamInfo.current_index == 0) {
          this.streamInfo.current_index = this.streamInfo.audio_list.length - 1;
        }

        let next_track_data = this.streamInfo.audio_list[this.streamInfo.current_index];

        let is_track_pause = false;
        if (next_track_data.content_availability.toLowerCase() == 'paid') {
          this.streamInfo.current_index = this.streamInfo.current_index + 1;
          is_track_pause = true;
        }
        console.log(next_track_data);
        var activity_data: any = {
          index: this.streamInfo.is_similar_track ? -1 : this.streamInfo.current_index,
          is_pause: is_track_pause,
          track_detail: next_track_data
        }
        let sub = this.activatedRoute.data.subscribe(v => {
          console.log(v);
          if(v.title == 'podcast') {
            this.checkTrackActivity.emit(activity_data)
          }
        });
        this.onTrackActivity.emit(activity_data);
        this.recommandation_album_details.image = next_track_data.content_availability.toLowerCase() == 'paid'
          && next_track_data.is_pack_active != '1' ? this.recommandation_album_details.image : '';
        this.getNexTrackData(next_track_data.code, true);
      }

    };

    AudioRepeatButton.onclick = (event) => {
      console.log("testststststststst");
      if (this.streamInfo.is_playlist) {
        // remove shuffle if repeat active
        if ($('#AudioShuffleButton i').hasClass('shuffle_track')) {
          $("#AudioShuffleButton i").removeClass("shuffle_track");
          this.is_shuffle_mode = false;
        }
        if ($('#AudioRepeatButton i').hasClass('repeat_track') && $('#AudioRepeatButton i p').length) {

          $('#AudioRepeatButton i').find('p').remove();
          $("#AudioRepeatButton i").removeClass("repeat_track");
          this.is_repeat_mode = false;
          this.is_repeat_one_mode = false;
        } else if ($('#AudioRepeatButton i').hasClass('repeat_track') && !$('#AudioRepeatButton i p').length) {
          var txt2 = $("<p></p>").text("1");
          $("#AudioRepeatButton i").append(txt2);
          this.is_repeat_mode = false;
          this.is_repeat_one_mode = true;
        }
        else {
          $("#AudioRepeatButton i").addClass("repeat_track");
          this.is_repeat_mode = true;
          this.is_repeat_one_mode = false;
        }
      } else if (!this.streamInfo.is_playlist) {
        console.log($('#AudioRepeatButton i').hasClass('repeat_track'));
        console.log($('#AudioRepeatButton i p').length);

        if ($('#AudioRepeatButton i').hasClass('repeat_track') && $('#AudioRepeatButton i p').length) {

          $('#AudioRepeatButton i').find('p').remove();
          $("#AudioRepeatButton i").removeClass("repeat_track");
          this.is_repeat_mode = false;
          this.is_repeat_one_mode = false;
        }
        else if (!$('#AudioRepeatButton i').hasClass('repeat_track') && !$('#AudioRepeatButton i p').length) {
          console.log('start repeat mode');
          $("#AudioRepeatButton i").addClass("repeat_track");
          var txt2 = $("<p></p>").text("1");
          $("#AudioRepeatButton i").append(txt2);
          this.is_repeat_mode = false;
          this.is_repeat_one_mode = true;
        }
      }


    }
    AudioShuffleButton.onclick = (event) => {
      console.log(this.streamInfo.is_playlist);

      if (this.streamInfo.is_playlist) {
        // remove repeat mode if shuffle
        if ($('#AudioRepeatButton i').hasClass('repeat_track')) {

          $('#AudioRepeatButton i').find('p').remove();
          $("#AudioRepeatButton i").removeClass("repeat_track");
          this.is_repeat_mode = false;
          this.is_repeat_one_mode = false;
        }
        if ($('#AudioShuffleButton i').hasClass('shuffle_track')) {
          $("#AudioShuffleButton i").removeClass("shuffle_track");
          this.is_shuffle_mode = false;
        } else {
          $("#AudioShuffleButton i").addClass("shuffle_track");
          this.is_shuffle_mode = true;
        }
      }
    }

    MoreOptionsButton.onclick = (event) => {
      $('#moreOptionsModal').modal('show')
    }
    track_more_info.onclick = (event) => {

      const modalRef = this.modalService.open(TrackInfoModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
      let component_instance: any = (<TrackInfoModalComponent>modalRef.componentInstance);
      console.log('this.next_track_api_data',this.next_track_api_data);
      console.log('this.playing_track_details',this.playing_track_details);
      
      component_instance.data = this.playing_track_details.assetData ? this.playing_track_details.assetData : this.playing_track_details.result;
      component_instance.modalRef = modalRef;
      console.log("checkkkkkkkkkkkkkkkkk",component_instance.data);

    }
    track_settings.onclick = (event) => {

      // const modalRef = this.modalService.open(TrackSettingsModalComponent, {ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
      // let component_instance:any = (<TrackSettingsModalComponent>modalRef.componentInstance);
      // component_instance.data = this.playing_track_details.result;
      // component_instance.modalRef = modalRef;

    }
    AudioFullButton.onclick = (event) => {
      // console.log(document.getElementById("maincontentarea").classList[1]);
      if ($('#AudioFullButton i').hasClass('fa-angle-up')) {
        $('#AudioFullButton i').removeClass('fa-angle-up');
        $('#AudioFullButton i').addClass('fa-angle-down');
       $("#maincontentarea").addClass("before_similar");
      } else {
        $('#AudioFullButton i').removeClass('fa-angle-down');
        $('#AudioFullButton i').addClass('fa-angle-up');
        $("#maincontentarea").removeClass("before_similar");
      }
      // document.getElementById("maincontentarea").classList.toggle("before_similar");
    }


    let CloseButton = document.getElementById("AudioClose");
    $(".audio_close").on("click", (event) => {
      this.onModalClose.emit('close');
      if (playObj.assetData.type == 'pc_episode') {
        this.stopAudio();
        var activity_d: any = {
          is_pause: true,
          is_podcst: true,
          is_preview: false,
          isAudioExist: true
        }

        this.checkTrackActivity.emit(activity_d);
        event.preventDefault();
      }
      clearInterval(this.interval_ID);
      setTimeout(() => { this.playermodal.dispose(), document.querySelector("ngb-modal-window").classList.remove("show") }, 50);
      document.getElementById("maincontentarea").classList.remove("before_similar");
      $('#album_similar').modal('hide');
      return false;
      // this.stopAudio();
    });

    // $(".full_screen_btn").on("click", (event) =>  {
    //   document.getElementById("maincontentarea").classList.toggle("before_similar"); 
    // });

    this.playermodal.on("timeupdate", () => {
      // console.log('time update');
      // console.log(this.streamInfo.type);



      let totalDuration = Math.round(this.playermodal.duration());
      let playedDuration = Math.round(this.playermodal.currentTime());
      let playingDuration = (playedDuration * 100) / totalDuration;
      // console.log('this.playermodal.duration()',this.playermodal.duration());
      // console.log('totalDuration',totalDuration);
      // console.log('this.playermodal.currentTime()',this.playermodal.currentTime());
      // console.log('this.playermodal.currentTime()',this.playermodal.currentTime()+2);

      // console.log(this.playermodal.currentTime()+2 >= this.playermodal.duration());

      // console.log('is_repeat_one_mode',this.is_repeat_one_mode);
      // console.log(this.streamInfo);
      // console.log(this.streamInfo.type);
      // console.log(playingDuration);

      if (this.streamInfo.type == 'vod' && (this.playermodal.currentTime() + 0.2 >= this.playermodal.duration())) {
        console.log('pause vod');

        this.playermodal.pause();
      }
      if (!this.is_repeat_one_mode && this.streamInfo.type == 'audio' && (playingDuration >= 99.5 || this.playermodal.currentTime() + 2 >= this.playermodal.duration()) || !this.is_repeat_one_mode && this.streamInfo.type == 'pc_episode' && playingDuration >= 99.4) {

        if (playObj.assetData.type == 'pc_episode' && this.streamInfo.type != 'audio') {
          if ((playedDuration * 100) / totalDuration > 99.2) {
            this.stopAudio();
          }
          if (this.is_repeat_mode) {
          } else {
            if (this.nextEpisodeIsBlank) {
              this.nextEpisodeIsBlank = false;
              if (this.nextVideoCode != '' && this.nextVideoCode != undefined) {
                this.nextButtonAction(this.nextVideoCode);
              } else {
                var activity_d: any = {
                  currentCode: "no1",
                  is_pause: true,
                  is_podcst: true,
                  is_preview: false,
                  isAudioExist: true
                }
                this.checkTrackActivity.emit(activity_d);
                setTimeout(() => { this.playermodal.dispose(), document.querySelector("ngb-modal-window").classList.remove("show") }, 50);
                this.removeFromContinueWatch(this.streamInfo);
              }

              setInterval(() => {
                this.nextEpisodeIsBlank = true;
              }, 10000);
            }
            //else{
            //   setTimeout(() => { this.playermodal.dispose(), document.querySelector("ngb-modal-window").classList.remove("show") }, 50);
            // }
          }

        }
        console.log('is_repeat_one_mode', this.is_repeat_one_mode);
        console.log('is_shuffle_mode', this.is_shuffle_mode);
        console.log('is_repeat_mode', this.is_repeat_mode);

        // for search component
        if (!this.streamInfo.current_index && this.streamInfo.current_index != 0) {
          this.streamInfo.current_index = 0;
        }
        console.log('streamInfo.current_index', this.streamInfo.current_index);
        // console.log('audio_list',this.streamInfo.audio_list);
        //when playing track is not last track
        if (this.is_preview) {

          this.playermodal.dispose();
          this.onModalClose.emit('close');
        }
        else if (!this.is_shuffle_mode && this.streamInfo.current_index != (this.streamInfo.audio_list.length - 1)) {
          console.log('is_shuffle_mode', 'no');
          console.log('last track', 'no');
          console.log('this.streamInfo.audio_list', this.streamInfo.audio_list);

          var next_track_data = this.streamInfo.audio_list[this.streamInfo.current_index + 1];
          console.log('next_track_data', next_track_data);
          console.log('this.next_track_api_data', this.next_track_api_data);

          if (!this.next_track_api_data || (this.next_track_api_data.assetData._id && next_track_data._id != this.next_track_api_data.assetData._id)) {

            this.getNexTrackData(next_track_data.code, false, true);
            console.log('next_track_api_dataaaaaaaaaaaaaaaaaaaaaaaaa', this.next_track_api_data);
          }
          //  when track is last
        } else if (!this.is_shuffle_mode && this.streamInfo.current_index == (this.streamInfo.audio_list.length - 1)) {
          console.log('is_shuffle_mode', 'no');
          console.log('last track', 'yes');
          console.log(this.playermodal.currentTime() + 0.3 >= this.playermodal.duration());


          if (!this.is_repeat_mode) {
            this.next_track_api_data = '';
            if (this.playermodal.currentTime() + 0.3 >= this.playermodal.duration()) {
              this.is_last_track = true;
              this.playermodal.pause();
              setTimeout(() => {
                this.is_last_track = false;
              }, 1000);
            }
          }


          var next_track_data = this.streamInfo.audio_list[0];
          if (this.is_repeat_mode && !this.next_track_api_data || (this.is_repeat_mode && this.next_track_api_data.assetData._id && next_track_data._id != this.next_track_api_data.assetData._id)) {
            this.getNexTrackData(next_track_data.code, false, true);
            console.log('next_track_api_data', this.next_track_api_data);
          }

        } else if (this.is_shuffle_mode) {
          console.log('is_shuffle_mode', 'yes');
          const randomIndex = this.randNum(this.streamInfo.audio_list, this.streamInfo.current_index);
          console.log('random number', randomIndex);

          // get random index value
          // const randomIndex = Math.floor(Math.random() * this.streamInfo.audio_list.length);

          var next_track_data = this.streamInfo.audio_list[randomIndex];
          console.log('next_track_data', next_track_data);
          this.getNexTrackData(next_track_data.code, false, true);
          // if(!this.next_track_api_data ||( this.next_track_api_data._id && next_track_data._id != this.next_track_api_data.assetData._id)) {
          // this.getNexTrackData(next_track_data.code);
          //   console.log('next_track_api_data',this.next_track_api_data);
          // }

        }
        // this.streamInfo.audio_list;
      }
      if ((this.playermodal.currentTime() + 1 >= this.playermodal.duration()) && this.streamInfo.type == 'audio' && this.next_track_api_data && !this.is_repeat_one_mode) {
        // alert(4)
        this.recommandation_album_details.image = '';
      }
      if ((playingDuration >= 99.99 || this.playermodal.currentTime() + 0.2 >= this.playermodal.duration()) && this.streamInfo.type == 'audio' && this.next_track_api_data && !this.is_repeat_one_mode) {
        // console.log('ended');
        for (var i = 0; i < this.streamInfo.audio_list.length; i++) {
          if (this.streamInfo.audio_list[i]._id === this.next_track_api_data.assetData._id) {
            this.streamInfo.current_index = i;
            break;
          }
        }
        // this.streamInfo.current_index = this.streamInfo.current_index + 1;
        // console.log('current_index',this.streamInfo.current_index);
        var activity_data: any = {
          index: this.streamInfo.is_similar_track ? -1 : this.streamInfo.current_index,
          is_pause: false,
          track_detail: this.streamInfo
        }
        let sub = this.activatedRoute.data.subscribe(v => {
          console.log(v);
          if(v.title == 'podcast') {
            this.checkTrackActivity.emit(activity_data)
          }
        });
        this.onTrackActivity.emit(activity_data);
        next_track_data = this.streamInfo.audio_list[this.streamInfo.current_index];
        // console.log('next_track_data',next_track_data);
        this.playNextAudio(this.next_track_api_data, next_track_data);

      }


    })

    let name = this.title1;
    let type = this.streamInfo.type;
    this.streamInfo.recently_watched = this.streamInfo.recently_watched ? this.streamInfo.recently_watched :
      (this.playing_track_details ? this.playing_track_details.result.recently_watched : '');

    console.log(this.playing_track_details);
 
    let code = this.streamInfo.recently_watched && this.streamInfo.recently_watched.code!='undefined' ? this.streamInfo.recently_watched.code : '';
    let action = 'Playing';
    this.playermodal.on("play", (event) => {
      console.log('play');
      console.log(this.streamInfo);
      if (playObj.assetData.type != 'pc_episode') {
        var activity_data: any = {
          index: this.streamInfo.is_similar_track ? -1 : this.streamInfo.current_index,
          is_pause: false,
          track_detail: this.streamInfo
        }
        this.onTrackActivity.emit(activity_data);
      } else {
        if (this.is_preview) {
          var activity_d: any = {
            currentCode: this.currentPlayingCode,
            is_pause: true,
            is_podcst: true,
            is_preview: true,
            isAudioExist: false,
            track_detail: this.streamInfo
          }
        } else {
          var activity_d: any = {
            currentCode: this.currentPlayingCode,
            is_pause: false,
            is_podcst: true,
            is_preview: false,
            isAudioExist: true,
            track_detail: this.streamInfo
          }
        }

        this.checkTrackActivity.emit(activity_d);
        this.stopAudio();
      }
      $('.progress_move_background').removeClass("animation-paused");
      this.action1 = 'start';
      action = 'Playing';
      this.googleEvent.eventEmitter('users_audio', 'users_audio', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);

    });

    if (this.streamInfo.recently_watched && this.streamInfo.recently_watched.duration!='undefined' &&  this.streamInfo.recently_watched.duration > 0) {
      this.playermodal.on("seeked", (event) => {
        this.action1 = 'seek';
        // this.stopAudio();

        if (playObj.assetData.type == 'pc_episode') {
          this.stopAudio();
        }
      });
    }

    this.playermodal.on("pause", (event) => {
      console.log('pause');
      console.log(this.streamInfo);
      if (playObj.assetData.type != 'pc_episode') {
        var activity_data: any = {
          index: this.streamInfo.is_similar_track ? -1 : this.streamInfo.current_index,
          is_pause: true,
          is_last_track: this.is_last_track
        }
        this.onTrackActivity.emit(activity_data);
      } else {
        this.stopAudio();
        if (this.is_preview) {
          var activity_d: any = {
            currentCode: this.currentPlayingCode,
            is_pause: true,
            is_podcst: true,
            checkIsPuse: false,
            is_preview: true,
            isAudioExist: false,
            track_detail: this.streamInfo
          }
        } else {
          var activity_d: any = {
            currentCode: this.currentPlayingCode,
            is_pause: true,
            is_podcst: true,
            checkIsPuse: false,
            is_preview: false,
            isAudioExist: true,
            track_detail: this.streamInfo
          }
        }
        this.checkTrackActivity.emit(activity_d);
      }




      $('.progress_move_background').addClass("animation-paused");
      action = 'Paused';
      this.action1 = 'paused';

      this.googleEvent.eventEmitter('users_audio', 'users_audio', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      // this.stopAudio();
    });

    this.playermodal.on("ended", (event) => {
      console.log("ended");
      action = 'Stopped';
      this.action1 = 'stop';

      this.googleEvent.eventEmitter('users_audio', 'users_audio', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      if (playObj.assetData.type == 'pc_episode') {
        this.stopAudio();
      }

    });
    this.playermodal.on("dispose", (event) => {
      this.jwPlayerService.closePlayer.next(true);
    });
    // this.playermodal.on("error", (event) => {
    //   console.error(event);
    // });

    //}, 500);


  }
  getNexTrackData(next_charge_code, is_playlist = false, is_mode = false) {
    let videoStreamObj: any = {
      nmode: "WIFI",
      pushId: "",
      ct: "1",
      build: 10000,
      charge_code: next_charge_code,
      appv: "1.0.00",
      push_date: "",
      air: "airip",
    };

    let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
    this.VideoPlayerService.playUrl(urlEncodedStreamInfo, this.is_trailer, this.is_live_evnet).subscribe(
      (playObj) => {
        if (playObj.result == undefined || playObj.result == "" || Object.keys(playObj.result).length === 0) {
          if (playObj.error_string && !this.is_paid_alert) {
            this.is_paid_alert = true;
            const modalRef = this.modalService.open(AlertModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
            let component_instance: any = (<AlertModalComponent>modalRef.componentInstance);
            component_instance.deviceMsg = playObj.error_string;
            component_instance.code = this.currentPlayingCode;
            component_instance.modalRef = modalRef;
            setTimeout(() => {
              this.is_paid_alert = false;
            }, 2000);

            // this.router.navigate(["subscribe/choseplan"], {
            //   queryParams: { code: next_charge_code },
            // });
          }
          var plyer = document.getElementById('pl_one');
          videojs(plyer).pause();
          // this.disableModal();
          return false;
        }

        // this.recommandation_album_details.image = '';
        // setTimeout(() => {
        //   this.recommandation_album_details.image_square_cloudfront_url = playObj.assetData.image_square_cloudfront_url;
        //   this.recommandation_album_details.image = playObj.assetData.image; 
        // }, 1000);
      
        console.log('playObj',playObj);
        
        this.playing_track_details = playObj;
        this.next_track_api_data = playObj;
        // alert(this.playermodal.currentTime())

        // if(this.playermodal.currentTime()+ 0.2 >= this.playermodal.duration()){
        //   alert(55)
        // }



        setTimeout(() => {
          this.recommandation_album_details.image_square_cloudfront_url = playObj.assetData.image_square_cloudfront_url;
          this.recommandation_album_details.image = playObj.assetData.image ? playObj.assetData.image : playObj.assetData.image_cloudfront_url;
        }, 1000);
        // for search component
        if (!this.streamInfo.audio_list.length) {
          this.next_track = playObj.assetData;
          this.streamInfo = playObj.assetData;
          this.streamInfo.audio_list = [];

        }

        if (is_playlist && !is_mode) {
          this.recommandation_album_details.image = '';
          for (var i = 0; i < this.streamInfo.audio_list.length; i++) {
            if (this.streamInfo.audio_list[i]._id === this.next_track_api_data.assetData._id) {
              this.streamInfo.current_index = i;
              break;
            }
          }
          // this.streamInfo.current_index = this.streamInfo.current_index + 1;
          console.log('current_index', this.streamInfo.current_index);

          if (this.streamInfo.current_index >= 0) {
            this.next_track = this.streamInfo.audio_list[this.streamInfo.current_index];
          }

          console.log('next_track_data', this.next_track);
          // return

          this.playNextAudio(this.next_track_api_data, this.next_track);
        } else if (!is_playlist && !is_mode) {
          this.playNextAudio(this.next_track_api_data, this.similar_track_data);
        }
      })
  }

  // audioRecommandationPlaylist() {
  //   let assetDetail = {
  //     "code":this.streamInfo.charge_code,
  //     "catlogue": CatlougeTypeConstants.catlogue,
  //     "platform": 'web',
  //     "type": 'playlist'
  //   }
  //   let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);
  //   this.VideoPlayerService.recommandationAudiolist(urlEncodedStreamInfo).subscribe(
  //     (data) => {
  //       // this.recommandation_audio_list = data.result;
  //       // this.audioRecommandationTrack(data.result);
  //     })
  // }
  audioRecommandationTrack(data) {
    console.log(data);

    let assetDetail = {
      "code": data.code,
      "catlogue": CatlougeTypeConstants.catlogue,
      "platform": 'web',
      "type": 'audio'
    }
    let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(assetDetail);
    this.VideoPlayerService.recommandationAudioTracks(urlEncodedStreamInfo).subscribe(
      (data) => {
        console.log(data);

        this.recommandation_audio_list = data.result;
        // in search case
        if (this.streamInfo.audio_list && !this.streamInfo.audio_list.length) {
          this.streamInfo.audio_list = this.recommandation_audio_list;
        }
      })
  }

  addToPlaylist(data) {
    const modalRef = this.modalService.open(AddToPlaylistModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
    let component_instance: any = (<AddToPlaylistModalComponent>modalRef.componentInstance);
    component_instance.data = data;
    component_instance.modalRef = modalRef;
  }
  openShareModal() {
    const modalRef = this.modalService.open(ShareModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
    let component_instance: any = (<ShareModalComponent>modalRef.componentInstance);
    component_instance.share_url_link = this.recommandation_album_details.share_url;
    component_instance.shared_data = this.recommandation_album_details;
    component_instance.modalRef = modalRef;
  }
  playSimilarAudio(track_data, index) {
    console.log('simlar track data', track_data);
    let content_availability = track_data.content_availability.toLowerCase();
    this.streamInfo = track_data;
    this.streamInfo.current_index = index;
    this.similar_track_data = track_data;
    this.streamInfo.is_similar_track = true;
    // if (content_availability != 'paid') {
      this.recommandation_album_details.image = '';
    // }
    this.getNexTrackData(track_data.code);
    this.streamInfo.audio_list = this.recommandation_audio_list;




  }
  playNextAudio(api_data, next_track_data) {
    console.log('next_track_data', next_track_data);
    console.log('api_data', api_data);
    this.recommandation_album_details.thumbnail = api_data.assetData.image_square_cloudfront_url ? api_data.assetData.
      image_square_cloudfront_url : api_data.assetData.image_cloudfront_url;

    this.recommandation_album_details.image_square_cloudfront_url = api_data.assetData.image_square_cloudfront_url ? api_data.assetData.
      image_square_cloudfront_url : api_data.assetData.image;
      
    this.recommandation_album_details.image = api_data.assetData.image ? api_data.assetData.
      image : api_data.assetData.image_square_cloudfront_url;

    this.recommandation_album_details.share_url = next_track_data.share_url;
    this.recommandation_album_details.code = next_track_data.code;
    this.recommandation_album_details.name = next_track_data.name;
    this.streamInfo._id = next_track_data._id;
    this.recommandation_album_details.highlighted_label = next_track_data.highlighted_label ? next_track_data.highlighted_label : '';
    console.log(this.recommandation_album_details.name);
    console.log("just testtttttt",this.recommandation_album_details);


    if (api_data.cloud_front && api_data.cloud_front.audio_url) {
      this.nextAudio = api_data.cloud_front.audio_url;
      // this.nextAudio  = './assets/img/audio-test/test3.mp3';
      var sources = [
        { type: "audio/mpeg", src: this.nextAudio },
      ];
      next_track_data.highlighted_label = next_track_data.highlighted_label ? next_track_data.highlighted_label : '';
      api_data.assetData.image_square_cloudfront_url = api_data.assetData.image_square_cloudfront_url ? api_data.assetData.image_square_cloudfront_url : api_data.assetData.image_cloudfront_url;
      this.title = '<div class="audio_title" id="audiotitle"><div><span>' + next_track_data.name + '</span> <p>' + next_track_data.highlighted_label + '</p></div></div>';
      let logo = '<div class="vjs-logo" ><img src="' + api_data.assetData.image_square_cloudfront_url + '"></div>';
      $("div.audio_title").remove();
      $("div.vjs-logo").remove();
      console.log('title', this.title);

      $("#MoreOptionsButton").before(this.title);
      $("#audiotitle").before(logo);

      // this.playermodal.nuevo({ logo: 'https://res.cloudinary.com/samatva/image/upload/v1658296247/1658296242.jpg' });
      // $(".vjs-audio").html(this.title);
      // event.stopPropagation();
      // event.preventDefault();

      let advertisement_url = "";
      this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
      this.playermodal.ima.requestAds();

      let assetDetail = {
        "code": next_track_data.code,
        "catlogue": CatlougeTypeConstants.catlogue,
        "platform": 'web',
        "type": 'audio'
      }
      let urlEncodedStreamInfo2 = this.UserService.convertoJSONToUrlEncoded(assetDetail);
      // use this code for play animation on playlist page
      $('#AudioNextButton').attr('data-code', next_track_data.code);

      $('#AudioNextButton').attr('data-playlistId', this.streamInfo.playlist_id);
      let nextTitle = "Next-Song";
      let preTitle = "Previous-Song";
      $('#AudioNextButton').attr('title', nextTitle);
      $('#AudioBackButton').attr('title', preTitle);
      $(".vjs-cog-button").css("visibility", "hidden");
      $("#AudioBackButton").css("pointer-events", "auto");
      if (api_data.assetData.type == 'audio') {
        $("#AudioRepeatButton").css("visibility", "visible");
        $("#MoreOptionsButton").css("visibility", "visible");
        $("#AudioFullButton").css("visibility", "visible");
        $("#AudioShuffleButton").css("visibility", "visible");
        $(".full_screen_btn").css("visibility", "visible");
      }

      this.playAudioPreview(urlEncodedStreamInfo2, false);

      this.audioRecommandationTrack(next_track_data);

      if (!this.streamInfo.is_playlist) {
        if ($('#AudioShuffleButton i').hasClass('shuffle_track')) {
          $("#AudioShuffleButton i").removeClass("shuffle_track");
          this.is_shuffle_mode = false;
        }

        $('#AudioRepeatButton i').find('p').remove();
        $("#AudioRepeatButton i").removeClass("repeat_track");
        this.is_repeat_mode = false;
        this.is_repeat_one_mode = false;
      }

    }
  }

  randNum(arr, excludeNum) {
    var randNumber = Math.floor(Math.random() * arr.length);
    if (randNumber == excludeNum) {
      return this.randNum(arr, excludeNum);
    } else {
      console.log('randNumber', randNumber);

      return randNumber;
    }
  }
  playAudioPreview(urlEncodedStreamInfo, is_preview) {
    // $('body').removeClass("modal-open");
    this.VideoPlayerService.playAudioUrl(urlEncodedStreamInfo).subscribe(
      (playObj) => {
        console.log(playObj);
        this.playing_track_details = playObj;
        console.log(this.streamInfo);

        if (playObj.result.type == 'pc_episode') {
          this.currentPlayingCode = playObj.result.code
          var activity_d: any = {
            currentCode: this.currentPlayingCode,
            is_pause: true,
            is_podcst: true,
            is_preview: true,
            isAudioExist: false,
            track_detail: this.streamInfo
          }

          this.checkTrackActivity.emit(activity_d);
        }

        this.streamInfo.type = this.playing_track_details.result.type;
        if (is_preview) {
          let noerror = 1;
          this.watch_credit_status = 1;
          if (playObj.result == undefined || playObj.result == "" || Object.keys(playObj.result).length === 0) {
            if (playObj.error_string) {
              this.router.navigate(["subscribe/choseplan"], {
                queryParams: { code: this.currentPlayingCode },
              });
            }
            this.disableModal();
            return false;
          }

          if (!this.playermodal && is_preview) {
            console.log("not old player");
            playObj.assetData = playObj.result;
            this.launchAudioPlayer(playObj)
          } else {
            console.log("old player");

            if (playObj.result.cloud_front && Object.keys(playObj.result.cloud_front).length > 0) {
              this.audioPlayUrl = playObj.result.cloud_front.audio_url;
              this.setCookie("CloudFront-Key-Pair-Id", playObj.result.cloud_front.cloudfront_key_pair_id.trim(), 1);
              this.setCookie("CloudFront-Policy", playObj.result.cloud_front.cloudfront_policy.trim(), 1);
              this.setCookie("CloudFront-Signature", playObj.result.cloud_front.cloudfront_signature.trim(), 1);
            } else {
              this.audioPlayUrl = playObj.result;
            }
            console.log('audioPlayUrl', this.audioPlayUrl);
            //this.audioPlayUrl = './assets/img/audio-test/test3.mp3';
            var sources = [
              { type: "audio/mpeg", src: this.audioPlayUrl },
            ];
            console.log(this.playing_track_details);

            this.playing_track_details.result.highlighted_label = this.playing_track_details.result.highlighted_label ? this.playing_track_details.result.highlighted_label : '';
            this.playing_track_details.result.image_square_cloudfront_url = this.playing_track_details.result.image_square_cloudfront_url ? this.playing_track_details.result.image_square_cloudfront_url : this.playing_track_details.result.image_cloudfront_url;
            this.title = '<div class="audio_title" id="audiotitle"><div><span>' + this.playing_track_details.result.name + '</span> <p>' + this.playing_track_details.result.highlighted_label + '</p></div></div>';
            let logo = '<div class="vjs-logo" ><img src="' + this.playing_track_details.result.image_square_cloudfront_url + '"></div>';
            $("div.audio_title").remove();
            $("div.vjs-logo").remove();
            console.log('title', this.title);
            $("#MoreOptionsButton").before(this.title);
            $("#audiotitle").before(logo);
            let advertisement_url = '';
            this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
            this.playermodal.ima.requestAds();
          }
        }
      }
    );
  }

  stopAudio() {
    let cs_uri_query = this.playUrlResObj.result.split("?")[1];
    let cs_uri_stem = this.playUrlResObj.result.split("?")[0];
    let videoLogData = {
      "x-app": this.streamInfo.type == "livetv" ? "livetv" : "vod",
      "x-event": this.action1,
      "sc-bytes": Math.round(this.playermodal.currentTime()),
      "c-user-agent": "platform/web/PlayerVersion-2.7.3",
      "x-duration": this.streamInfo.recently_watched.duration,
      "x-sname": this.currentPlayingName,
      "cs-uri-query": cs_uri_query
        ? encodeURIComponent(cs_uri_query.toString())
        : "",
      "cs-uri-stem": cs_uri_stem,
    };
    setTimeout(() => {
      this.spinner.hide();
    }, 10);

    let urlEncodedEncodeParams =
      this.UserService.convertoJSONToUrlEncoded(videoLogData);
    this.jwPlayerService
      .analyticsLog(urlEncodedEncodeParams)
      .subscribe((res) => { });

    console.log(Math.round(this.playermodal.duration()));
    let totalDuration = Math.round(this.playermodal.duration());
    let playedDuration = Math.round(this.playermodal.currentTime());
    let playingDuration = (playedDuration * 100) / totalDuration;

    let playLogReqdata = Analyticsstreaminglog.logData;
    playLogReqdata.platform = "web";
    playLogReqdata.code = this.currentPlayingCode;
    playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
    playLogReqdata.play_url = this.audioPlayUrl;
    playLogReqdata.asset_code = this.currentPlayingCode;
    playLogReqdata.category = this.currentPlayingCode;
    playLogReqdata.code = this.currentPlayingCode;

    playLogReqdata["show_id"] = this.streamInfo.type == 'pc_episode' ? "" : this.currentPlayingShowID;
    playLogReqdata["season_id"] = this.streamInfo.type == 'pc_episode' ? "" : this.currentPlayingSeasonId;

    if (this.currentPlayingShowID == "") {
      playLogReqdata["show_id"] = this.streamInfo.type == 'pc_episode' ? "" : this.streamInfo.recently_watched.show_id;
    }
    if (this.currentPlayingSeasonId == "") {
      playLogReqdata["season_id"] = this.streamInfo.type == 'pc_episode' ? "" : this.streamInfo.recently_watched.season_id;
    }
    playLogReqdata.stop = Math.round(this.playermodal.currentTime());
    // playLogReqdata.stop = Math.round(this.playermodal.duration())-3;
    playLogReqdata["duration"] = Math.round(this.playermodal.duration());
    let Playerduration = Math.round(this.playermodal.duration());
    playLogReqdata.charge_code = this.currentPlayingCharegCode;
    playLogReqdata["last_video_play_id"] = this.currentPlayingMongoId; //this.streamInfo.asset_mongo_id;
    playLogReqdata["release_date"] = this.streamInfo.release_date
      ? this.streamInfo.release_date
      : "";
    playLogReqdata["name"] = this.currentPlayingName; //this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
    playLogReqdata["cloud_image"] = this.currentPlayingThumb; //this.streamInfo.thumbnail;
    playLogReqdata["content_availability"] =
      this.currentPlayingContentAvailability; //this.streamInfo.content_availability;
    playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;

    playLogReqdata["sec"] = this.currentPlayingSec;
    playLogReqdata["min"] = this.currentPlayingMin;
    playLogReqdata["hours"] = this.currentPlayingHour;
    playLogReqdata["genre_text"] = this.currentPlayingGenreText;
    playLogReqdata["languages"] = this.currentPlayingLanguages;
    playLogReqdata["genre"] = this.currentPlayingGenre;
    playLogReqdata["synopsis"] = this.currentPlayingSys;
    // playLogReqdata['show_id'] = this.streamInfo.recently_watched.show_id;
    // playLogReqdata['season_id'] = this.streamInfo.recently_watched.season_id;
    //playLogReqdata['duration'] = this.currentPlayingDuration;

    if (
      playLogReqdata["duration"] == undefined ||
      playLogReqdata["duration"] == "undefined"
    ) {
      playLogReqdata["duration"] = this.currentPlayingDuration;
    }

    if (playLogReqdata.stop > playLogReqdata["duration"]) {
      playLogReqdata.stop = playLogReqdata["duration"] - 1;
    }
    playLogReqdata.start = 0;
    playLogReqdata.mobile = this.userInfo.result.mobile;
    playLogReqdata["type"] = this.streamInfo.type;
    playLogReqdata["uid"] = this.sessionId;
    if (this.streamInfo.type == 'pc_episode') {
      playLogReqdata["pc_season_id"] = this.currentPlayingSeasonId == "" ? this.streamInfo.recently_watched.season_id : this.currentPlayingSeasonId;
      playLogReqdata["podcast_id"] = this.currentPlayingShowID == "" ? this.streamInfo.recently_watched.show_id : this.currentPlayingShowID;
    }




    setTimeout(() => {
      this.spinner.hide();
    }, 10);


    let urlEncodedPlayData =
      this.UserService.convertoJSONToUrlEncoded(playLogReqdata);

    if ((playedDuration * 100) / totalDuration > 99.2) {
      this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res) => { });
    }
    if (playingDuration <= 99 && playedDuration > 1) {
      if ((playedDuration * 100) / totalDuration <= 99) {
        this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res) => { });
      }
    }

  }

  disableModal() {
    try {
      setTimeout(() => { if (this.playermodal) this.playermodal.dispose(); this.subscription.unsubscribe(); }, 0);
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById("nexgTV-app").click();
      document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());
      document.querySelectorAll(".d-block").forEach((el) => el.remove());
      document.body.style.overflow = "scroll";



    } catch (e) {
      console.log(e);
    }
  }

  videoLableButtonRander() {
    console.log(this.streamInfo);
    let skip_cast_button = `<button class="skip_cast_button btn btn-primary" id="skip_cast_button" type="button" title="Skip Intro" aria-disabled="false">
    </button>`;
    let content_advisory = this.playUrlResObj.assetData.content_rating.content_advisory ?
      this.playUrlResObj.assetData.content_rating.content_advisory
      : '';
    let viewer_rating = this.playUrlResObj.assetData.content_rating.viewer_rating ?
      this.playUrlResObj.assetData.content_rating.viewer_rating
      : '';

    let uARating_button = `<button class="uaRationOnPLayer btn btn-primary" id="uaRationOnPLayer" type="button" title="Skip Cast" aria-disabled="false">

    ${'<span class="rating_underline">' + 'RATED ' + viewer_rating + '</span>'
      + '<div>' + content_advisory + '<div>'}
    </button>`;

    $(".vjs-big-play-button").after(skip_cast_button);

    $(".vjs-user-inactive").toggleClass('uaRationOnPLayer');


    if (this.playUrlResObj.assetData.content_rating) {
      $(".vjs-big-play-button").after(uARating_button);
      let uiRatingTimeout = this.playUrlResObj.assetData.content_rating.show_duration * 1000;
      setTimeout(() => {
        console.log(uiRatingTimeout);
        $("#uaRationOnPLayer").remove();
      }, uiRatingTimeout);
    }


    $("#skip_cast_button").css({
      background: "rgb(2 2 2 / 100%)",
      width: "130px",
      top: "50%",
      right: "12%",
      cursor: "pointer",
      "z-index": "3",
      position: "absolute",
      display: "none",
    });
  }

  preNextVideo() {
    let totalSeasons = Object.keys(this.streamInfo.seasons).length;

    let seasonArray = [];
    if (totalSeasons > 0) {
      //if (totalSeasons > 1) {
      seasonArray = [];
      for (let i = 0; i < totalSeasons; i++) {
        // add episode number
        if (Object.keys(this.streamInfo.seasons[i].assets).length > 0) {
          for (
            let k = 0;
            k < Object.keys(this.streamInfo.seasons[i].assets).length;
            k++
          ) {
            this.streamInfo.seasons[i].assets[k].episode_number = k + 1;
          }
        }
        seasonArray = [...seasonArray, ...this.streamInfo.seasons[i].assets]; //Object.assign(seasonArray, this.streamInfo.seasons[i].assets);
      }
      this.seasonarray = seasonArray;


      // find code in array index
      let index = seasonArray.findIndex(
        (seasonArray) => seasonArray.code == this.currentPlayingCode
      ); //


      if (seasonArray[index + 1] == undefined) {
        this.nextVideoCode = "";
        this.nextVideoName = "";
        this.nextEpisodeNumber = "";
        this.nextSeasonNumber = "";
        this.nextPlayingStartTime = 0;
        this.nextPlayingMongoId = "";
        this.nextPlayingCharegCode = "";
        this.nextPlayingContentAvailability = "";
        this.nextPlayingThumb = "";
        this.nextPlayingSec = "";
        this.nextPlayingMin = "";
        this.nextPlayingHour = "";
        this.nextPlayingGenreText = "";
        this.nextPlayingGenre = "";
        this.nextPlayingSys = "";
        this.nextPlayingLanguages = "";
        this.nextPlayingStop = "";
        this.nextPlayingDuration = "";
        this.nextPlayingShowID = "";
        this.nextPlayingSeasonId = "";
        this.nextVideoDuration = "";
      } else {
        this.nextVideoCode = seasonArray[index + 1].code;

        this.nextVideoName = seasonArray[index + 1].name;
        this.nextEpisodeNumber = " E" + seasonArray[index + 1].episode_number;
        this.nextSeasonNumber = " S" + seasonArray[index + 1].season_number;
        this.nextPlayingStartTime = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingMongoId = seasonArray[index + 1].asset_mongo_id;
        this.nextPlayingCharegCode = seasonArray[index + 1].charge_code;
        this.nextPlayingContentAvailability =
          seasonArray[index + 1].content_availability;
        this.nextPlayingThumb = seasonArray[index + 1].thumbnail;

        this.nextPlayingSec = seasonArray[index + 1].sec;
        this.nextPlayingMin = seasonArray[index + 1].min;
        this.nextPlayingHour = seasonArray[index + 1].hour;
        this.nextPlayingGenreText = seasonArray[index + 1].genre_text;
        // this.nextPlayingGenre = seasonArray[index + 1].genre[0];
        this.nextPlayingLanguages = seasonArray[index + 1].languages;
        this.nextPlayingSys = seasonArray[index + 1].synopsis;
        this.nextPlayingStop = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingDuration =
          seasonArray[index + 1].seek_bar_data.duration;

        this.nextPlayingShowID = seasonArray[index + 1].show_id;
        this.nextPlayingSeasonId = seasonArray[index + 1].season_id;

        if (seasonArray[index + 1].hours > 0) {
          if (seasonArray[index + 1].hours < 10) {
            let h = seasonArray[index + 1].min;
            this.nextVideoDuration = h.replace(/^0+/, "") + " h";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].hours + "h";
          }

          if (seasonArray[index + 1].min > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].min +
              "m";
          }
        } else if (
          seasonArray[index + 1].hours <= 0 &&
          seasonArray[index + 1].min > 0
        ) {
          if (seasonArray[index + 1].min < 10) {
            let t = seasonArray[index + 1].min;
            this.nextVideoDuration = t.replace(/^0+/, "") + "m";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].min + "m";
          }

          if (seasonArray[index + 1].sec > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].sec +
              "s";
          }
        } else {
          this.nextVideoDuration =
            this.nextVideoDuration + " " + seasonArray[index + 1].sec + "s";
        }

        //let obj = seasonArray[index + 1];
      }
      if (seasonArray[index - 1] == undefined) {
        this.preVideoCode = "";
        this.preVideoName = "";
        this.preEpisodeNumber = "";
        this.preSeasonNumber = "";
        this.prePlayingStartTime = 0;
        this.prePlayingThumb = "";
        this.preVideoDuration = "";
      } else {
        this.preVideoCode = seasonArray[index - 1].code;
        this.preVideoName = seasonArray[index - 1].name;
        this.preEpisodeNumber = " E" + seasonArray[index - 1].episode_number;
        this.preSeasonNumber = " S" + seasonArray[index - 1].season_number;
        this.prePlayingStartTime = seasonArray[index - 1].seek_bar_data.stop;
        this.prePlayingThumb = seasonArray[index - 1].thumbnail;

        if (seasonArray[index - 1].hours > 0) {
          if (seasonArray[index - 1].hours < 10) {
            let h = seasonArray[index - 1].hours;
            this.preVideoDuration = h.replace(/^0+/, "") + "h";
          } else {
            this.preVideoDuration = seasonArray[index - 1].hours + "h";
          }

          if (seasonArray[index - 1].min > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].min + "m";
          }
        } else if (
          seasonArray[index - 1].hours <= 0 &&
          seasonArray[index - 1].min > 0
        ) {
          if (seasonArray[index - 1].min < 10) {
            let m = seasonArray[index - 1].min;
            this.preVideoDuration = m.replace(/^0+/, "") + "m";
          } else {
            this.preVideoDuration = seasonArray[index - 1].min + "m";
          }

          if (seasonArray[index - 1].sec > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
          }
        } else {
          this.preVideoDuration =
            this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
        }
      }
    }
    this.lastEpisode = seasonArray[seasonArray.length - 1];
  }

  startTimerFor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
    // this.timerOfPlayer = setInterval(()=>{
    //     // hit the Video End APi
    //     //this.stopVideo();
    //     this.timerForPlayer++;
    //     console.log(this.timerForPlayer);
    // }, 10000);
  }

  stopTimerfor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
  }

  removeFromContinueWatch(episodeObj) {
    console.log(episodeObj);
    let deleteConObj = {};
    deleteConObj["id"] = this.streamInfo.show_id
      ? this.streamInfo.show_id
      : this.currentPlayingMongoId; // this.streamInfo.asset_mongo_id,
    deleteConObj["type"] = this.audioType == "pc_episode" ? "pc_episode" : "vod";
    deleteConObj["catlogue"] = CatlougeTypeConstants.catlogue;
    deleteConObj["action"] = "delete";
    let urlEncodedeleteObj =
      this.UserService.convertoJSONToUrlEncoded(deleteConObj);

    if (this.audioType == "pc_episode") {
      if (this.nextVideoCode == undefined || !this.nextVideoCode || this.nextVideoCode == '') {
        console.log(`Clear All previous playlist because it's last episode`);

        setTimeout(() => {
          this.spinner.hide();
        }, 50);
        this.jwPlayerService
          .removeFromContinueWatching(urlEncodedeleteObj)
          .subscribe((res) => {
            console.log(`current playing series cleared`);
          });
      } else {
        if (episodeObj.nextVideObj != undefined) {

          console.log('video End api call for set SeekBar of current Playing');

          let playLogReqdata = Analyticsstreaminglog.logData;
          let newNextEpisodeObj = {
            ...playLogReqdata,
            ...episodeObj.nextVideObj,
          };
          delete newNextEpisodeObj.download_url;
          delete newNextEpisodeObj.seek_bar_data;
          delete newNextEpisodeObj.genre_text;
          delete newNextEpisodeObj.type_id;
          delete newNextEpisodeObj._id;

          newNextEpisodeObj["duration"] = this.currentPlayingDuration;
          newNextEpisodeObj["last_video_play_id"] = this.currentPlayingMongoId;
          newNextEpisodeObj["asset_mongo_id"] = this.currentPlayingMongoId;

          //newNextEpisodeObj.asset_cat_type = this.videoType;
          newNextEpisodeObj.uid = this.sessionId;
          newNextEpisodeObj.type =
            this.audioType == "pc_episode" ? "pc_episode" : "vod";
          newNextEpisodeObj.catlogue = CatlougeTypeConstants.catlogue;

          newNextEpisodeObj.asset_code = this.currentPlayingCode;
          newNextEpisodeObj.charge_code = this.currentPlayingCharegCode;
          newNextEpisodeObj.paid_status =
            this.currentPlayingContentAvailability;
          newNextEpisodeObj.content_availability =
            this.currentPlayingContentAvailability;

          newNextEpisodeObj.code = this.currentPlayingCode;
          newNextEpisodeObj.play_url = this.audioPlayUrl;
          newNextEpisodeObj.category = this.currentPlayingCode;
          newNextEpisodeObj.release_date = "";
          newNextEpisodeObj.name = this.title;
          newNextEpisodeObj.cloud_image = this.currentPlayingThumb;
          newNextEpisodeObj.sec = this.currentPlayingSec;
          newNextEpisodeObj.min = this.currentPlayingMin;
          newNextEpisodeObj.hours = this.currentPlayingHour;
          newNextEpisodeObj.genre_text = this.currentPlayingGenreText;
          newNextEpisodeObj.languages = this.currentPlayingLanguages;
          newNextEpisodeObj.genre = this.currentPlayingGenre;
          newNextEpisodeObj.synopsis = this.currentPlayingSys;
          newNextEpisodeObj.stop = this.currentPlayingDuration;

          newNextEpisodeObj.show_id = this.currentPlayingShowID;
          newNextEpisodeObj.season_id = this.currentPlayingSeasonId;

          console.log(newNextEpisodeObj);
          let urlEncodedNextObj =
            this.UserService.convertoJSONToUrlEncoded(newNextEpisodeObj);
          setTimeout(() => {
            this.spinner.hide();
          }, 50);
          console.log('remove from continue watching');
          this.jwPlayerService.pause(urlEncodedNextObj).subscribe((res) => {
            if (this.nextPlayingMongoId != undefined) {

              console.log('nextPlayingMongoId');
              newNextEpisodeObj["last_video_play_id"] = this.nextPlayingMongoId;
              newNextEpisodeObj["asset_mongo_id"] = this.nextPlayingMongoId;
              newNextEpisodeObj.asset_code = this.nextVideoCode;
              newNextEpisodeObj.paid_status =
                this.nextPlayingContentAvailability;
              newNextEpisodeObj.content_availability =
                this.nextPlayingContentAvailability;
              newNextEpisodeObj.charge_code = this.nextPlayingCharegCode;

              newNextEpisodeObj.code = this.nextVideoCode;
              newNextEpisodeObj.play_url = "";
              newNextEpisodeObj.category = this.nextVideoCode;
              newNextEpisodeObj.release_date = "";
              newNextEpisodeObj.name = this.nextVideoName;
              newNextEpisodeObj.cloud_image = this.nextPlayingThumb;
              newNextEpisodeObj.sec = this.nextPlayingSec;
              newNextEpisodeObj.min = this.nextPlayingMin;
              newNextEpisodeObj.hours = "00";
              newNextEpisodeObj.genre_text = this.nextPlayingGenreText;
              newNextEpisodeObj.languages = this.nextPlayingLanguages;
              newNextEpisodeObj.genre = this.nextPlayingGenreText;
              newNextEpisodeObj.synopsis = this.nextPlayingSys;
              newNextEpisodeObj.stop = this.nextPlayingStop;
              newNextEpisodeObj.duration = this.nextPlayingDuration;
              newNextEpisodeObj.show_id = this.nextPlayingShowID;
              newNextEpisodeObj.season_id = this.nextPlayingSeasonId;

              this.currentPlayingShowID = this.nextPlayingShowID;
              this.currentPlayingSeasonId = this.nextPlayingSeasonId;

              let urlEncodedNextObjNew =
                this.UserService.convertoJSONToUrlEncoded(newNextEpisodeObj);
              setTimeout(() => {
                this.spinner.hide();
              }, 50);
              this.jwPlayerService
                .pause(urlEncodedNextObjNew)
                .subscribe((res) => {
                  console.log('done')
                });
            }
          });
        }
      }
    } else {
      setTimeout(() => {
        this.spinner.hide();
      }, 50);
      this.jwPlayerService
        .removeFromContinueWatching(urlEncodedeleteObj)
        .subscribe((res) => {
          console.log("content remove");
        });
    }
  }

  nextButtonAction(param: any) {
    // this.stopVideo();
    let currentTime = this.nextPlayingStartTime;
    this.nextVideoDuration = '';
    this.preVideoDuration = '';
    console.log(this.nextVideoCode)
    this.getPlayUrl(this.nextVideoCode)
      .then((playObj) => {
        this.playType = "next";
        this.playUrlResObj = playObj;
        this.playing_track_details = playObj;
        //this.audioPlayUrl = './assets/img/audio-test/test2.mp3';
        var sources = [
          { type: "audio/mpeg", src: this.audioPlayUrl },
        ];

        //   this.playermodal.src(sources);
        //   this.playermodal.load();
        //  this.playermodal.play();
        //  this.playermodal.currentTime(this.nextPlayingStartTime);
        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();
        //this.playermodal.currentTime(currentTime);
        // var _this = this;
        // this.playermodal.one('loadedmetadata', function () {
        //   console.log('setting current time to 60 seconds');
        //   _this.playermodal.currentTime(0);
        // });  
        this.isHudredPersent = true;
        this.watch_credit_status = 1;
        // this.videoLableButtonRander();


      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
        console.log(error);
      });
  }
  backButtonAction(param: any) {
    //this.stopVideo();

    let preTime = this.prePlayingStartTime;
    this.getPlayUrl(this.preVideoCode)
      .then((playObj) => {
        this.playType = "pre";
        var sources = [
          { type: "audio/mpeg", src: this.audioPlayUrl },
        ];

        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();
        let name = this.title1;
        let type = this.streamInfo.type;
        let code = this.streamInfo.recently_watched.code;
        let timeInSeconds = this.playermodal.currentTime();
        // let action='Stopped';
        // this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code, 0, this.userUnique, this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code);
        // this.googleEvent.eventEmitter('users', 'users', this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code, 0, this.userUnique, this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code);
        this.googleEvent.eventEmitter('video_viewtime', 'video_viewtime', this.userUnique + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code);

        // this.playermodal.src(sources);
        // this.playermodal.load();
        // this.playermodal.play();

        // this.playermodal.currentTime(this.prePlayingStartTime);
        // var _this = this;
        // this.playermodal.one('loadedmetadata', function () {
        //   console.log('setting current time to 60 seconds');
        //   _this.playermodal.currentTime(0);
        // });
      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
      });
  }
  skipIntroButtonAction() {
    this.playermodal.currentTime(this.playUrlResObj.assetData.skip_intro.end);
  }

  videoLabelButtonAction(videoLabelObj) {
    if (videoLabelObj[0].is_watch_credit == "1") {
      this.watch_credit_status = 0;
      // this.videoLabelObj[0].is_watch_credit = 0;
      $(".skip_cast_button").css({ display: "none" });
      // $("#next_button").remove();
      // $("#back_button").remove();
      console.log(videoLabelObj);
    } else {
      this.playermodal.currentTime(videoLabelObj[0].end);
    }
  }

  getPlayUrl(code): Promise<any> {

    return new Promise((resolve, reject) => {
      this.rmContApiCalledStatus = false;
      let videoStreamObj = {
        nmode: "WIFI",
        pushId: "",
        ct: "1",
        build: 10000,
        charge_code: code,
        appv: "1.0.00",
        push_date: "",
        air: "airip",
      };

      let urlEncodedStreamInfo = this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
      console.log(urlEncodedStreamInfo);

      this.VideoPlayerService.playUrl(urlEncodedStreamInfo).subscribe((playObj) => {
        if (playObj.result == undefined || playObj.result == "") {
          this.nextEpisodeIsBlank = false;
          this.playermodal.pause();
          if (this.nextAudioPaidData.content_availability == "PAID") {
            this.currentPlayingCode = this.currentPlayingCode;
            // this.currentPlayingMongoId = this.nextAudioPaidData.asset_mongo_id;
            // this.currentPlayingCharegCode = this.nextAudioPaidData.charge_code;
            // this.currentPlayingContentAvailability = this.nextAudioPaidData.content_availability;
            // this.currentPlayingThumb = this.nextAudioPaidData.thumbnail;
            // this.currentPlayingName = this.nextAudioPaidData.name;

            // this.currentPlayingSec = this.nextAudioPaidData.sec;
            // this.currentPlayingMin = this.nextAudioPaidData.min;
            // let tempminsc = parseInt(this.nextAudioPaidData.min) * 60;
            // let temhourtosec = (parseInt(this.nextAudioPaidData.hours) * 60) * 60;
            // let totalDuration = parseInt(this.currentPlayingSec) + tempminsc + temhourtosec;
            // this.currentPlayingHour = this.nextAudioPaidData.hours;
            // this.currentPlayingLanguages = this.nextAudioPaidData.languages;
            // this.currentPlayingSys = this.nextAudioPaidData.synopsis;
            // this.currentPlayingShowID = this.nextAudioPaidData.show_id;
            // this.currentPlayingSeasonId = this.nextAudioPaidData.season_id;
            // let playLogReqdata = Analyticsstreaminglog.logData;

            // playLogReqdata.platform = "web";
            // playLogReqdata.code = this.currentPlayingCode;
            // playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
            // playLogReqdata.play_url = this.audioPlayUrl;
            // playLogReqdata.asset_code = this.currentPlayingCode;
            // playLogReqdata.category = this.currentPlayingCode;
            // playLogReqdata["show_id"] = this.currentPlayingShowID;
            // playLogReqdata["season_id"] = this.currentPlayingSeasonId;
            // playLogReqdata.charge_code = this.currentPlayingCharegCode;
            // playLogReqdata["last_video_play_id"] = this.currentPlayingSeasonId; //this.streamInfo.asset_mongo_id;
            // playLogReqdata["release_date"] = this.streamInfo.release_date ? this.streamInfo.release_date : "";
            // playLogReqdata["name"] = this.currentPlayingName; //this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
            // playLogReqdata["cloud_image"] = this.currentPlayingThumb; //this.streamInfo.thumbnail;
            // playLogReqdata["content_availability"] = this.currentPlayingContentAvailability; //this.streamInfo.content_availability;
            // playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;
            // playLogReqdata["sec"] = this.currentPlayingSec;
            // playLogReqdata["min"] = this.currentPlayingMin;
            // playLogReqdata["hours"] = this.currentPlayingHour;
            // playLogReqdata["genre_text"] = this.currentPlayingGenreText;
            // playLogReqdata["languages"] = this.currentPlayingLanguages;
            // playLogReqdata["genre"] = this.currentPlayingGenre;
            // playLogReqdata["synopsis"] = this.currentPlayingSys;
            // playLogReqdata.start = 0;
            // playLogReqdata.stop = 5;
            // playLogReqdata["duration"] = totalDuration;
            // playLogReqdata.mobile = this.userInfo.result.mobile;
            // playLogReqdata["type"] = this.streamInfo.type;
            // playLogReqdata["uid"] = this.sessionId;
            // if (this.streamInfo.type == 'pc_episode') {
            //   playLogReqdata["pc_season_id"] = this.currentPlayingSeasonId == "" ? this.streamInfo.recently_watched.season_id : this.currentPlayingSeasonId;
            //   playLogReqdata["podcast_id"] = this.currentPlayingShowID == "" ? this.streamInfo.recently_watched.show_id : this.currentPlayingShowID;
            // }
            // let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);
            // this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res) => { });
          }

          this.error = playObj.error_string;

          // this.showErrorMsg.nativeElement.click();
          // alert(this.showErrorMsg);

          // $(".modal-backdrop").css({ "z-index": "0" });
         
         
          var activity_d: any = {
            currentCode: 'no',
            is_pause: true,
            is_podcst: true,
            is_preview: false,
            isAudioExist: true,
            test:'test'
          }
          this.checkTrackActivity.emit(activity_d);
          if (this.error == 'You have no active pack to view this content.') {
            this.openErrorMsg.emit(this.error);
          }
         
          reject();
        } else {
          this.playUrlResObj = playObj;
          this.currentPlayingCode = code;
          if (playObj.assetData.type == 'pc_episode') {
            if (playObj.next_episode.code != undefined) {
              this.nextVideoCode = playObj.next_episode.code;
            } else {
              this.nextVideoCode = '';
            }
            if (playObj.prev_episode.code != undefined) {
              this.preVideoCode = playObj.prev_episode.code;
            } else {
              this.preVideoCode = '';
            }

            var activity_d: any = {
              currentCode: this.currentPlayingCode,
              is_pause: false,
              is_podcst: true
            }

            this.checkTrackActivity.emit(activity_d);
          }

          if (playObj.cloud_front && Object.keys(playObj.cloud_front).length > 0) {
            this.audioPlayUrl = playObj.cloud_front.audio_url;
           // this.audioPlayUrl = './assets/img/audio-test/test2.mp3';
            console.log(this.playUrlResObj);
            this.setCookie("CloudFront-Key-Pair-Id", playObj.cloud_front.cloudfront_key_pair_id.trim(), 1);
            this.setCookie("CloudFront-Policy", playObj.cloud_front.cloudfront_policy.trim(), 1);
            this.setCookie("CloudFront-Signature", playObj.cloud_front.cloudfront_signature.trim(), 1);
          } else {
            this.audioPlayUrl = playObj.result;
          }
          console.log(this.audioPlayUrl);
          // if (playObj.assetData.name != undefined)
          //   this.title = playObj.assetData.name;
          // else this.title = playObj.assetData.channel_name;

          if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {
            this.title = '<div id="show_temp">' +
              '<div class="temp-title">' + playObj.assetData.show_details.show_title + '</div>' +
              '<span class="temp-seasnon">' + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
              '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

          } else {
            this.title = '<div class="audio_title" id="audiotitle"><div><span>' + playObj.assetData.name + '</span> <p class="artist">' + playObj.assetData.highlighted_label + '</p></div></div>';
            let logo = '<div class="vjs-logo" ><img src="' + playObj.assetData.image_square_cloudfront_url + '"></div>';
            let repeatButton = '<div data-code=' + this.currentPlayingCode + ' data-playlistId=' + this.streamInfo.podcast_id + ' data-isPreview=' + this.is_preview + ' class="audio_track_btn2" id="AudioRepeatButton" title="Repeat"><i class="fa fa-retweet"></i></div>';
            $("div.audio_title").remove();
            $("div.vjs-logo").remove();
            $("div#AudioRepeatButton").remove();
            console.log('title', this.title);

            $("#MoreOptionsButton").before(this.title);
            $("#audiotitle").before(logo);
            $("#AudioBackButton").before(repeatButton);
            // $("#AudioRepeatButton").css("visibility", "visible");
            // $("#MoreOptionsButton").css("visibility", "visible");
            // $("#AudioFullButton").css("visibility", "visible");
            // $("#AudioShuffleButton").css("visibility", "visible");
            $("#AudioFullButton").css("visibility", "hidden");
            $("#AudioShuffleButton").css("visibility", "hidden");

            let AudioRepeatButton = document.getElementById("AudioRepeatButton");

            if (this.is_repeat_one_mode) {

              $("#AudioRepeatButton i").addClass("repeat_track");
              var txt2 = $("<p></p>").text("1");
              $("#AudioRepeatButton i").append(txt2);
              this.is_repeat_mode = false;
              this.is_repeat_one_mode = true;
            }

            AudioRepeatButton.onclick = (event) => {
              console.log("testststststststst");
              console.log($('#AudioRepeatButton i').hasClass('repeat_track'));
              console.log($('#AudioRepeatButton i p').length);
              if ($('#AudioRepeatButton i').hasClass('repeat_track') && $('#AudioRepeatButton i p').length) {

                $('#AudioRepeatButton i').find('p').remove();
                $("#AudioRepeatButton i").removeClass("repeat_track");
                this.is_repeat_mode = false;
                this.is_repeat_one_mode = false;
              }
              else if (!$('#AudioRepeatButton i').hasClass('repeat_track') && !$('#AudioRepeatButton i p').length) {
                console.log('start repeat mode');
                $("#AudioRepeatButton i").addClass("repeat_track");
                var txt2 = $("<p></p>").text("1");
                $("#AudioRepeatButton i").append(txt2);
                this.is_repeat_mode = false;
                this.is_repeat_one_mode = true;
              }
            }

            if (this.nextVideoCode == '') {
              $('#AudioNextButton').css("pointer-events", "none")
            }
            else {
              $('#AudioNextButton').css("pointer-events", "auto")
            }

            if (this.preVideoCode == '') {
              // $("#AudioBackButton").css("visibility", "hidden");
              $('#AudioBackButton').css("pointer-events", "none")
            }
            else {
              // $("#AudioBackButton").css("visibility", "visible");
              $('#AudioBackButton').css("pointer-events", "auto")
            }

          }
          //$(".vjs-audio").html(this.title);
          this.currentPlayingCode = code;

          this.playermodal.pause();
          this.playermodal.poster(playObj.assetData.image);
          if (playObj.assetData.type == 'pc_episode') {
            var obj = playObj.assetData;
          } else {

            var obj = this.seasonarray.find((seasonArray) => seasonArray.code == this.currentPlayingCode);
          }

          console.log("on next");
          console.log(obj);

          this.currentPlayingMongoId = playObj.assetData.type == 'pc_episode' ? playObj.assetData._id : obj.asset_mongo_id;
          this.currentPlayingCharegCode = obj.charge_code;
          this.currentPlayingContentAvailability = obj.content_availability;
          this.currentPlayingThumb = playObj.assetData.type == 'pc_episode' ? playObj.assetData.image_cloudfront_url : obj.thumbnail;
          this.currentPlayingName = obj.name;
          this.currentPlayingSec = obj.sec;
          this.currentPlayingMin = obj.min;
          this.currentPlayingHour = obj.hours;
          this.currentPlayingDuration = obj.duration;

          if (this.currentPlayingDuration == undefined) {
            this.currentPlayingDuration = obj.seek_bar_data.duration;
          }

          this.currentPlayingGenreText = obj.genre_text;
          // this.currentPlayingGenre = obj.genre[0];
          this.currentPlayingLanguages = obj.languages;
          this.currentPlayingSys = obj.synopsis;
          this.currentPlayingShowID = obj.show_id;
          this.currentPlayingSeasonId = obj.season_id;

          // logic complication
          // the below object hold the current object of the playing video
          // reason : On next button click we hold the current object in next or pre variable because of Videoend api required the data of current object
          // this.preNextVideo();
          resolve(playObj);
        }
      });
    });
  }

  removeErrorBox() {
    if (this.playermodal != "") {
      console.log("removeErrorBox");
      this.playermodal.currentTime(0);
      console.log(this.playermodal.currentTime());
      // this.stopAudio();
    }

    this.closeShowErrorMsg.nativeElement.click();
    setTimeout(() => {
      this.disableModal();
      if (this.playermodal) this.playermodal.dispose();
      clearInterval(this.interval_ID);

      //$('body').removeClass('vjs-full-window');
      this.playermodal = "";
    }, 50);
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}

