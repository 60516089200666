import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { imageUpload } from 'src/app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatlougeTypeConstants } from "src/app/core/constants";
import { UserService } from "src/app/core/services/user.service";
import { AssetsService } from "../../core/services/assets.service";
import { AccountService } from '../account/account.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { VMSlider } from '../../shared/owl-slider-config';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
declare var $: any;


declare var $;
interface SnowFlakeConfig {
	depth: number;
	left: number;
	speed: number;
}

@Component({
	selector: 'app-virtual-mandir',
	templateUrl: './virtual-mandir.component.html',
	styleUrls: ['./virtual-mandir.component.css']
})
export class VirtualMandirComponent implements OnInit {
	public snowFlakes: SnowFlakeConfig[];
	public isShowFlower: boolean = true;
	public interval_ID: any;
	public isThali: any = '../../../assets/img/vm/puja_ki_thaali_01.gif';

	public sessionId: any;
	public isSession: boolean;
	public some_error: any;
	public registeredBy: any;
	public registredEmail: any;
	public registredMobileNumber: any;
	public name: any;
	public id: any;
	public shankhAudio: any;
	public artiAudio: any;
	public bellAudio: any;
	public flowerAudio: any;
	public isArti: boolean = false;
	public isShank: boolean = false;
	assets_hover: boolean;
	assetImgParam: any;
	VMSlider = VMSlider;
	@Input() owlOption: OwlOptions;
	godImage: any;
	images: any;
	public isThaliLamp: boolean = false;

	galleryOptions: NgxGalleryOptions[];
	galleryImages: NgxGalleryImage[];
	startFlower: any;
	stopFlower: any;
	leftPendulum: any
	rightPendulum: any
	isIphone : boolean =false;

	// public image1 : any = '../../../assets/img/vm/puja_ki_thaali_01.gif';

	constructor(public activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private userService: UserService,
		private assetsService: AssetsService,
		private apiService: AccountService,
		private googleEvent: EventTrackingService,
		private router: Router) {
		clearInterval(this.interval_ID);
		this.snowFlakes = [];
		for (var i = 1; i <= 50; i++) {
			this.snowFlakes.push({
				depth: this.randRange(1, 30),
				left: this.randRange(0, 40),
				speed: this.randRange(1, 30)
			});
		}

		if (navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/iPad/i) ||
			navigator.userAgent.match(/Android/i)) {
			this.godImage = "../../../assets/img/vm/Hanuman_Ji_mbl.jpg";
		} else {
			this.godImage = "../../../assets/img/vm/Hanuman_ji_wallpaper.jpg";
		}

		if (navigator.userAgent.match(/iPhone/i)){
			this.isIphone = true;
		}

		var li = $(".owl-item li ");
		$(".owl-item li").click(function () {
			li.removeClass('active');
		});

	}

	private randRange(min: number, max: number): number {
		var range = (max - min);
		return (min + Math.round(Math.random() * range));
	}

	ngOnInit(): void {

		// this.images = [
		// 	{ "src": "../../../assets/img/vm/Hanuman_ji_wallpaper.jpg" },
		// 	{ "src": "../../../assets/img/vm/Vishnu_ji_wallpaper.jpg" },
		// 	{ "src": "../../../assets/img/vm/Hanuman_ji_wallpaper.jpg" }];

		this.owlOption = VMSlider;

		this.activatedRoute.params.subscribe(params => {

			this.sessionId = params['sessionId'];
			if (params['sessionId']) {
				this.isSession = true;
			} else {
				this.isSession = false;
			}
		});


		if (localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession == false) {
			$('#openLogin').trigger('click');
			//this.openLoginModel.nativeElement.click();
		} else {
			if (localStorage.getItem('sessionId') == "" || localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession) {
				localStorage.setItem('sessionId', this.sessionId);
			} else {
				this.sessionId = localStorage.getItem('sessionId');
			}
			this.profileDetails();
		}

		//this.test();




		this.galleryOptions = [
			{
				width: '550px',
				height: '550px',
				thumbnailsColumns: 7,
				imageAnimation: NgxGalleryAnimation.Slide,
				arrowPrevIcon: "fa fa-angle-left",
				arrowNextIcon: "fa fa-angle-right"
			},
			{
				height: '500px',
				breakpoint: 767,
				width: '100%',
				thumbnailsColumns: 3,
				imageArrows: false,
				previewRotate: true,

			},

		];


		this.galleryImages = [
			{
				small: '../../../assets/img/vm/hanuman_ji_thumb.png',
				medium: '../../../assets/img/vm/hanuman_ji.jpg',
				big: '../../../assets/img/vm/hanuman_ji.jpg'
			},
			{
				small: '../../../assets/img/vm/maa_durga_thumb.png',
				medium: '../../../assets/img/vm/maa_durga.jpg',
				big: '../../../assets/img/vm/maa_durga.jpg'
			},
			{
				small: '../../../assets/img/vm/shiv_Ji_thumb.png',
				medium: '../../../assets/img/vm/shiv_Ji.jpg',
				big: '../../../assets/img/vm/shiv_Ji.jpg'
			},
			{
				small: '../../../assets/img/vm/sri_ganesha_ji_thumb.png',
				medium: '../../../assets/img/vm/sri_ganesha_ji.jpg',
				big: '../../../assets/img/vm/sri_ganesha_ji.jpg'
			},
			{
				small: '../../../assets/img/vm/sri_shanidev_ji_thumb.png',
				medium: '../../../assets/img/vm/sri_shanidev_ji.jpg',
				big: '../../../assets/img/vm/sri_shanidev_ji.jpg'
			},
			{
				small: '../../../assets/img/vm/surya_devta_ji_thumb.png',
				medium: '../../../assets/img/vm/surya_devta_ji.jpg',
				big: '../../../assets/img/vm/surya_devta_ji.jpg'
			},
			{
				small: '../../../assets/img/vm/vishnu_ji_thumb.png',
				medium: '../../../assets/img/vm/vishnu_ji.jpg',
				big: '../../../assets/img/vm/vishnu_ji.jpg'
			},

		];

	}



	playSankh() {

		this.shankhAudio = document.getElementById("shankhAudio");
		this.artiAudio = document.getElementById("artiAudio");
		this.shankhAudio.play();
		this.artiAudio.pause();
		$(".arti-block").removeClass("artiStart");
		clearInterval(this.interval_ID);
		clearInterval(this.leftPendulum);
		clearInterval(this.rightPendulum);
		$(".bell-left").removeClass("pendulum animation-running");
		$(".bell-right").removeClass("pendulum animation-running");

		this.isThali = '../../../assets/img/vm/puja_ki_thaali_01.gif';
		this.isArti = false;
		this.isShank = true;

		
			if (this.isShowFlower == false) {
				this.interval_ID = setInterval(() => {
					this.isShowFlower = true;
				}, 1000);
			}
	}

	shankhEnded() {
		this.isShank = false;
	}
	profileDetails() {
		this.apiService.getData('signupnexg/profile')
			.subscribe((data: any) => {
				if (Object.keys(data.result).length > 0) {
					this.registeredBy = data.result.register_by == undefined ? '' : data.result.register_by;
					this.registredEmail = data.result.emailaddress == undefined ? '' : data.result.emailaddress;
					this.registredMobileNumber = data.result.mobile == undefined ? '' : data.result.mobile;
					this.name = data.result.first_name == undefined ? '' : data.result.first_name;
					this.googleEvent.eventEmitter('Vritual Mandir Event', 'Vritual Mandir', 'Click on Vritual Mandir page', 0, this.registredEmail == '' ? this.registredMobileNumber : this.registredEmail, 'Vritual Mandir page');

				}

			},
				error => {
					this.some_error = 'Something went wrong!! Please try again after some time.';
				},
				() => {
				}
			);
	}


	openLoginModal(content) {

		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
		}, (reason) => {
		});

	}


	bellleft() {
		$(".bell-left").addClass("pendulum");
		if ($(".bell-left").hasClass("pendulum")) {
			$(".bell-left").removeClass("pendulum");
			this.leftPendulum = setInterval(() => {
				$(".bell-left").addClass("pendulum");
			}, 100);
		}

		this.bellAudio = document.getElementById("bellAudio");
		this.bellAudio.play();
	}

	bellright() {
		$(".bell-right").addClass("pendulum");
		if ($(".bell-right").hasClass("pendulum")) {
			$(".bell-right").removeClass("pendulum");
			this.rightPendulum = setInterval(() => {
				$(".bell-right").addClass("pendulum");
			}, 100);
		}
		this.bellAudio = document.getElementById("bellAudio");
		this.bellAudio.play();
	}

	AartiThali() {

		this.isArti = true;
		this.isShank = false;

		this.bellAudio = document.getElementById("bellAudio");
		this.bellAudio.play();
		$(".bell-left").addClass("pendulum animation-running");
		$(".bell-right").addClass("pendulum animation-running");
		$(".vertical-track").addClass("multi_animation");
		$(".horizontal-track").addClass("multi_animation");

		this.isShowFlower = false;
		// this.stopFlower = setInterval(() => {
		// 	 this.isShowFlower = true;
		// 	 this.startFlower = setInterval(() => {
		// 		this.isShowFlower = false;
		//    }, 100);
		// }, 4000);
		//clearInterval(startFlower);

		this.flowerAudio = document.getElementById("flowerAudio");
		this.flowerAudio.play();

		if (this.isThaliLamp) {
			$(".arti-block").addClass("artiStart");
		} else {
			this.isThaliLamp=true;
			this.interval_ID = setTimeout(() => {
				this.isThali = "../../../assets/img/vm/puja_ki_thaali_02.gif";
			}, 100);


			this.interval_ID = setInterval(() => {
				this.isThali = "../../../assets/img/vm/puja_ki_thaali_03.gif";
				$(".arti-block").addClass("artiStart");
			}, 2500);
		}

		this.artiAudio = document.getElementById("artiAudio");
		this.artiAudio.play();
		this.shankhAudio.pause();
	}


	artiEnded() {
		this.isArti = false;
		setTimeout(() => {
			this.isShowFlower = true;
		}, 100);
		$(".bell-left").removeClass("pendulum animation-running");
		$(".bell-right").removeClass("pendulum animation-running");
		$(".arti-block").removeClass("artiStart");
		clearInterval(this.interval_ID);
		clearInterval(this.startFlower);
		clearInterval(this.stopFlower);
		this.isThali = '../../../assets/img/vm/puja_ki_thaali_01.gif';
	}

	stopArti() {
		this.isArti = false;
		this.isThaliLamp = false;


		setTimeout(() => {
			this.isShowFlower = true;
		}, 100);

		clearInterval(this.startFlower);
		clearInterval(this.stopFlower);
		$(".bell-left").removeClass("pendulum animation-running");
		$(".bell-right").removeClass("pendulum animation-running");
		$(".arti-block").removeClass("artiStart");
		clearInterval(this.interval_ID);

		this.isThali = '../../../assets/img/vm/puja_ki_thaali_01.gif';
		this.artiAudio.load();
		//alert(this.isShowFlower);

	}

	flowerfall() {
		//this.isArti = false;
		//this.isShank = true;
		this.flowerAudio = document.getElementById("flowerAudio");
		this.flowerAudio.play();
		if (this.isShowFlower == true) {

			this.isShowFlower = false;
			clearInterval(this.interval_ID);
			if (this.isShowFlower == false) {
				this.interval_ID = setInterval(() => {
					this.isShowFlower = true;
				}, 4000);
			}
		}


	}



	thali() {
			this.interval_ID = setTimeout(() => {
				this.isThali = "../../../assets/img/vm/puja_ki_thaali_02.gif";
			}, 100);

			this.interval_ID = setInterval(() => {
				this.isThali = "../../../assets/img/vm/puja_ki_thaali_03.gif";
				// $(".arti-block").addClass("artiStart");
			}, 2500);
			this.isThaliLamp = true;
	}



	gotoPnachang() {
		if (!navigator.userAgent.match(/iPhone/i) ||
			!navigator.userAgent.match(/iPad/i) ||
			!navigator.userAgent.match(/Android/i)) {
			this.modalService.dismissAll();
		}
		var pType = 'rss';
		var pId = '63ef78b25eee965593129ca2';
	    localStorage.setItem('isfromVirtualMandir', "yes");
		this.router.navigateByUrl('/panchang/' + pType + '/' + pId);
		this.stopArti();
	}


	test() {
		$(' .owl_1').owlCarousel({
			loop: false,
			margin: 2,
			responsiveClass: true,
			autoplayHoverPause: true,
			autoplay: true,
			slideSpeed: 400,
			paginationSpeed: 400,
			autoplayTimeout: 3000,
			responsive: {
				0: {
					items: 3,
					nav: true,
					loop: false
				},
				600: {
					items: 3,
					nav: true,
					loop: false
				},
				1000: {
					items: 3,
					nav: true,
					loop: false
				}
			}
		})
	}




	modalClose() {
		this.modalService.dismissAll();
		clearInterval(this.interval_ID);
		this.isThali = "../../../assets/img/vm/puja_ki_thaali_01.gif";
		this.isArti = false;
		this.artiAudio = document.getElementById("artiAudio");
		$(".arti-block").removeClass("artiStart");
		this.artiAudio.stop();
	}





}
