<button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item" id="openLogin"
    (click)="openLoginModal(content)">Open Login Modal</button>

<ng-template #content let-modal>
    <app-login></app-login>
</ng-template>

<div class="maincontentarea">
    <section class="vouchers">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h4>My Vouchers<a class="faq-link" href="./vouchers-faq">FAQ's</a></h4>
                    <div class="no-vouchers" *ngIf="vouchersList.length<=0">
                        <img src="../../../assets/img/novouchers.png" alt="" title="" />
                        <p>You don't have any vouchers</p>
                    </div>

                    <div id="snackbar">Code Copied !</div>
                    <p class="mt-3 mb-3" *ngIf="vouchersList.length>0">Thank you for signing up for Samatva. You can
                        copy voucher code of your choice and avail some exciting offers!</p>
                    <ul>
                        <li *ngFor="let item of vouchersList">
                            <div class="voucher_img">
                                <image-manipulation [img]="item.voucher_image" [params]="{'width':100,'height':100}"
                                    [notFound]="notFoundImg">
                                </image-manipulation>
                            </div>
                            <div class="voucher_info">
                                <label>{{item.brand_name}}</label>
                                <p>{{item.deal_summary}}</p>
                                <span>
                                    <label *ngIf="item.voucher_type=='Universal'">{{item.voucher_code}}</label>
                                    <button *ngIf="item.voucher_type=='Universal'" type="button" ngxClipboard
                                        [cbContent]="item.voucher_code" class="copy-btn"
                                        (cbOnSuccess)="contentCopied($event)">Copy Code</button>
                                        <i *ngIf="item.voucher_type=='Unique' && item.id==CurrentVoucherId && vCode==''" class="fa fa-spinner fa-spin" aria-hidden="true"></i>    
                                    <label
                                        *ngIf="item.voucher_type=='Unique' && item.id==CurrentVoucherId && getCodeData.error_code=='200'">
                                        {{vCode}}</label>
                                        
                                    <button
                                        *ngIf="item.voucher_type=='Unique' && item.id==CurrentVoucherId && getCodeData.error_code=='200'"
                                        type="button" ngxClipboard [cbContent]="vCode" class="copy-btn"
                                        (cbOnSuccess)="contentCopied($event)">Copy Code</button>

                                    <button *ngIf="item.voucher_type=='Unique' && item.id!=CurrentVoucherId"
                                        type="button" (click)="getCode(item.id)" class="copy-btn">GET CODE</button>

                                    <a href="javascript:;" (click)="landingPage(item.id)">Know More</a>
                                    <label class="error"
                                        *ngIf="item.voucher_type=='Unique' && item.id==CurrentVoucherId && getCodeData.error_code=='202'">{{getCodeData.error_string}}</label>
                                </span>
                            </div>
                        </li>
                        <!-- <li>
                            <div class="voucher_img">
                                <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvdHZzaG93L1RTSDU3NS9UU0g1NzUvMTY2NDUyMjA2MC5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjoxMDAsImhlaWdodCI6MTAwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                            </div>
                            <div class="voucher_info">
                                <label>Bigbasket</label>
                                <p>20% cashback on minimum order of Rs.800</p>
                                <span>
                                    <a href="javascript:void(0);">GET CODE</a>
                                    <a href="javascript:void(0);">Know More</a>
                                </span>
                            </div>
                        </li> -->
                        <!-- <li>
                            <div class="voucher_img">
                                <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvdHZzaG93L1RTSDU3NS9UU0g1NzUvMTY2NDUyMjA2MC5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjoxMDAsImhlaWdodCI6MTAwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                            </div>
                            <div class="voucher_info">
                                <label>Bigbasket</label>
                                <p>20% cashback on minimum order of Rs.800</p>
                                <span>
                                    <label>BB20</label>
                                    <a href="javascript:void(0);">Copy Code</a>
                                    <a href="javascript:void(0);">Know More</a>
                                </span>
                            </div>
                        </li> -->
                        <!-- <li>
                            <div class="voucher_img">
                                <img src="https://stage-image.samatva.tv/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5Ijoic2FtYXR2YS9pbWcvdHZzaG93L1RTSDU3NS9UU0g1NzUvMTY2NDUyMjA2MC5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZSwicmVzaXplIjp7IndpZHRoIjoxMDAsImhlaWdodCI6MTAwLCJmaXQiOiJjb3ZlciJ9fX0=" alt="" title="" />
                            </div>
                            <div class="voucher_info">
                                <label>Bigbasket</label>
                                <p>20% cashback on minimum order of Rs.800</p>
                                <span>
                                    <label>BB20</label>
                                    <a href="javascript:void(0);">Copy Code</a>
                                    <a href="javascript:void(0);">Know More</a>
                                </span>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>