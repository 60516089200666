import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../../core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AssetsService } from 'src/app/core/services/assets.service';
import * as moment from 'moment';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
declare var $: any;
@Component({
  selector: 'app-panchang',
  templateUrl: './panchang.component.html',
  styleUrls: ['./panchang.component.css']
})
export class PanchangComponent implements OnInit {

  panchangForm: FormGroup;
  today: any;
  tomorrow: any;
  todayMoonIn: any;
  tomorrowMoonIn: any;
  todaySunIn: any;
  tomorrowSunIn: any;
  cityname: any;
  public isSession: boolean;
  public sessionId: any;
  todayPaksh: any;
  tomorrowPaksh: any;
  type: any;
  id: any;
  tmoonIn: any;
  tmmoonIn: any;
  tSunrise: any;
  tSunset: any;
  tMoonrise: any;
  tnakshatra: any;
  tYoga: any;
  tAbhijitMuhurtha: any;
  tRahukalam: any;
  tYamagandam: any;
  tGulikai: any;
  tTithi: any;


  tmSunrise: any;
  tmSunset: any;
  tmMoonrise: any;
  tmnakshatra: any;
  tmYoga: any;
  tmAbhijitMuhurtha: any;
  tmRahukalam: any;
  tmYamagandam: any;
  tmGulikai: any;
  tmTithi: any;
  userInfo: any;
  userUnique: any;
  isfromVirtualMandir:boolean = false;



  constructor(public userService: UserService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public assetsService: AssetsService,
    private googleEvent: EventTrackingService,
    private router: Router) { }

  ngOnInit(): void {
    this.userUnique = 'NA';
    if (localStorage.getItem('userInfo') != undefined) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (Object.keys(this.userInfo.result).length > 0) {
        if (this.userInfo.result.register_by == 'mobile') {
          this.userUnique = this.userInfo.result.mobile;
        } else {
          this.userUnique = this.userInfo.result.emailaddress;
        }
      }
    }
    this.panchangForm = this.fb.group({});
    this.activatedRoute.params.subscribe(params => {

      this.sessionId = params['sessionId'];
      this.type = params['rtype'];
      this.id = params['rid'];
      if (params['sessionId']) {
        this.isSession = true;
      } else {
        this.isSession = false;
      }
    });

    if (localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession == false) {
      $('#openLogin').trigger('click');
      //this.openLoginModel.nativeElement.click();
    } else {
      if (localStorage.getItem('sessionId') == "" || localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession) {
        localStorage.setItem('sessionId', this.sessionId);
      }
      this.getPanchang('Agra-UP&India');
    }


    this.googleEvent.eventEmitter('panchang', 'panchang', 'Click on Panchang page', 0, this.userUnique, 'Visit Panchang page');

    if(localStorage.getItem('isfromVirtualMandir')=='yes'){
      this.isfromVirtualMandir = true;
    }
  }
  get panchangFormControls() { return this.panchangForm.controls; }


  openLoginModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
    }, (reason) => {
    });

  }

  getPanchang(e) {

    // this.cityname = e.target.value;
    this.cityname = e;
    let temp = {
      id: this.id,
      cityname: this.cityname,
      type: this.type
    }

    let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.assetsService.getPanchang(urlEncodedContestantParams).subscribe(data => {
      this.today = data.result.today;
      if (this.today.Moonin_1) {
        let getMooninTime = this.today.Moonin_1.slice(-8);
        let MooninWithDate = new Date('2012/12/01 ' + this.today.Moonin_1);
        let changeMooninAmPm = moment(MooninWithDate).format('HH:mm');
        this.tmoonIn = this.today.Moonin_1.replace(getMooninTime, '(' + changeMooninAmPm + ')');
      } else {
        this.tmoonIn = this.today.Moonin;
      }
      this.tomorrow = data.result.tomorrow;
      if (this.tomorrow.Moonin_1) {
        let gettMooninTime = this.tomorrow.Moonin_1.slice(-8);
        let tMooninWithDate = new Date('2012/12/01 ' + this.tomorrow.Moonin_1);
        let changetMooninAmPm = moment(tMooninWithDate).format('HH:mm');
        this.tmmoonIn = this.tomorrow.Moonin_1.replace(gettMooninTime, '(' + changetMooninAmPm + ')');
      } else {
        this.tmmoonIn = this.tomorrow.Moonin;
      }


      // for today
      var tris = new Date('2012/12/01 ' + this.today.Sunrise);
      var tset = new Date('2012/12/01 ' + this.today.Sunset);
      var tmon = new Date('2012/12/01 ' + this.today.Moonrise);
      this.tSunrise = moment(tris).format('HH:mm A');
      this.tSunset = moment(tset).format('HH:mm A');
      this.tMoonrise = moment(tmon).format('HH:mm A');
      this.tMoonrise = moment(tmon).format('HH:mm A');
      let getNakshatraTime = this.today.Nakshatra.slice(-8);
      let nakshatraWithDate = new Date('2012/12/01 ' + getNakshatraTime);
      let changeNakshatraAmPm = moment(nakshatraWithDate).format('HH:mm');
      this.tnakshatra = this.today.Nakshatra.replace(getNakshatraTime, '(' + changeNakshatraAmPm + ')');

      let getTithiTime = this.today.Tithi.slice(-8);
      let TithiWithDate = new Date('2012/12/01 ' + getTithiTime);
      let changeTithiaaAmPm = moment(TithiWithDate).format('HH:mm');
      this.tTithi = this.today.Tithi.replace(getTithiTime, '(' + changeTithiaaAmPm + ')');


      let getYogaTime = this.today.Yoga.slice(-8);
      let YogaWithDate = new Date('2012/12/01 ' + getYogaTime);
      let changeYoga = moment(YogaWithDate).format('HH:mm');
      this.tYoga = this.today.Yoga.replace(getYogaTime, '(' + changeYoga + ')');

      let AbhijitMuhurthaTime = this.today.AbhijitMuhurtha.split("-");
      let AbhijitFrom = AbhijitMuhurthaTime[0];
      let AbhijitTo = AbhijitMuhurthaTime[1];
      let AbhijitFromDate = new Date('2012/12/01 ' + AbhijitFrom);
      let AbhijitFromPaAm = moment(AbhijitFromDate).format('HH:mm A');
      let AbhijitToDate = new Date('2012/12/01 ' + AbhijitTo);
      let AbhijitToPaAm = moment(AbhijitToDate).format('HH:mm A');
      this.tAbhijitMuhurtha = AbhijitFromPaAm + '-' + AbhijitToPaAm;

      let RahukalamTime = this.today.Rahukalam.split("-");
      let RahukalamFrom = RahukalamTime[0];
      let RahukalamTo = RahukalamTime[1];
      let RahukalamFromDate = new Date('2012/12/01 ' + RahukalamFrom);
      let RahukalamFromPaAm = moment(RahukalamFromDate).format('HH:mm A');
      let RahukalamToDate = new Date('2012/12/01 ' + RahukalamTo);
      let RahukalamToPaAm = moment(RahukalamToDate).format('HH:mm A');

      this.tRahukalam = RahukalamFromPaAm + '-' + RahukalamToPaAm;


      let YamagandamTime = this.today.Yamagandam.split("-");
      let YamagandamFrom = YamagandamTime[0];
      let YamagandamTo = YamagandamTime[1];
      let YamagandamFromDate = new Date('2012/12/01 ' + YamagandamFrom);
      let YamagandamFromPaAm = moment(YamagandamFromDate).format('HH:mm A');
      let YamagandamToDate = new Date('2012/12/01 ' + YamagandamTo);
      let YamagandamToPaAm = moment(YamagandamToDate).format('HH:mm A');

      this.tYamagandam = YamagandamFromPaAm + '-' + YamagandamToPaAm;



      let GulikaiTime = this.today.Gulikai.split("-");
      let GulikaiFrom = GulikaiTime[0];
      let GulikaiTo = GulikaiTime[1];
      let GulikaiFromDate = new Date('2012/12/01 ' + GulikaiFrom);
      let GulikaiFromPaAm = moment(GulikaiFromDate).format('HH:mm A');
      let GulikaiToDate = new Date('2012/12/01 ' + GulikaiTo);
      let GulikaiToPaAm = moment(GulikaiToDate).format('HH:mm A');

      this.tGulikai = GulikaiFromPaAm + '-' + GulikaiToPaAm;


      // for tommoro
      var tmris = new Date('2012/12/01 ' + this.tomorrow.Sunrise);
      var tmset = new Date('2012/12/01 ' + this.tomorrow.Sunset);
      var tmmon = new Date('2012/12/01 ' + this.tomorrow.Moonrise);
      this.tmSunrise = moment(tmris).format('HH:mm A');
      this.tmSunset = moment(tmset).format('HH:mm A');
      this.tmMoonrise = moment(tmmon).format('HH:mm A');

      let tgetNakshatraTime = this.tomorrow.Nakshatra.slice(-8);
      let tnakshatraWithDate = new Date('2012/12/01 ' + tgetNakshatraTime);
      let tchangeNakshatraAmPm = moment(tnakshatraWithDate).format('HH:mm');
      this.tmnakshatra = this.tomorrow.Nakshatra.replace(tgetNakshatraTime, '(' + tchangeNakshatraAmPm + ')');

      let tgetTithiTime = this.tomorrow.Tithi.slice(-8);
      let tTithiWithDate = new Date('2012/12/01 ' + tgetTithiTime);
      let tchangeTithiaaAmPm = moment(tTithiWithDate).format('HH:mm');
      this.tmTithi = this.tomorrow.Tithi.replace(tgetTithiTime, '(' + tchangeTithiaaAmPm + ')');

      let tgetYogaTime = this.tomorrow.Yoga.slice(-8);
      let tYogaWithDate = new Date('2012/12/01 ' + tgetYogaTime);
      let tchangeYoga = moment(tYogaWithDate).format('HH:mm');
      this.tmYoga = this.tomorrow.Yoga.replace(tgetYogaTime, '(' + tchangeYoga + ')');

      let tAbhijitMuhurthaTime = this.tomorrow.AbhijitMuhurtha.split("-");
      let tAbhijitFrom = tAbhijitMuhurthaTime[0];
      let tAbhijitTo = tAbhijitMuhurthaTime[1];
      let tAbhijitFromDate = new Date('2012/12/01 ' + tAbhijitFrom);
      let tAbhijitFromPaAm = moment(tAbhijitFromDate).format('HH:mm A');
      let tAbhijitToDate = new Date('2012/12/01 ' + AbhijitTo);
      let tAbhijitToPaAm = moment(tAbhijitToDate).format('HH:mm A');
      this.tmAbhijitMuhurtha = tAbhijitFromPaAm + '-' + tAbhijitToPaAm;

      let tRahukalamTime = this.tomorrow.Rahukalam.split("-");
      let tRahukalamFrom = RahukalamTime[0];
      let tRahukalamTo = RahukalamTime[1];
      let tRahukalamFromDate = new Date('2012/12/01 ' + tRahukalamFrom);
      let tRahukalamFromPaAm = moment(tRahukalamFromDate).format('HH:mm A');
      let tRahukalamToDate = new Date('2012/12/01 ' + tRahukalamTo);
      let tRahukalamToPaAm = moment(tRahukalamToDate).format('HH:mm A');

      this.tmRahukalam = tRahukalamFromPaAm + '-' + tRahukalamToPaAm;


      let tYamagandamTime = this.tomorrow.Yamagandam.split("-");
      let tYamagandamFrom = tYamagandamTime[0];
      let tYamagandamTo = tYamagandamTime[1];
      let tYamagandamFromDate = new Date('2012/12/01 ' + tYamagandamFrom);
      let tYamagandamFromPaAm = moment(tYamagandamFromDate).format('HH:mm A');
      let tYamagandamToDate = new Date('2012/12/01 ' + tYamagandamTo);
      let tYamagandamToPaAm = moment(tYamagandamToDate).format('HH:mm A');

      this.tmYamagandam = tYamagandamFromPaAm + '-' + tYamagandamToPaAm;



      let tGulikaiTime = this.tomorrow.Gulikai.split("-");
      let tGulikaiFrom = tGulikaiTime[0];
      let tGulikaiTo = tGulikaiTime[1];
      let tGulikaiFromDate = new Date('2012/12/01 ' + tGulikaiFrom);
      let tGulikaiFromPaAm = moment(tGulikaiFromDate).format('HH:mm A');
      let tGulikaiToDate = new Date('2012/12/01 ' + tGulikaiTo);
      let tGulikaiToPaAm = moment(tGulikaiToDate).format('HH:mm A');

      this.tmGulikai = tGulikaiFromPaAm + '-' + tGulikaiToPaAm;
    });

  }

  landingPage(sessionId) {
    if (navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Android/i)) {
      var pType = 'rss';
      var pId = '63ef72e1b79fb143fd48843d';
      this.router.navigateByUrl('/virtual-mandir/' + sessionId);
    }
  }



}
