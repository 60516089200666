<div class="maincontentarea">
    <div class="container">
        <div class="terms">
            <h2>TERMS OF USE</h2>
            <p>THIS DOCUMENT IS AN ELECTRONIC RECORD GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES. THIS IS A LEGAL AGREEMENT BETWEEN YOU AND DIGIVE SERVICES PRIVATE LIMITED AND IT GOVERNS YOUR USE OF DIGIVIVE SERVICES PRIVATE LIMITED'S PLATFORM.</p>
            <p>These terms and conditions (Terms) govern your use of the website and mobile application of “Samatva” (the Platform) as offered by Digivive Services Private Limited (Digivive). These Terms are to be read in conjunction with Digivive's privacy policy (Privacy Policy) available at <a href="https://www.samatva.tv/privacy">www.samatva.tv/privacy.</a> It is clarified that these Terms are to be read in conjunction with, and not in derogation of any other terms, notifications, and policies that may apply to and be periodically published on the Platform.</p>
            
            <h2>Scope and Intent</h2>
            <ol>
                <li>These Terms apply to anyone accessing the Platform, regardless of the device or internet enabled resource used by you to access the Platform (each a Device). By accessing the Platform, you indicate to have read, understood, and agree to be bound by these Terms, the Privacy Policy, any other policies of Digivive as communicated from time to time and all other applicable laws and regulations without any limitation or qualification.</li>
                <li>It is expressly clarified that these Terms set out your rights and obligations for using the Platform.</li>
                <li>Digivive reserves all rights in relation to the Platform that are not expressly enumerated in these Terms.</li>
            </ol>

            <h2>Your Access to the Platform</h2>
            <ol start="4">
                <li>To use the Platform, you will ordinarily be required to be connected to the internet. However, some content on the Platform may be available for download and offline viewing.</li>
                <li>You shall only access the Platform as per the Terms as intimated by Digivive.</li>
                <li>To access the Platform, you are required to register yourself by submitting the relevant information and creating an account.</li>
                <li>Your account with Platform will be valid, save and except termination of your account per these Terms.</li>
                <li>Submission of accurate and complete information is required to use the Platform. At all times, you are responsible for your passwords and for any use of your account(s) maintained on the Platform. Your username and password on the Platform are for your personal use only and you are responsible for maintaining the confidentiality of your account(s) and passwords and for restricting access to your Devices. You hereby accept responsibility for all activities that occur under your account(s) and passwords on the Platform.</li>
                <li>While certain content on the Platform may be free to access, certain content on the Platform will be available to you subject to the payment of a subscription fee as set out at <a href="https://www.samatva.tv/subscribe/choseplan"> https://samatva.tv/subscribe/choseplan</a> (login required to visit the page).</li>
                <li>Payments will be affected via the payment methods available, and as may be updated from time to time on the Platform</li>
                <li>You expressly authorize Digivive to update any payment information provided by third party service providers.</li>
                <li>The Consideration for access to the Platform, or any other charges incurred in connection with the use of the Platform, such as taxes and processing charges will charged on a monthly/ quarterly/ half yearly/ annually, subject to the subscription plan opted by you, and the billing period shall commence from the day of your payment of the Consideration</li>
                <li>Digivive may at its sole discretion update the Consideration payable and/ or the subscription services of the Platform from time to time, and the revised rates and subscription plans will be published on the Platform, and will be applicable to you once the revised rates have been intimated to you</li>
                <li>You remain responsible for the payment of the Consideration, failing which Digivive may restrict/ suspend/ or cancel your subscription with the Platform without prejudice to any of its rights under equity, law, or contract.</li>
                <li>You expressly authorize Digivive to charge a recurring Consideration to the billing method available with the Platform when you select the auto renewal option on the Platform.</li>
                <li>It is clarified that Digivive may charge the recurring Consideration to any other payment method available with the Platform in the event that the primary payment method is declined or longer available to affect payments for any reason whatsoever.</li>
                <li>If you provide any information on the Platform that is inaccurate and / or incomplete or where Digivive has reasonable grounds to suspect that such information is inaccurate and / or incomplete, Digivive reserves the right to suspend or terminate your account on the Platform and, or access to the Platform.</li>
                <li>You may cancel your subscription, or alter all relevant account details including but not limited to payment information on the “Account” page. In the event that you have used third party services to obtain subscription to our services, you are required to independently confirm the cancellation of your subscription with the relevant third party, and Digivive will not be responsible for the same.</li>
                <li>While you may cancel your subscription to the Platform at any time and for any reason, all payments of Consideration made to the Platform are non-refundable. You will however have access to the content for the entire duration of the billing period for which you have paid the Consideration.</li>
                <li>Digivive may at its sole discretion, subject to your violation of these Terms and/ or the Privacy Policy or any other policies and guidelines as intimated by Digivive from time to time, restrict/ suspend/ or cancel your subscription. If your access to the Platform is terminated for reason of violation of these Terms and, or the Privacy Policy and, or any other policies and guidelines as intimated by Digivive from time to time, you will not be entitled to receive a refund on the Consideration paid by you.</li>
                <li>You must not disseminate any messages or views which are discriminatory against any individual, or group of persons based on their race or ethnic origin, religion, disability, gender, age, nationality, veteran status, sexual orientation, or gender identity.</li>
                <li>You are not authorised to commercially exploit the Platform for any reason whatsoever.</li>
                <li>You are not authorised to reproduce, duplicate, copy, reverse engineer, sell, resell, or exploit any portion of the Platform and allow any unauthorised use of the Platform and / or facilitate third-party access to the Platform.</li>
                <li>You may not scrape the Platform for spamming purposes.</li>
                <li>You agree and understand that Digivive reserves the right to refuse or restrict your access to the Platform and the products and services therein at its sole discretion.</li>
            </ol>

            <h2>Digivive's Intellectual Property and use of the Platform </h2>
            <ol start="26">
                <li>You agree and understand that nothing in these Terms shall be deemed to be construed as an assignment, transfer or novation of any intellectual property right belonging to, owned by and / or licensed by Digivive including, amongst others, all content, trademarks, trade names, copyrights, designs, software, and patents as published on the Platform. Digivive will continue to hold all right, title, and interest in all intellectual property in the Platform itself and any derivative works thereof. It is clarified that all third-party trademarks, brands, links, and content on the Platform are the property of their respective owners.</li>
                <li>Digivive hereby grants you a limited and non-exclusive license to access the Platform and the intellectual property contained in such Platform, including, without limitation, all trademarks, copyrights, patents, software design etc., as and to the extent set out in these Terms.</li>
                <li>You agree not to reproduce, distribute, sell, modify, display, perform, archive, publish, license, create derivative works from, offer for sale or use, or in any way exploit any part of any intellectual property or other information, content, materials, and services available on or through the Platform.</li>
                <li>You agree not to circumvent, remove, degrade, or deactivate any of the contents of the Platform and / or use any robot, spider, scraper, or other means to access the Platform. You further agree not to decompile, reverse engineer, and disassemble any software or other products or processes accessible through the Platform and not to insert any code or product or manipulate the content of the Platform in any way, or use any data mining, data gathering or extraction method on the Platform.</li>
                <li>You agree not to use any product which modifies, changes, adds to, or subtracts from the source code, graphical user interface, structure, integration, look and feel or features of the Platform.</li>
                <li>You agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy, or limit the functionality of the Platform, or do any act which may adversely affect any third-party's use of, and experience on, the Platform.</li>
                <li>You will promptly notify Digivive upon discovery of any unauthorised use or disclosure or any illegal use of the Platform and related intellectual property of Digivive, whether intentional or unintentional, and will cooperate with Digivive in every reasonable way to help regain possession thereof and to prevent its further unauthorised use.</li>
                <li>Digivive reserves the right to alter, modify and / or filter the content on the Platform at any time.</li>
            </ol>

            <h2>Use of your Information by Digivive</h2>
            <ol start="34">
                <li>Digivive only uses your information as set forth in the Privacy Policy (which can be accessed at <a href="https://www.samatva.tv/privacy"> www.samatva.tv/privacy</a> and only in accordance with the applicable laws.</li>
                <li>You hereby grant your consent to receive periodic promotional materials and marketing surveys from Digivive through text messages and e-mails in relation to Digivive’s Platform. You can unsubscribe / opt-out from receiving promotional materials and marketing surveys at any point.</li>
                <li>Any feedback you provide to Digivive in relation to Digivive’s products and services will not be confidential. Digivive shall be free to use such information on an unrestricted basis. You represent and warrant that your feedback does not contain any confidential or proprietary information and you are not entitled to any compensation or reimbursement of any kind from Digivive for use of the information contained in the feedback under any circumstances.</li>
            </ol>

            <h2>Age of Consent</h2>
            <ol start="37">
                <li>In order to access or avail Digivive’s products and services on the Platform and to create and maintain an account on the Platform you must be at least 18 years of age. For users below the age of 18, consent to access the Platform shall be provided and supervised by the legal guardian of the child. It is clarified that Digivive bears no responsibility for any claims that arise from usage of the Platform by users under the age of 18.</li>
            </ol>

            <h2>Termination of Licence and your access to the Platform</h2>
            <ol start="38">
                <li>If you violate the Terms, the Privacy Policy, or any other policies of the Platform, your permission to use and access such Platform and any licence granted over such Platform ends and ceases immediately.</li>
                <li>Your access to the Platform shall be terminated further to any requests by authorised law enforcement and / or other government and regulatory agencies and authorities.</li>
            </ol>

            <h2>Warranties and Limitations</h2>
            <ol start="40">
                <li>The use of the Platform is at your own risk and discretion. Digivive makes no warranty that the Platform will meet your requirements and / or that the service will be uninterrupted, timely or error-free.</li>
                <li>You represent and warrant that all information and documents provided by you and any representations made by you, including but not limited to your name, e-mail address and any other financial and banking related information are genuine, accurate and in compliance with all applicable laws.</li>
            </ol>

            <h2>Liability</h2>
            <ol start="42">
                <li>To the extent permitted by applicable law, Digivive shall not be liable for any damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from:</li>
                <ol type="a">
                    <li>Your access to or use of or inability to access or use the Platform; </li>
                    <li>Any act, conduct and / or omission of any third-party in relation to or through the Platform including without limitation, any malpractice, misrepresentation, defamation, sedition, offensive or illegal conduct of other users or third-parties; or</li>
                    <li>Any unauthorised access, use or alteration of your transmissions or content on the Platform.</li>
                </ol>
            </ol>

            <h2>Indemnification</h2>
            <ol start="43">
                <li>You agree to indemnify and hold Digivive harmless from any claim or demand, including but not limited to reasonable attorney's fees, made by any third-party due to your use of the Platform, your violation of the Terms, Privacy Policy and any other guidelines communicated by Digivive from time to time.</li>
            </ol>

            <h2>Changes to Terms of Use and Assignment</h2>
            <ol start="44">
                <li>Digivive may from time to time change the Terms and / or implement new Terms and recommend that you periodically check these Terms for the most recent version. Such changes will be effective immediately upon being published on the Platform. Your continued use of the Platform will indicate your acceptance of the last version of the Terms as published on such Platform. If you do not agree to the change(s) made to the Terms, you must immediately cease your use of the Platform.</li>
            </ol>
        </div>
    </div>
</div>