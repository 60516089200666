
 <!--Share Modal -->
 <!-- <div class="modal auth" id="sharebuttons">
    <div class="modal-dialog" role="document">
       <div class="modal-content"> -->
          <div class="modal-header">
             <h4 class="modal-title">Share</h4>
             <button type="button" class="close" (click)="onClose()">&times;</button>
          </div>
          <div class="modal-body mt-4">
             <!-- <share-button button="facebook" text="Share" ></share-button> -->

             <!-- <share-popup-button>Share</share-popup-button> -->
             <div class="_social_icon">
                <button>
                   <i class="fa fa-envelope-o" (click)="openMailBody()" aria-hidden="true"></i>
                   <h5>Email</h5>
                </button>
                <button>
                   <i class="fa fa-facebook-square" (click)="shareFB()" data-js="facebook-share"
                      aria-hidden="true"></i>
                   <h5>Facebook</h5>
                   <!-- <i class="fa fa-facebook-square" (click)="shareFB()" data-js="facebook-share" aria-hidden="true"></i> -->
                </button>
                <button>
                   <i class="fa fa-twitter-square" (click)="shareTB()" aria-hidden="true"></i>
                   <h5>Twitter</h5>
                </button>
             </div>
             <div class="social_btn">

                <button *ngIf="!copyLink" type="button" ngxClipboard
                   [cbContent]="share_url_link" class="btn btn-default register sharemodal_margin"
                   (cbOnSuccess)="contentCopied($event)">Copy Link</button>
             </div>
             <div class="social_btn">

                <button *ngIf="copyLink" type="button" ngxClipboard
                   [cbContent]="share_url_link"
                   class="btn btn-default register sharemodal_margin">Copied!</button>
             </div>
          </div>
       <!-- </div>
    </div>
 </div> -->
 <!-- Share Modal end -->