import { Injectable } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor(private titleService: Title, private metaService:Meta) { }
  
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  public getTitle(){
      return  this.titleService.getTitle();
  }
  public setTag( descrption: string) {
      this.metaService.updateTag({ name: 'description', content: descrption });
  }
}
