import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { CommonService } from "src/app/core/services/common.service";
import { customOptionsForBigSlider, customOptionsForEightMiniSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider, customOptionsForFiveMiniSlider } from 'src/app/shared/owl-slider-config';



@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {


  @Input() owlOption: OwlOptions;
  @Input() data: any = {};

  customOptionsForBigSlider = customOptionsForBigSlider;
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  customOptionsForEightMiniSlider = customOptionsForEightMiniSlider;


  assets_hover: boolean;
  userInfo: any;
  page_view: string;
  routeName: string;
  playListId: string;
  setPlayListItem: any;
  addedAssetIndex: any;
  error_code: any;
  deletedItem: any;
  deleteAsset_id: any;

  thumbnailType: any;
  thumbnailClass: string;
  notFoundImg: string = 'image-not-found';
  assetImgParam: any;
  categoryId: any;
  viewAllType: any;
  limit: number = 20;

  isCloudFrontType: boolean = true;
  isCircle: any;
  enlargedCircle: any;

  showViewAll: number = 4;




  constructor(
    public router: Router,
    public commonService: CommonService, public userService: UserService

  ) { }

  ngOnInit(): void {


    this.data = this.data;

    this.thumbnailType = this.data.thumbnail_type.slug;

    // if(Object.keys(this.data.thumbnail_type).length === 0){
    //   this.thumbnailType = 'landscape';
    // }else{
    //   this.thumbnailType = this.data.thumbnail_type.slug;
    // }

    if (this.commonService.filterType == 1) {
      this.isCloudFrontType = true;
      this.isCircle = { "roundCrop": true, 'width': 191, 'height': 191 };
      this.enlargedCircle = { "roundCrop": true, 'width': 230, 'height': 230 };
    } else {
      this.isCloudFrontType = false;
      this.isCircle = { "radius": 'max', 'width': 191, 'height': 191 };
      this.enlargedCircle = { "radius": 'max', 'width': 230, 'height': 230 };
    }

    switch (this.thumbnailType) {
      case 'landscape':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'width': 292, 'height': 164 };
        this.thumbnailClass = 'landscape_assets';
        this.notFoundImg = 'landscape';
        this.showViewAll = 4;
        break;
      case 'enlarged-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'width': 390, 'height': 220 };
        this.thumbnailClass = 'enlarged_landscape_assets';
        this.notFoundImg = 'enlarged_landscape';
        this.showViewAll = 3;
        break;
      case 'stretched-landscape':
        this.owlOption = customOptionsForThreeMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 390, 'height': 170 };
        this.thumbnailClass = 'stretched_landscape_assets';
        this.notFoundImg = 'stretched_landscape';
        this.showViewAll = 3;
        break;
      case 'portrait':
        this.owlOption = customOptionsForEightMiniSlider;
        this.assetImgParam = { "crop": 'fill', 'ar_2': 3, 'width': 191, 'height': 286 };
        this.thumbnailClass = 'portrait_assets';
        this.notFoundImg = 'portrait';
        this.showViewAll = 8;
        break;
      case 'square':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 161, 'height': 161 };
        this.thumbnailClass = 'square_assets';
        this.notFoundImg = 'square';
        this.showViewAll = 7;
        break;
      case 'enlarged-square':
        this.owlOption = customOptionsForFourMiniSlider;
        this.assetImgParam = { "crop": 'scale', 'width': 292, 'height': 292 };
        this.thumbnailClass = 'enlarged_square_assets';
        this.notFoundImg = 'enlarged_square';
        this.showViewAll = 4;
        break;
      case 'circular':
        this.owlOption = customOptionsForSevenMiniSlider;
        this.assetImgParam = this.isCircle;
        this.thumbnailClass = 'circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 7;
        break;
      case 'enlarged-circle':
        this.owlOption = customOptionsForSixMiniSlider;
        this.assetImgParam = this.enlargedCircle;
        this.thumbnailClass = 'enlarged-circular_assets';
        this.notFoundImg = 'circle';
        this.showViewAll = 6;
        break;

      default: this.thumbnailClass = 'landscape_assets';
        this.assetImgParam = { 'width': 330, 'height': 180 };
        this.owlOption = customOptionsForFourMiniSlider;
        this.notFoundImg = 'landscape';
        this.showViewAll = 4;
        break;
    }
  }

  redirectUrl(data, content, jwPlayerDom, event) {

    // event.stopPropagation();
    console.log(data);
    console.log(data.landing_page);
    console.log(data.landing_page == "__tab");

    //  return
    const slug = data.app_page;

    let viewAlldParams = {
      categoryId: data._id,
      slug
      // categoryType: data.category_type
    }

    viewAlldParams['type'] = this.data.tab_slug;

    // viewAlldParams['sub_category_id'] = data._id;
    // viewAlldParams['categoryname'] = data.category_name;

    // this.router.navigate(['/subcategory'], { queryParams: viewAlldParams });

    let viewalltabDataParams = {
      "plateform": "web",
      "catlogue": CatlougeTypeConstants.catlogue,
      "slug": this.data.tab_slug, //this.getCurrentSlug
      // "category_id": this.categoryId,
      // category_type+'_id':data._id
      // "sub_category_id":data._id,
      //sub_sub_category_id:611671427465905fc4001b87,
      "call": 'index',
      "page": 1,
      "limit": this.limit
      //categoryType=sub_category&
      // &sub_category_id=618f9fe79f807069706b8be2&categoryname=Sub%20Category%20Parent
    };
    // this.router.navigate(['/viewall'], { queryParams: viewAlldParams });
    console.log(data);
    // console.log(data.category_name.replace(/\s+/g, '-').toLowerCase());
    let url_slug = data.category_name.replace(/\s+/g, '-').toLowerCase();
    // return
    if (data.landing_page == "__tab" && data.app_page && data.app_page != 'home') {
      let tab_data = {
        sub_category_id: data._id,
        // tab_id:data.tab_id,
        tab_id: data.category_tab_id,
        slug,
        category_name: data.category_name,
        url_slug
      }
      localStorage.setItem('tab_data', JSON.stringify(tab_data));
      localStorage.setItem('prev_slug', this.userService.getCurrentSlugName);
      // localStorage.setItem('slugName', slug);
      this.userService.updateSelectedMenu(slug);
      this.userService.setCurrentSlugName(slug);
      this.router.navigateByUrl('/' + url_slug);
      window.scroll(0, 0);
      // this.router.navigate(['',data.app_page])
    } else if (data.app_page && data.app_page == 'home') {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/subcategory'], { queryParams: viewAlldParams })
    }




  }

  viewall(data) {
    // console.log(this.router.url);
    console.log(data);
    let viewAlldParams = {
      categoryId: data._id,
      categoryType: data.category_type,
      subCategoryCount: data.sub_category_count
    }

    viewAlldParams['type'] = data.tab_slug;
    this.router.navigate(['/viewall'], { queryParams: viewAlldParams })
  }

  item_hover_enter() {
    // console.log("item hover enter");
    this.assets_hover = true;
  }

  item_hover_leave() {
    // console.log("item hover leave");
    this.assets_hover = false;
  }

}
